import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { createCheckoutSessionForArticles } from '../../../services/paymentServices';
import Button from '../../../components/Button/Button';
import { FaCreditCard, FaTimes } from 'react-icons/fa';

const CheckoutForm = ({ onConfirmPayment, onClose, totalAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      try {
        console.log("Total amount:", totalAmount); // Ajout de log pour vérifier le montant
        const { clientSecret } = await createCheckoutSessionForArticles(totalAmount);
        console.log("ClientSecret reçu:", clientSecret); // Ajout de log pour vérifier le clientSecret
        setClientSecret(clientSecret);
      } catch (error) {
        setError('Erreur lors de la création du PaymentIntent');
        console.error("Erreur lors de la création du PaymentIntent:", error); // Ajout de log pour les erreurs
      }
    };

    fetchPaymentIntent();
  }, [totalAmount]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Désactiver le bouton de soumission
    setError('');

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      console.error('Erreur de paiement:', error); // Ajout de log pour les erreurs
      setError(`Erreur de paiement: ${error.message}`);
      setLoading(false);
    } else {
      console.log('Paiement réussi');
      setLoading(false);
      onConfirmPayment();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg shadow-lg max-w-[800px] mx-auto font-satoshi">
      <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Paiement par Carte</h3>
      <PaymentElement className="p-2 border rounded mb-4" />
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="flex justify-center mt-4 gap-4">
        <Button type="submit" text="Payer" icon={FaCreditCard} disabled={loading} className="ml-4 gap-2" />
        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="ml-4 gap-2" />
      </div>
    </form>
  );
};

export default CheckoutForm;
