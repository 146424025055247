import React from "react";
import { FaCartPlus } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";

const ArticleCard = ({ id, nom, description, prix, photo_url, addToCart }) => {
  const handleAddToCart = () => {
    addToCart({ id, nom, prix: parseFloat(prix), photo_url, description });
    toast.success(`"${nom}" ajouté au panier.`);
  };

  const formattedPrice = parseFloat(prix).toFixed(2);

  return (
    <div className="border-slate-300 dark:border-slate-600 border-2 dark:bg-slate-500 bg-slate-300 shadow-md rounded-lg overflow-hidden flex flex-col min-h-[400px] font-satoshi relative">
      <img
        src={photo_url || "default_product.jpg"}
        alt={nom}
        className="w-full h-64 object-cover flex-shrink-0"
      />
      <div className="flex flex-col flex-grow p-4 justify-between">
        <div>
          <h3 className="text-lg font-bold text-gray-600 dark:text-slate-100">
            {nom}
          </h3>
          <p className="text-sm text-gray-600 dark:text-slate-300">
            {description}
          </p>
          <div className="mt-2">
            <div className="flex items-center text-2xl dark:text-slate-300 font-bold text-primary">
              {formattedPrice} €
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center mt-4 space-y-2">
          <Button
            text="Ajouter au panier"
            onClick={handleAddToCart}
            icon={FaCartPlus}
            className="w-full gap-2 text-sm"
            hoverLabel="Ajouter ce produit au panier"
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
