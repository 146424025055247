import React, { useState, useEffect } from "react";
import { getAllTeamMembers } from "../../services/teamServices";

const NosEquipes = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const data = await getAllTeamMembers();
      setTeam(data);
    };

    fetchTeamMembers();
  }, []);

  return (
    <section
      className="py-12 bg-lightBackground dark:bg-darkBackground text-black dark:text-white"
      id="nosEquipes"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center -mx-4 overflow-hidden">
          <div className="font-satoshi px-8 w-full lg:w-2/3 overflow-hidden my-4 text-center">
            <h2 className="text-3xl font-extrabold mb-4 text-primary mx-auto font-satoshi text-center">NOS ÉQUIPES</h2>
            <div className="flex flex-wrap justify-center">
              {team.map((member) => (
                <div
                  key={member.id}
                  className="w-1/2 sm:w-1/2 md:w-1/3 p-4 text-center"
                >
                  <img
                    src={member.photoURL}
                    alt={`${member.nom} ${member.prenom}`}
                    className="rounded-lg mx-auto mb-2 w-48 h-48 object-cover font-satoshi"
                  />
                  <h3 className="text-lg">
                    <span className="font-semibold text-sm font-satoshi">{member.nom} </span>
                    <span className="font-normal font-satoshi">{member.prenom}</span>
                  </h3>
                  <p className="italic text-gray-400 font-bold font-satoshi">{member.fonction}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NosEquipes;
