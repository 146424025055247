import React, { useState } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { FaChevronUp, FaChevronDown, FaTimes } from "react-icons/fa";

const Dropdown = ({ title, children, isOpen, toggle }) => (
  <div className="bg-blue-100 rounded-lg shadow mb-4 p-4 max-w-[800px] mx-auto w-full">
    <button
      onClick={toggle}
      className="w-full flex justify-between items-center text-left text-lg font-semibold dark:text-gray-800"
    >
      {title}
      {isOpen ? <FaChevronUp /> : <FaChevronDown />}
    </button>
    {isOpen && <div className="mt-2">{children}</div>}
  </div>
);

const ArbitrageJudo = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);

  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleDropdown3 = () => setIsOpen3(!isOpen3);
  const toggleDropdown4 = () => setIsOpen4(!isOpen4);
  const toggleDropdown5 = () => setIsOpen5(!isOpen5);
  const toggleDropdown6 = () => setIsOpen6(!isOpen6);
  const toggleDropdown7 = () => setIsOpen7(!isOpen7);
  const toggleDropdown8 = () => setIsOpen8(!isOpen8);
  const toggleDropdown9 = () => setIsOpen9(!isOpen9);
  const toggleDropdown10 = () => setIsOpen10(!isOpen10);
  const toggleDropdown11 = () => setIsOpen11(!isOpen11);
  const toggleDropdown12 = () => setIsOpen12(!isOpen12);
  const toggleDropdown13 = () => setIsOpen13(!isOpen13);
  const toggleDropdown14 = () => setIsOpen14(!isOpen14);
  const toggleDropdown15 = () => setIsOpen15(!isOpen15);
  const toggleDropdown16 = () => setIsOpen16(!isOpen16);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Arbitrage Judo
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mt-4 space-y-8 mx-auto p-4 max-w-[1280px]">

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Rôle de l'arbitre</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>🔸Assurer la sécurité des combattants</p>
              <p>🔸Faire respecter les règles du judo</p>
              <p>🔸Attribuer les points et les pénalités</p>
              <p>🔸Déterminer le vainqueur du combat</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731205/images/arbitrage/arbitrage_judo_01_zlmwhp.jpg" alt="Rôle de l'arbitre" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Les principales commandes de l'arbitre</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720757031/images/arbitrage/arbitrage_judo_06_h2yqp4.jpg" alt="Gestes de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Hajime : Début du combat" isOpen={isOpen1} toggle={toggleDropdown1}>
                <p>Hajime est le terme japonais pour dire commencer ou poursuivre le combat après une pause.</p>
              </Dropdown>
              <Dropdown title="Matte : Arrêt temporaire du combat" isOpen={isOpen2} toggle={toggleDropdown2}>
                <p>Matte : l'arbitre interrompt le combat.</p>
              </Dropdown>
              <Dropdown title="Sore-made : Fin du combat" isOpen={isOpen3} toggle={toggleDropdown3}>
                <p>Sore-made indique la fin du combat puis l'arbitre désigne le vainqueur.</p>
              </Dropdown>
              <Dropdown title="Osaekomi : Début d'une immobilisation" isOpen={isOpen4} toggle={toggleDropdown4}>
                <p>Osaekomi signifie qu'une immobilisation au sol est effective et que le chronomètre d'immobilisation est enclenché.</p>
              </Dropdown>
              <Dropdown title="Toketa : Fin d'une immobilisation" isOpen={isOpen5} toggle={toggleDropdown5}>
                <p>Toketa signifie la fin de l'immobilisation et l'arrêt du chronomètre d'immobilisation.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Le système de points, avantages & pénalités</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <Dropdown title="Ippon (point complet)" isOpen={isOpen6} toggle={toggleDropdown6}>
                <p>Ippon est le score le plus élevé qu'un judoka peut obtenir dans un combat de judo. Il représente une victoire immédiate. Un Ippon est accordé lorsqu'un judoka exécute une technique parfaite, projetant son adversaire sur le dos avec force, vitesse et contrôle, ou lorsqu'il maintient une immobilisation pendant 20 secondes, ou encore grâce à une soumission par clé de bras ou étranglement.</p>
              </Dropdown>
              <Dropdown title="Waza-ari : Demi-point" isOpen={isOpen7} toggle={toggleDropdown7}>
                <p>Waza-ari est un score attribué en judo lorsqu'une technique est presque parfaite, mais ne remplit pas toutes les conditions pour obtenir un Ippon. Il est accordé pour une projection qui manque l'une des trois conditions (force, vitesse, contrôle) ou pour une immobilisation maintenue entre 10 et 19 secondes. Deux Waza-ari obtenus par un judoka équivalent à un Ippon, mettant fin au combat.</p>
              </Dropdown>
              <Dropdown title="Kinza : avantage" isOpen={isOpen8} toggle={toggleDropdown8}>
                <p>Kinza est un terme utilisé pour désigner un avantage léger accordé à un judoka sur une action. Bien que ce terme ne soit pas officiellement utilisé dans les compétitions internationales, il peut être employé pour indiquer une légère supériorité dans la performance globale, sans qu'un point formel soit attribué. Cela peut influencer la décision finale en cas d'égalité.</p>
              </Dropdown>
              <Dropdown title="Shido & Hansoku-make (pénalités)" isOpen={isOpen9} toggle={toggleDropdown9}>
                <p>Shido est une pénalité mineure en judo. Elle est attribuée pour des infractions telles que la passivité, les sorties du tapis, ou des actions prohibées comme saisir le bas du pantalon. Trois Shido entraînent une disqualification (Hansoku-make) et la victoire de l'adversaire. Un Shido est également utilisé pour départager les judokas en cas d'égalité, le combattant avec le moins de Shido étant déclaré vainqueur.</p>
              </Dropdown>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731797/images/arbitrage/arbitrage_judo_04_o2hiap.webp" alt="Système de notation" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Les actions interdites & sanctions</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731412/images/arbitrage/arbitrage_judo_03_b1ibhn.jpg" alt="Actions interdites" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Infractions légères : Shido" isOpen={isOpen10} toggle={toggleDropdown10}>
                <p>🔸Éviter intentionnellement de prendre la saisie du kimono (kumi-kata).</p>
                <p>🔸Attitude ou position défensive exagérée</p>
                <p>🔸Fausse attaque: action donnant l'impression d'attaquer sans réelle intention de projeter.</p>
                <p>🔸Saisie en "pistolet" ou "ourlet" du bout de la manche de l'adversaire</p>
                <p>🔸Saisie de plus de 5s des doigts ou du poignet de l'adversaire afin que celui-ci ne puisse saisir le kumi-kata</p>
                <p>🔸Défaire intentionnellement son judogi ou sa ceinture.</p>
                <p>🔸Insérer les doigts à l'intérieur de la manche ou du pantalon (au sol)</p>
                <p>🔸Saisir le pantalon de l'adversaire (en combat debout)</p>
                <p>🔸Prendre la saisie du kumi-kata avec une seule main sans attaquer</p>
                <p>🔸Non combativité : après saisie du kumi-kata, ne pas faire d'attaque</p>
                <p>🔸Garde unilatérale : prendre le kumi-kata du même côté du judogi sans attaquer</p>
                <p>🔸Etreinte de l'ours : saisir l'adversaire en l'enserrant sans saisir le kumi-kata afin de le projeter en arrière</p>
                <p>🔸Mettre une main, un pied ou une jambe directement sur le visage de l'adversaire</p>
                <p>🔸Mettre une main ou un pied à l'intérieur de la ceinture</p>
                <p>🔸Sortie volontaire de la zone de combat</p>
                <p>🔸Donner un coup avec le genou, le pied ou la main afin de faire lâcher la saisie du kumi-kata adverse</p>
                <p>🔸Rompre la saisie adverse avec les deux mains, ou tordre les doigts</p>
                <p>🔸Protéger couvrir le bord de la veste de son Judogi pour empêcher la saisie.</p>
                <p>🔸Forcer l'adversaire avec un ou deux bras à prendre une position courbée, sans attaque immédiate</p>
              </Dropdown>
              <Dropdown title="Les infractions graves : Hansoku-Make" isOpen={isOpen11} toggle={toggleDropdown11}>
                <p>🔸Toute action qui peut porter atteinte à l'intégrité physique de l'adversaire</p>
                <p>🔸Pratiquer des techniques illicites, interdites car dangereuses, ainsi que toute forme de soumissions autres que sur l'articulation du coude.</p>
                <p>🔸Toutes actions contraires à l'esprit du judo et au respect des arbitres (insultes, gestes déplacés, coups portés volontairement, ne pas tenir compte des instructions de l'arbitre)</p>
                <p>🔸Diving : Effectuer une technique en projetant sa tête directement sur le tatami</p>
                <p>🔸Porter un objet dur ou avoir gardé son téléphone à l'intérieur du judogi</p>
              </Dropdown>
              <Dropdown title="Succession des Shido" isOpen={isOpen12} toggle={toggleDropdown12}>
                <p>🔸3 shido successifs entraînent un Hansoku-make (disqualification) et victoire de son adversaire</p>
                <p>🔸un combattant peut reprendre les combats après un Hansoku-make de 3 shido</p>
                <p>🔸un combattant est disqualifié de la compétition s'il reçoit un Hansoku-make pour des actions contraires à l'esprit du judo.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 La durée des combats</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <Dropdown title="Les temps de combat en fonction des catégories" isOpen={isOpen13} toggle={toggleDropdown13}>
                <p>🔸Poussins, Mini-Poussins : 1m30</p>
                <p>🔸Benjamins : 2m</p>
                <p>🔸Minimes, Cadets-Juniors-Séniors (2D-3D) : 3m</p>
                <p>🔸Cadets (1D), Juniors (1D), Seniors (1D): 4m</p>
                <p>🔸Temps de récupération : 2x la durée d'un combat</p>
              </Dropdown>
              <Dropdown title="Golden Score (prolongation)" isOpen={isOpen14} toggle={toggleDropdown14}>
                <p>Golden Score est une période de prolongation utilisée pour départager les judokas lorsque le temps réglementaire se termine sans qu'un vainqueur soit déterminé. Durant cette phase, le premier judoka à marquer un point (Ippon, Waza-ari, ou par une pénalité Shido attribuée à l'adversaire) remporte le combat. Il n'y a pas de limite de temps pour le Golden Score, et le combat continue jusqu'à ce qu'une action décisive se produise.</p>
              </Dropdown>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720760819/images/arbitrage/arbitrage_judo_07_mo5tqx.webp" alt="Durée des combats" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Table d'arbitrage & Commissaires Sportifs</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720732018/images/arbitrage/arbitrage_judo_05_yce4cw.webp" alt="Équipement de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Enregistrement des Scores, du temps, annonce des résultats" isOpen={isOpen15} toggle={toggleDropdown15}>
                <p>🔸Les commissaires sportifs enregistrent les points marqués par les judokas (Ippon, Waza-ari) et les pénalités (Shido, Hansoku-make) en temps réel.</p>
                <p>🔸Ils supervisent le chronomètre, assurant que la durée des combats et des immobilisations au sol est respectée, y compris le temps pour le Golden Score.</p>
                <p>🔸Ils assistent l'arbitre en vérifiant et confirmant les décisions prises sur le tapis, en utilisant parfois des outils vidéo pour les vérifications (révision vidéo).</p>
                <p>🔸Après chaque combat, ils annoncent officiellement le vainqueur en fonction des points et pénalités enregistrés.</p>
              </Dropdown>
              <Dropdown title="Importance de la Table de Marquage" isOpen={isOpen16} toggle={toggleDropdown16}>
                <p>La table de marquage assure une gestion équitable et transparente des combats. Les commissaires sportifs y travaillent en étroite collaboration avec les arbitres pour garantir que les règles du judo sont appliquées correctement et que les résultats sont précis. Leur rôle est essentiel pour le bon déroulement des compétitions et pour maintenir l'intégrité et l'esprit sportif du judo.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 La formation des arbitres</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>Des Ecoles d'arbitrage au sein des comités départementaux permettent la formation des arbitres et commissaires sportifs</p>
              <p>Niveaux d'arbitrage (départemental, régional, national, international)</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720731410/images/arbitrage/arbitrage_judo_02_rpacmx.jpg" alt="Formation des arbitres" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ArbitrageJudo;
