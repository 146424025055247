import React, { useEffect, useState } from "react";
import { getGradesByAdherentId } from "../../../services/gradCnServices";

function AdherentGradeCN({ adherentId }) {
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        const gradesData = await getGradesByAdherentId(adherentId);
        setGrades(gradesData);
      }
    }

    fetchData();
  }, [adherentId]);

  const renderUvIcon = (value) => {
    return value === 1 ? "✅" : "❌";
  };

  if (grades.length === 0) {
    return null; // Ne rien afficher si aucune donnée
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-4 shadow-lg max-w-[800px] mx-auto font-satoshi">
      <div className="bg-gray-300 dark:bg-gray-600 p-4 rounded">
        <h3 className="text-2xl font-light uppercase mb-4 text-left">
          Grade CN
        </h3>

        {grades.length > 0 ? (
          <div className="space-y-4">
            {grades.map((grade, index) => (
              <div
                key={index}
                className="bg-white dark:bg-slate-800 rounded-lg shadow-lg p-4 flex items-center"
              >
                {/* UV1 et UV2 à gauche */}
                <div className="w-1/3 text-gray-700 dark:text-gray-300 text-sm">
                  <p>
                    <strong className="dark:text-gray-300">UV1 :</strong>{" "}
                    {renderUvIcon(grade.uv1)}
                  </p>
                  <p className="mt-2">
                    <strong className="dark:text-gray-300">UV2 :</strong>{" "}
                    {renderUvIcon(grade.uv2)}
                  </p>
                </div>

                {/* UV3 et UV4 au milieu */}
                <div className="w-1/3 text-gray-700 dark:text-gray-300 text-sm">
                  <p>
                    <strong className="dark:text-gray-300">UV3 :</strong>{" "}
                    {renderUvIcon(grade.uv3)}
                  </p>
                  <p className="mt-2">
                    <strong className="dark:text-gray-300">UV4 :</strong>{" "}
                    {renderUvIcon(grade.uv4)}
                  </p>
                </div>

                {/* Points UV3 à droite */}
                <div className="w-1/3 text-center">
                  <div className="bg-gray-200 dark:bg-gray-700 rounded-lg p-4">
                    <p className="text-2xl font-bold text-gray-800 dark:text-white">
                      {grade.uv3_points} pts
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-300">
                      UV3 Points
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-800 dark:text-gray-200 py-4">
            Aucun grade CN disponible.
          </p>
        )}
      </div>
    </div>
  );
}

export default AdherentGradeCN;
