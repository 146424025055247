import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FaUsers,
  FaCalendarAlt,
  FaEnvelope,
  FaMoneyBillWave,
  FaUserCheck,
  FaChartBar,
  FaAddressCard,
  FaCartPlus,
  FaRegCreditCard,
  FaUserCircle,
  FaFileSignature,
  FaRegNewspaper,
  FaImage,
  FaComment,
  FaHourglassHalf,
  FaCalculator,
  FaShoppingBag,
  FaUserGraduate,
  FaUserLock,
  FaUser,
} from "react-icons/fa";
import { IoMdContacts, IoMdSettings } from "react-icons/io";
import {
  GiBlackBelt,
  GiKimono,
  GiPodiumWinner,
  GiPodium,
  GiGong,
} from "react-icons/gi";
import {
  MdDashboard,
  MdTimer,
  MdOutlineInventory,
  MdScoreboard,
} from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import { BsClipboardCheckFill } from "react-icons/bs";

const linkIcon = {
  "Tableau de Bord": <MdDashboard />,
  "Dossier d'Inscription": <FaAddressCard />,
  "Fiche Adhérent": <FaUserCircle />,
  Calendrier: <FaCalendarAlt />,
  Messages: <FaEnvelope />,
  Chat: <FaComment />,
  Boutique: <FaCartPlus />,
  "Commandes & Règlements": <FaRegCreditCard />,
  "Techniques Judo": <GiKimono />,
  Actualités: <FaRegNewspaper />,
  Lexique: <PiTranslateFill />,
  "Arbitrage Judo": <GiGong />,
  "Judo Score": <MdScoreboard />,
  "Galerie Photos": <FaImage />,
  "Listing Adhérents": <IoMdContacts />,
  "Passage Ceinture": <GiBlackBelt />,
  "Grade CN": <GiBlackBelt />,
  "Suivi Compétitions": <GiPodiumWinner />,
  Palmarès: <GiPodium />,
  ToDoList: <LuListTodo />,
  Événements: <FaCalendarAlt />,
  "Appel Présence": <FaUserCheck />,
  "Tableau Appel": <BsClipboardCheckFill />,
  "Dashboard Adhérents": <FaChartBar />,
  "Dashboard Comptabilité": <FaChartBar />,
  "Détails Adhérent": <FaUserCircle />,
  "Listing Utilisateurs": <FaUsers />,
  "Listing Adhérents Admin": <FaUsers />,
  "Règlements Inscriptions": <FaMoneyBillWave />,
  "Dossiers d'Inscription": <FaFileSignature />,
  "Achats Boutique": <FaCartPlus />,
  "Articles Admin": <FaShoppingBag />,
  "Dépenses Recettes": <FaCalculator />,
  Coachings: <MdTimer />,
  "Heures Enseignement": <FaHourglassHalf />,
  Inventaire: <MdOutlineInventory />,
  "Chat Page Admin": <FaComment />,
  "Message Admin": <FaEnvelope />,
  Paramètres: <IoMdSettings />,
};

const sections = {
  2: {
    label: "Utilisateur",
    color: "text-blue-500 bg-blue-100",
    icon: <FaUser className="text-blue-500 mr-2" />,
    links: [
      "Tableau de Bord",
      "Dossier d'Inscription",
      "Fiche Adhérent",
      "Calendrier",
      "Messages",
      "Chat",
      "Boutique",
      "Commandes & Règlements",
      "Actualités",
      "Galerie Photos",
      "Lexique",
      "Arbitrage Judo",
      "Judo Score",
      "Techniques Judo",
    ],
  },
  3: {
    label: "Enseignant",
    color: "text-green-500 bg-green-100",
    icon: <FaUserGraduate className="text-green-500 mr-2" />,
    links: [
      "Listing Adhérents",
      "Appel Présence",
      "Tableau Appel",
      "Passage Ceinture",
      "Grade CN",
      "Événements",
      "Suivi Compétitions",
      "Palmarès",
      "ToDoList",
    ],
  },
  1: {
    label: "Administrateur",
    color: "text-red-500 bg-red-100",
    icon: <FaUserLock className="text-red-500 mr-2" />,
    links: [
      "Dashboard Adhérents",
      "Dashboard Comptabilité",
      "Détails Adhérent",
      "Listing Utilisateurs",
      "Listing Adhérents Admin",
      "Règlements Inscriptions",
      "Dossiers d'Inscription",
      "Achats Boutique",
      "Articles Admin",
      "Dépenses Recettes",
      "Coachings",
      "Heures Enseignement",
      "Inventaire",
      "Chat Page Admin",
      "Message Admin",
      "Paramètres",
    ],
  },
};

const MegaMenu = ({ role_id, settings, closeMegaMenu }) => {
  const lastActiveTab =
    parseInt(localStorage.getItem("lastActiveTab"), 10) || 0;
  const [activeTab, setActiveTab] = useState(lastActiveTab);
  const orderedSections =
    role_id === 1 || role_id === 4
      ? [sections[2], sections[3], sections[1]]
      : role_id === 3
      ? [sections[2], sections[3]]
      : [sections[2]];

  const menuRef = useRef(null);

  const handleSwipe = (direction) => {
    if (direction === "left" && activeTab < orderedSections.length - 1) {
      setActiveTab((prev) => {
        localStorage.setItem("lastActiveTab", prev + 1);
        return prev + 1;
      });
    }
    if (direction === "right" && activeTab > 0) {
      setActiveTab((prev) => {
        localStorage.setItem("lastActiveTab", prev - 1);
        return prev - 1;
      });
    }
  };

  const handleTabChange = (idx) => {
    setActiveTab(idx);
    localStorage.setItem("lastActiveTab", idx);
  };

  return (
    <div
      ref={menuRef}
      className="max-w-[1280px] mx-auto bg-base-200 dark:bg-slate-600 font-satoshi relative p-2 rounded-lg shadow-lg overflow-hidden"
    >
      {/* Bouton de fermeture */}
      <button
        onClick={closeMegaMenu}
        className="absolute top-4 right-4 bg-primary text-white w-10 h-10 rounded-full flex items-center justify-center shadow-md hover:bg-red-600"
      >
        ×
      </button>

      {/* Tabs */}
      <div className="flex justify-center flex-wrap gap-2 p-4">
        {orderedSections.map((section, idx) => (
          <button
            key={idx}
            onClick={() => handleTabChange(idx)}
            className={`px-4 py-2 text-sm font-semibold rounded-lg font-satoshi ${
              activeTab === idx
                ? `${section.color} text-dark`
                : "bg-white text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-white"
            }`}
          >
            {section.label}
          </button>
        ))}
      </div>

      {/* Panels */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${activeTab * 100}%)`,
          width: `${orderedSections.length * 33.33}%`,
        }}
      >
        {orderedSections.map((section, idx) => (
          <div
            key={idx}
            className="flex-shrink-0 w-full flex justify-center items-start font-satoshi"
            style={{
              minWidth: "100%",
            }}
          >
            <div className="p-5 w-full max-w-[1280px] overflow-y-auto max-h-[70vh]">
              <div
                className={`text-xl font-semibold mb-4 text-center p-2 rounded flex items-center justify-center ${section.color}`}
              >
                {section.icon}
                {section.label}
              </div>
              <ul className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {section.links.map((link, linkIdx) => (
                  <li
                    key={linkIdx}
                    className="text-center bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg transition-transform hover:border-primary hover:shadow-primary"
                  >
                    <NavLink
                      to={`/${link
                        .toLowerCase()
                        .replace(/[\s'ç]/g, "-")
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")}`}
                      className="block relative overflow-hidden p-3"
                      onClick={closeMegaMenu}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <div className="text-3xl text-primary my-3">
                          {linkIcon[link]}
                        </div>
                        <span className="block text-sm font-semibold text-gray-800 dark:text-white font-satoshi">
                          {link}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Flèches */}
      <button
        onClick={() => handleSwipe("right")}
        className="hidden md:block absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        ‹
      </button>
      <button
        onClick={() => handleSwipe("left")}
        className="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        ›
      </button>
    </div>
  );
};

export default MegaMenu;
