import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { getGroupsHome } from "../../services/groupServices";
import { getSettings } from "../../services/settingsServices";
import { getAdherentsByGroup } from "../../services/dashboardServices";
import { useUser } from "./../Home/UserContext";

const GroupTable = () => {
  const { user } = useUser();
  const [groups, setGroups] = useState([]);
  const [openGroupIndex, setOpenGroupIndex] = useState(null);
  const [saisonActive, setSaisonActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome();
        const adherentsData = await getAdherentsByGroup();

        if (groupsData) {
          // Combine les données des groupes avec celles des adhérents
          const combinedGroups = groupsData.map((group) => {
            const adherentsInfo = adherentsData.find(
              (ad) => ad.nom === group.nom
            );
            return {
              ...group,
              adherentCount: adherentsInfo?.adherentCount || 0,
              quota_groupe: adherentsInfo?.quota_groupe || 0,
            };
          });

          setGroups(combinedGroups);
        } else {
          setGroups([]);
          console.error("Aucune donnée reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des groupes:", error);
      }
    };

    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (
          settingsData &&
          settingsData.length > 0 &&
          settingsData[0].saison_active
        ) {
          setSaisonActive(settingsData[0].saison_active);
        } else {
          console.error("Aucune saison active reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres:", error);
      }
    };

    fetchGroups();
    fetchSettings();
  }, []);

  const handleButtonClick = () => {
    if (user) {
      navigate("/ajouter-inscription-adherent");
    } else {
      navigate("/register");
    }
  };

  const getButtonText = () => {
    return user
      ? "Faire une inscription ou cours d'essai"
      : "S'inscrire pour faire une adhésion";
  };

  const getGroupColorClass = (color_groupe) => {
    return color_groupe || "bg-gray-400";
  };

  const getFillStyles = (percentage, color) => {
    const lightColor = `${color}80`; // Couleur plus claire (80% d'opacité)
    return {
      background: `linear-gradient(to right, ${color} ${percentage}%, ${lightColor} ${percentage}%)`,
    };
  };

  const handleGroupClick = (index) => {
    setOpenGroupIndex(openGroupIndex === index ? null : index);
  };

  const getLightColorClass = (color) => {
    const colorMap = {
      "bg-orange-400": "bg-orange-200",
      "bg-indigo-400": "bg-indigo-200",
      "bg-purple-400": "bg-purple-200",
      "bg-green-400": "bg-green-200",
      "bg-yellow-400": "bg-yellow-200",
      "bg-blue-400": "bg-blue-200",
      "bg-red-400": "bg-red-200",
      "bg-pink-400": "bg-pink-200",
      "bg-gray-400": "bg-gray-200",
      "bg-cyan-400": "bg-cyan-200",
    };

    return colorMap[color] || "bg-gray-200"; // Par défaut, utilisez "bg-gray-200"
  };

  return (
    <section className="py-12 px-4 bg-lightBackground dark:bg-darkBackground text-black dark:text-white">
      <h2 className="text-3xl font-extrabold mb-4 text-primary mx-auto font-satoshi text-center">
        GROUPES - HORAIRES - TARIFS
      </h2>
      {saisonActive && (
        <h3 className="text-xl text-center mb-8 mt-2 font-satoshi">
          pour la saison : {saisonActive}
        </h3>
      )}
      {groups.map((group, index) => {
        const percentage =
          group.quota_groupe > 0
            ? Math.round((group.adherentCount / group.quota_groupe) * 100)
            : 0;

        return (
          <div
            key={index}
            className="max-w-[1280px] mx-auto collapse collapse-arrow border mb-8 font-satoshi dark:text-gray-800 border-gray-400 dark:border-gray-400 shadow-lg"
          >
            {/* Barre de remplissage dynamique */}
            <div className="relative h-12 overflow-hidden flex">
              {/* Partie remplie */}
              <div
                className={`${group.color_groupe} h-full`}
                style={{
                  width: `${percentage}%`, // Largeur dynamique basée sur le pourcentage
                }}
              ></div>
              {/* Partie vide */}
              <div
                className={`h-full ${getLightColorClass(group.color_groupe)}`}
                style={{
                  width: `${100 - percentage}%`, // Largeur restante
                }}
              ></div>
            </div>

            <input
              type="radio"
              name="accordion"
              className="peer"
              id={`group-${index}`}
              hidden
              checked={openGroupIndex === index}
            />
            <label
              htmlFor={`group-${index}`}
              className="collapse-title text-xl font-medium cursor-pointer bg-gray-200 dark:bg-gray-400 dark:text-gray-800 shadow-xl"
              onClick={() => handleGroupClick(index)}
            >
              <div
                className="rounded-lg p-2"
                style={getFillStyles(percentage, group.color_groupe || "#ccc")}
              >
                {group.nom} |{" "}
                <span className="italic font-light text-base">
                  {group.annees_naissance}
                </span>
              </div>
            </label>
            <div className="collapse-content bg-white text-gray-800 dark:bg-gray-600 dark:text-white font-satoshi">
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">Horaire 1 :</strong>{" "}
                {group.horaire1}
              </p>
              {group.horaire2 && (
                <p className="mt-2 dark:text-slate-200">
                  <strong className="dark:text-slate-400">Horaire 2 :</strong>{" "}
                  {group.horaire2}
                </p>
              )}
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">Enseignant(s) :</strong>{" "}
                {group.enseignant1 && <span>{group.enseignant1}</span>}
                {group.enseignant1 && group.enseignant2 && " & "}
                {group.enseignant2 && <span>{group.enseignant2}</span>}
              </p>
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">Tarifs : </strong>
                {group.tarif_licence}€ Licence - {group.tarif_cotisation}€
                Cotisation
              </p>
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">
                  Tarif Total Annuel :
                </strong>{" "}
                {parseFloat(group.tarif_licence) +
                  parseFloat(group.tarif_cotisation)}
                €
              </p>
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">Inscrits :</strong>{" "}
                {group.adherentCount} / {group.quota_groupe}{" "}
                <span className="italic">({percentage}% rempli)</span>
              </p>
            </div>
          </div>
        );
      })}

      <Button
        text={getButtonText()}
        onClick={handleButtonClick}
        className="mx-auto"
      />
    </section>
  );
};

export default GroupTable;
