import React, { useState, useEffect } from "react";
import StatisticsCards from "./StatisticsCards";
import GroupDistributionChart from "./GroupDistributionChart";
import GenderDistributionChart from "./GenderDistributionChart";
import AgeDistributionChart from "./AgeDistributionChart";
import CommunityStatisticsTable from "./CommunityStatisticsTable";
import CourseStatisticsTable from "./CourseStatisticsTable";
import CommuneDistributionChart from "./CommuneDistributionChart";
import LatestMembers from "./LatestMembers";
import VisitesLineChart from "./VisitesLineChart";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";

const Dashboard = () => {
  useScrollToTop();
  // État pour chaque section du dashboard
  const [adherentsCount, setAdherentsCount] = useState(0);
  const [inscritsCount, setInscritsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [groupData, setGroupData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [ageDistributionData, setAgeDistributionData] = useState([]);
  const [communityStats, setCommunityStats] = useState({});
  const [courseStats, setCourseStats] = useState([]);
  const [latestMembers, setLatestMembers] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Statistiques Adhérents
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4 mt-4 font-satoshi">
        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Derniers Adhérents Inscrits
          </h2>
          <LatestMembers data={latestMembers} />
        </div>
        <h2 className="text-2xl uppercase font-bold mb-5 dark:text-white">
          Statistiques Adhérents
        </h2>
        <StatisticsCards
          adherentsCount={adherentsCount}
          inscritsCount={inscritsCount}
          usersCount={usersCount}
        />
        <div className="w-full max-w-4xl px-2 mb-8">
          <VisitesLineChart />
        </div>
        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Répartitions sur les cours
          </h2>
          <GroupDistributionChart data={groupData} />
        </div>
        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Répartitions par genre et âge
          </h2>
          <div className="flex flex-col md:flex-row justify-between mt-5 gap-4">
            <div className="md:w-1/2">
              <GenderDistributionChart data={genderData} />
            </div>
            <div className="md:w-1/2">
              <AgeDistributionChart data={ageDistributionData} />
            </div>
          </div>
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Statistiques des Adhérents par Commune, Genre et Tranche d'Âge
          </h2>
          <CommunityStatisticsTable data={communityStats} />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Répartition des Adhérents par Commune
          </h2>
          <CommuneDistributionChart />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Statistiques par Cours
          </h2>
          <CourseStatisticsTable data={courseStats} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
