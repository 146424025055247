import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Fonction pour obtenir un item de localStorage et le parser
  const getLocalStorageItem = (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(
        `Erreur lors de la lecture de ${key} du localStorage:`,
        error
      );
      return null;
    }
  };

  // Fonction pour définir un item dans le localStorage
  const setLocalStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(
        `Erreur lors de l'écriture dans ${key} du localStorage:`,
        error
      );
    }
  };

  // Initialiser un utilisateur "visiteur" si aucun utilisateur n'existe
  useEffect(() => {
    const userData = getLocalStorageItem("userInfo");

    if (!userData) {
      const visitorUser = {
        role_id: 4, // Visiteur
        nom: "Visiteur",
        prenom: "Mode Démo",
        userId: 412, // ID de l'utilisateur visiteur
        token: "visitor-token", // Token factice pour le visiteur
      };

      setLocalStorageItem("userInfo", visitorUser);
      localStorage.setItem("token", "visitor-token"); // Ajouter le token dans localStorage
      setUser(visitorUser); // Définir l'utilisateur comme visiteur par défaut
    } else {
      setUser(userData); // Charger l'utilisateur existant
    }
  }, []);

  // Méthode pour mettre à jour l'utilisateur
  const updateUser = (userData) => {
    setLocalStorageItem("userInfo", userData);
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, useUser };
