import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "./../../../components/Button/Button";
import { updateDossierInscription } from "./../../../services/dossierServices";

const EditDossierInscriptionModal = ({
  isOpen,
  onClose,
  dossier,
  refreshList,
}) => {
  const [formData, setFormData] = useState({
    cm_url: "",
    date_cm: "",
    validate_cm: false,
    formulaire_licence_a_signer: "",
    formulaire_licence_signe: "",
    licence_url: "",
    validate_licence: false,
    validate_reglement: false,
    lien_drive: "",
  });

  useEffect(() => {
    console.log("Updating modal data for dossier:", dossier);
    if (dossier) {
      setFormData({
        cm_url: dossier.cm_url || "",
        date_cm: dossier.date_cm ? dossier.date_cm.split("T")[0] : "",
        validate_cm: dossier.validate_cm || false,
        formulaire_licence_a_signer: dossier.formulaire_licence_a_signer || "",
        formulaire_licence_signe: dossier.formulaire_licence_signe || "",
        licence_url: dossier.licence_url || "",
        validate_licence: dossier.validate_licence || false,
        validate_reglement: dossier.validate_reglement || false,
        lien_drive: dossier.lien_drive || "",
      });
    }
  }, [dossier]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Attempting to save data", formData);
    try {
      await updateDossierInscription(dossier.id, formData);
      refreshList();
      onClose();
      toast.success("Dossier mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du dossier", error);
      toast.error("Erreur lors de la mise à jour du dossier.");
    }
  };

  const handleClose = () => {
    console.log("Closing modal");
    onClose();
  };

  console.log("Modal isOpen status:", isOpen);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 dark:text-black font-satoshi">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Éditer un Dossier d'inscription
        </h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="cm_url"
            placeholder="URL du Certificat Médical"
            value={formData.cm_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="date"
            name="date_cm"
            value={formData.date_cm}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label className="label cursor-pointer justify-start gap-2 mb-3">
            <input
              type="checkbox"
              name="validate_cm"
              checked={formData.validate_cm}
              onChange={handleChange}
              className="checkbox checkbox-primary"
            />
            <span className="ml-2">Certificat Médical Validé</span>
          </label>
          <input
            type="text"
            name="formulaire_licence_a_signer"
            placeholder="Formulaire de Licence à Signer"
            value={formData.formulaire_licence_a_signer}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="text"
            name="formulaire_licence_signe"
            placeholder="Formulaire de Licence Signé"
            value={formData.formulaire_licence_signe}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="text"
            name="licence_url"
            placeholder="Licence URL"
            value={formData.licence_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label className="flex items-center">
            <input
              type="checkbox"
              name="validate_licence"
              checked={formData.validate_licence}
              onChange={handleChange}
              className="checkbox checkbox-primary mb-3"
            />
            <span className="ml-2">Licence Validée</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="validate_reglement"
              checked={formData.validate_reglement}
              onChange={handleChange}
              className="checkbox checkbox-primary mb-3"
            />
            <span className="ml-2">Règlement Validé</span>
          </label>
          <input
            type="text"
            name="lien_drive"
            placeholder="Lien Drive"
            value={formData.lien_drive}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Sauvegarder"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDossierInscriptionModal;
