import React, { useEffect, useState } from "react";
import NotificationsDisplay from "./NotificationsDisplay";
import MessagesDisplay from "./MessagesDisplay";
import CompetitionsDisplay from "./CompetitionsDisplay";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import ContactFormDisplay from "./ContactFormDisplay";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import InfoBubble from "../../../components/InfoBubble/InfoBubble";

const DashboardUser = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [userId, setUserId] = useState(null);

  const getUserIdFromLocalStorage = () => {
    try {
      const userData = localStorage.getItem("userInfo");
      if (userData) {
        const userObj = JSON.parse(userData);
        return userObj.userId;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la lecture des données de l'utilisateur du localStorage:",
        error
      );
      toast.error("Erreur lors de la lecture des données de l'utilisateur.");
      return null;
    }
  };

  useEffect(() => {
    if (user?.role_id !== 4) {
      // Récupérer userId pour les utilisateurs non visiteurs
      const id = getUserIdFromLocalStorage();
      if (!id) {
        console.error("L'ID de l'utilisateur n'est pas trouvé");
        toast.error("L'ID de l'utilisateur n'est pas trouvé.");
      } else {
        setUserId(id);
      }
    }
  }, [user]);

  if (!user && userRole !== "visiteur") {
    return (
      <div className="text-center">
        <h2>Vous devez être connecté pour accéder à cette page.</h2>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Tableau de Bord
      </h2>
      <DashboardNavigation role={userRole} />

      <InfoBubble title="Mode Démonstration">
        <p>
          ✅ En tant que visiteur, vous ne pouvez pas voir les notifications, les
          messages ou les compétitions.
        </p>
        <p>
          ✅ Pensez à vous connecter ou vous inscrire pour voir toutes les fonctionnalités.
        </p>
      </InfoBubble>

      <div className="flex flex-col items-center w-full px-4 mt-4">
        {(userRole === "admin" || userRole === "visiteur") && (
          <div className="w-full max-w-4xl px-2 mb-4">
            <ContactFormDisplay userId={userId} role_id={user?.role_id || 4} />
          </div>
        )}
        <div className="w-full max-w-4xl px-2">
          <NotificationsDisplay userId={userId || 0} />
        </div>
        <div className="w-full max-w-4xl px-2 my-8">
          <MessagesDisplay userId={userId || 0} />
        </div>
        <div className="w-full max-w-4xl px-2 mb-4 mt-4">
          <CompetitionsDisplay userId={userId || 0} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardUser;
