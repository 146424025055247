import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { countVisitesByDate, deleteVisitesByDate } from "../../../services/visitesServices"; // Assurez-vous que le chemin est correct
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import { useUser } from "../../Home/UserContext";
import { toast } from "react-toastify";

const VisitesLineChart = () => {
  const [data, setData] = useState([]);
  const [dateDebut, setDateDebut] = useState(""); // État pour la date de début
  const [dateFin, setDateFin] = useState(""); // État pour la date de fin
  const [loading, setLoading] = useState(false); // État pour gérer le chargement
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    const fetchVisites = async () => {
      try {
        const response = await countVisitesByDate();
        setData(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de visites", error);
      }
    };

    fetchVisites();
  }, []);

  // Fonction pour gérer la suppression des visites
  const handleDelete = async () => {
    if (!dateDebut || !dateFin) {
      alert("Veuillez sélectionner les dates de début et de fin.");
      return;
    }

    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à supprimer les notifications."
          );
          return;
        }

    setLoading(true);
    try {
      const response = await deleteVisitesByDate(dateDebut, dateFin);
      alert(response.message || "Visites supprimées avec succès");
      
      // Après la suppression, recharger les données mises à jour
      const updatedData = await countVisitesByDate();
      setData(updatedData);
    } catch (error) {
      console.error("Erreur lors de la suppression des visites :", error);
      alert("Une erreur est survenue lors de la suppression des visites.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">Évolution des Visites</h3>
      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="gray"
              className="dark:stroke-gray-300"
            />
            <XAxis
              dataKey="date_visite"
              stroke="#666"
              className="dark:stroke-gray-300"
            />
            <YAxis stroke="#666" className="dark:stroke-gray-200" />
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                border: "none",
                color: "#fff",
              }}
              cursor={{
                stroke: "gray",
                strokeDasharray: "3 3",
              }}
            />
            <Legend wrapperStyle={{ color: "#333", fill: "#333" }} />
            <Line
              type="monotone"
              dataKey="compteur" // Utilisation du champ compteur
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Zone pour la suppression des visites */}
      <div className="mt-8">
        <h4 className="text-lg font-semibold text-gray-200 dark:text-gray-100 mb-4">
          Supprimer des visites entre deux dates
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Input pour la date de début */}
          <input
            type="date"
            className="p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            value={dateDebut}
            onChange={(e) => setDateDebut(e.target.value)}
          />

          {/* Input pour la date de fin */}
          <input
            type="date"
            className="p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            value={dateFin}
            onChange={(e) => setDateFin(e.target.value)}
          />

          {/* Bouton pour supprimer */}
          <button
            className={`p-2 rounded text-white ${loading ? "bg-gray-500" : "bg-red-600"} hover:bg-red-700 ${
                          userRole === "visiteur"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
            onClick={handleDelete}
            disabled={userRole === "visiteur"}
          >
            {loading ? "Suppression en cours..." : "Supprimer les visites"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VisitesLineChart;
