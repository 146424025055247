import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllAdherentsWithGroupAndUsers,
  updateAdherent,
  deleteAdherent,
  addAdherentToGroup, // Importer la fonction addAdherentToGroup
} from "./../../../services/adherentServices";
import { getSettings } from "../../../services/settingsServices";
import { getGroupsList } from "../../../services/groupServices";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import EditAdherentAdminModal from "./EditAdherentAdminModal";
import ChangeGroupModal from "./../../PagesEnseignants/ListingAdherents/ChangeGroupModal"; // Importer la modale de changement de groupe
import AddGroupModal from "../../PagesEnseignants/ListingAdherents/AddGroupModal";
import jsPDF from "jspdf"; // Pour PDF
import "jspdf-autotable"; // Pour les tableaux dans PDF
import * as XLSX from "xlsx"; // Pour Excel
import { saveAs } from "file-saver"; // Pour télécharger Excel
import Button from "../../../components/Button/Button"; // Composant Button pour ajouter un nouvel adhérent
import { FaPrint, FaFileExport } from "react-icons/fa"; // Icônes pour trier
import ButtonPrint from "../../../components/Button/ButtonPrint";
import generateAdherentPdf from "./AdherentPdfGenerator";



const ListingAdherentsAdmin = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [adherents, setAdherents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [seasonFilter, setSeasonFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [groups, setGroups] = useState([]); // État pour stocker les groupes
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentAdherent, setCurrentAdherent] = useState(null);
  const [duplicateWarning, setDuplicateWarning] = useState(null);
  const [settings, setSettings] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");
  const [selectedGroupToAdd, setSelectedGroupToAdd] = useState(null); // Pour stocker le groupe sélectionné

  const [isChangeGroupModalOpen, setIsChangeGroupModalOpen] = useState(false); // État pour la modale
  const [currentAdherentId, setCurrentAdherentId] = useState(null); // Pour stocker l'ID de l'adhérent à changer de groupe

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [adherentsData, settingsData, groupsData] = await Promise.all([
          getAllAdherentsWithGroupAndUsers(),
          getSettings(),
          getGroupsList(),
        ]);
        setAdherents(adherentsData);
        setGroups(groupsData); // On stocke les groupes pour les utiliser dans la modale
        checkForDuplicates(adherentsData);

        const fetchedSettings = settingsData[0];
        setSettings(fetchedSettings);
        setSeasonFilter(fetchedSettings?.saison_active);

        const previousSeason = getPreviousSeason(fetchedSettings.saison_active);
        const nextSeason = getNextSeason(fetchedSettings.saison_active);
        setSeasons([previousSeason, fetchedSettings.saison_active, nextSeason]);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        toast.error("Erreur lors du chargement des données");
      }
    };

    fetchData();
  }, []);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const checkForDuplicates = (data) => {
    const names = data.map(
      (adherent) =>
        `${adherent.nom.toLowerCase()} ${adherent.prenom.toLowerCase()}`
    );
    const duplicates = names.filter(
      (name, index) => names.indexOf(name) !== index
    );
    if (duplicates.length > 0) {
      setDuplicateWarning(
        `Attention, il existe un doublon dans ce tableau : ${duplicates.join(
          ", "
        )}`
      );
    } else {
      setDuplicateWarning(null);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSeasonFilterChange = (e) => {
    setSeasonFilter(e.target.value);
  };

  const handleGroupFilterChange = (e) => {
    setGroupFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleEditClick = (adherent) => {
    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à éditer les adhérents."
          );
          return;
        }
    setCurrentAdherent(adherent);
    setShowEditModal(true);
  };

  const handleEditSave = async (id, formData) => {
    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à éditer les adhérents."
          );
          return;
        }
    try {
      await updateAdherent(id, formData);
      setAdherents((prevAdherents) =>
        prevAdherents.map((adherent) =>
          adherent.id === id ? { ...adherent, ...formData } : adherent
        )
      );
      toast.success("Adhérent mis à jour avec succès");
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'adhérent:", error);
      toast.error("Erreur lors de la mise à jour de l'adhérent");
    }
  };

  const handleDeleteClick = async (id) => {
    const adherent = adherents.find((a) => a.id === id);
    const confirmation = window.confirm(
      `Êtes-vous sûr de vouloir supprimer l'adhérent ${adherent.nom} ${adherent.prenom} ?`
    );

    if (!confirmation) {
      return;
    }

    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à supprimer les adhérents."
          );
          return;
        }

    try {
      await deleteAdherent(id);
      setAdherents((prevAdherents) =>
        prevAdherents.filter((adherent) => adherent.id !== id)
      );
      toast.success("Adhérent supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'adhérent:", error);
      toast.error("Erreur lors de la suppression de l'adhérent");
    }
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const openChangeGroupModal = (adherentId) => {
    setCurrentAdherentId(adherentId);
    setIsChangeGroupModalOpen(true);
  };

  const handleAddGroup = async () => {
    if (selectedGroupToAdd) {
      try {
        await addAdherentToGroup(currentAdherentId, selectedGroupToAdd); // Utilisez `currentAdherentId` et `selectedGroupToAdd`
        toast.success("Groupe ajouté avec succès à l'adhérent");
        closeChangeGroupModal(true); // Rafraîchissement de la liste après succès
      } catch (error) {
        console.error("Erreur lors de l'ajout du groupe à l'adhérent:", error);
        toast.error("Erreur lors de l'ajout du groupe à l'adhérent");
      }
    } else {
      console.warn("Aucun groupe sélectionné"); // Cas où aucun groupe n'est sélectionné
    }
  };

  const closeChangeGroupModal = (refresh = false) => {
    setIsChangeGroupModalOpen(false);
    setCurrentAdherentId(null);
    setSelectedGroupToAdd(null); // Réinitialiser le groupe sélectionné
    if (refresh) {
      const fetchData = async () => {
        try {
          const data = await getAllAdherentsWithGroupAndUsers();
          setAdherents(data);
        } catch (error) {
          console.error("Erreur lors du chargement des adhérents:", error);
          toast.error("Erreur lors du chargement des adhérents");
        }
      };
      fetchData();
    }
  };

  const getStatusColor = (statut_inscription) => {
    switch (statut_inscription) {
      case "Licencié":
        return "bg-green-300";
      case "Licencié Hors Club":
        return "bg-green-200";
      case "Inscrit":
        return "bg-yellow-300";
      case "Cours d'essai":
        return "bg-red-300";
      case "Licencié - Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié et Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié":
        return "bg-slate-400";
      default:
        return "bg-base-100";
    }
  };

  const toggleSortOrder = () => {
    if (sortOrder === "none") {
      setSortOrder("asc");
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("none");
    }
  };

  const filteredAdherents = adherents
    .filter((adherent) => {
      const matchesSearchTerm =
        searchTerm === "" ||
        adherent.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        adherent.prenom.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesSeason =
        seasonFilter === "" || adherent.saison_active === seasonFilter;

      const matchesGroup =
        groupFilter === "" ||
        adherent.Groups.some((group) => group.nom === groupFilter);

      const matchesStatus =
        statusFilter === "" || adherent.statut_inscription === statusFilter;

      return (
        matchesSearchTerm && matchesSeason && matchesGroup && matchesStatus
      );
    })
    .sort((a, b) => {
      if (sortOrder === "none") return 0;
      return sortOrder === "asc"
        ? new Date(a.date_naissance) - new Date(b.date_naissance)
        : new Date(b.date_naissance) - new Date(a.date_naissance);
    });

  // Fonction pour exporter en Excel
  const handleExportExcel = () => {
  const formattedAdherents = filteredAdherents.map((adherent) => {
    const formattedAdherent = {};

    for (const key in adherent) {
      if (key === "date_naissance" && adherent[key]) {
        // Formatage des dates au format DD/MM/YYYY
        formattedAdherent[key] = new Date(adherent[key]).toLocaleDateString("fr-FR");
      } else if (key === "Groups") {
        // Conversion des groupes en liste de noms
        formattedAdherent[key] = adherent[key]
          .map((group) => group.nom) // Récupération du nom du groupe
          .join(", ");
      } else if (key === "Users") {
        // Conversion des utilisateurs en liste de noms
        formattedAdherent[key] = adherent[key]
          .map((user) => `${user.nom} ${user.prenom}`) // Concaténation nom et prénom
          .join(", ");
      } else if (Array.isArray(adherent[key])) {
        // Gestion des autres tableaux en les convertissant en JSON lisible
        formattedAdherent[key] = adherent[key]
          .map((item) => (typeof item === "object" ? JSON.stringify(item) : item))
          .join(", ");
      } else {
        // Conservation des autres valeurs telles quelles
        formattedAdherent[key] = adherent[key];
      }
    }

    return formattedAdherent;
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedAdherents);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Adherents");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, "adherents_filtered_complete.xlsx");
};


  // Fonction pour imprimer en PDF
  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Nom",
      "Prénom",
      "Statut",
      "Email",
      "Date de naissance",
      "Groupes",
    ];
    const tableRows = [];

    filteredAdherents.forEach((adherent) => {
      const adherentData = [
        adherent.nom,
        adherent.prenom,
        adherent.statut_inscription,
        adherent.email,
        adherent.date_naissance
          ? new Date(adherent.date_naissance).toLocaleDateString("fr-FR")
          : "",
        adherent.Groups.map((group) => group.nom).join(", "),
      ];
      tableRows.push(adherentData);
    });

    doc.text("Liste des Adhérents Filtrés", 14, 15);
    doc.autoTable({
      startY: 20,
      head: [tableColumn],
      body: tableRows,
    });
    doc.save("adherents_filtered.pdf");
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Listing Adhérents
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="shadow-xl w-full max-w-[1280px] mx-auto mb-4">
        {duplicateWarning && (
          <div className="bg-red-500 text-white p-4 mb-4 rounded">
            {duplicateWarning}
          </div>
        )}
        <input
          type="text"
          placeholder="Rechercher par nom ou prénom"
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full mb-4 dark:bg-slate-300"
        />
        <div className="flex flex-col md:flex-row md:space-x-4">
          <select
            onChange={handleSeasonFilterChange}
            value={seasonFilter}
            className="select select-bordered w-full md:w-1/3 mb-4 md:mb-0 dark:bg-slate-300 dark:text-gray-800"
          >
            <option value="">Toutes les saisons</option>
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>

          <select
            onChange={handleGroupFilterChange}
            value={groupFilter}
            className="select select-bordered w-full md:w-1/3 dark:bg-slate-300 dark:text-gray-800 mb-4"
          >
            <option value="">Tous les groupes</option>
            {groups.map((group) => (
              <option key={group.id} value={group.nom}>
                {group.nom}
              </option>
            ))}
          </select>

          <select
            onChange={handleStatusFilterChange}
            value={statusFilter}
            className="select select-bordered w-full md:w-1/3 dark:bg-slate-300 dark:text-gray-800 mb-4"
          >
            <option value="">Tous les statuts</option>
            <option value="Licencié">Licencié</option>
            <option value="Inscrit">Inscrit</option>
            <option value="Cours d'essai">Cours d'essai</option>
            <option value="Licencié - Arrêt">Licencié - Arrêt</option>
            <option value="Non Licencié - Arrêt">Non Licencié - Arrêt</option>
            <option value="Licencié Hors Club">Licencié Hors Club</option>
            <option value="Ancien Licencié">Ancien Licencié</option>
          </select>
        </div>

        <div className="flex justify-start mb-4 pl-2">
          <p className="text-lg font-medium">
            Nombre d'adhérents affichés : {filteredAdherents.length}
          </p>
        </div>

        <div className="flex flex-wrap mb-4 gap-4">
          {/* Bouton Imprimer PDF */}
          <Button
            text={
              <>
                <FaPrint className="mr-2" /> Imprimer
              </>
            }
            onClick={handlePrintPDF}
          />

          {/* Bouton Exporter Excel */}
          <Button
            text={
              <>
                <FaFileExport className="mr-2" /> Exporter Excel
              </>
            }
            onClick={handleExportExcel}
          />
        </div>

        <div className="overflow-x-auto shadow-xl mt-4">
          <table className="table bg-white dark:bg-slate-300 font-satoshi max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Photo
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Nom Prénom
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Statut Inscription
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  DDN
                  <button onClick={toggleSortOrder} className="ml-2">
                    {sortOrder === "none"
                      ? "⇅"
                      : sortOrder === "asc"
                      ? "▲"
                      : "▼"}
                  </button>
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Groupe
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Utilisateurs
                </th>
                <th className="px-3 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAdherents.map((adherent) => (
                <tr key={adherent.id} className="text-left">
                  <td>
                    <img
                      className="rounded-full w-10 h-10 object-cover"
                      src={adherent.photo_url || "/img/user_avatar.jpg"}
                      alt="Avatar"
                    />
                  </td>
                  <td className="text-left">
                    {adherent.nom} {adherent.prenom}
                  </td>
                  <td className="text-left">
                    <span
                      className={`inline-block px-3 py-1 text-xs font-semibold text-gray-700 rounded-full ${getStatusColor(
                        adherent.statut_inscription
                      )}`}
                    >
                      {adherent.statut_inscription}
                    </span>
                  </td>
                  <td className="text-left">{adherent.email}</td>
                  <td className="text-left">
                    {new Date(adherent.date_naissance).toLocaleDateString()}
                  </td>
                  <td className="text-left">
                    {adherent.Groups.length > 0 ? (
                      adherent.Groups.map((group) => group.nom).join(", ")
                    ) : (
                      <span
                        onClick={() => openChangeGroupModal(adherent.id)}
                        className="inline-block px-3 py-1 text-xs font-semibold text-white bg-red-500 rounded-full cursor-pointer"
                      >
                        Sans Groupe
                      </span>
                    )}
                  </td>
                  <td className="text-left">
                    {adherent.Users.map((user) => (
                      <div key={user.email}>
                        <img
                          className="rounded-full w-6 h-6 object-cover inline-block mr-2"
                          src={user.photoURL || "/img/user_avatar.jpg"}
                          alt="User Avatar"
                        />
                        {user.nom} {user.prenom}
                      </div>
                    ))}
                  </td>
                  <td className="px-2 py-2 border-none dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    {/* Nouveau bouton pour imprimer la fiche PDF */}
                    <ButtonPrint
                      onClick={() => generateAdherentPdf(adherent)}
                    />
                    <ButtonEdit onClick={() => handleEditClick(adherent)}className={`${
                          userRole === "visiteur"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        disabled={userRole === "visiteur"} />
                    <ButtonDelete onClick={() => handleDeleteClick(adherent.id)} className={`${
                          userRole === "visiteur"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        disabled={userRole === "visiteur"} />
                  </div>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
      {showEditModal && (
        <EditAdherentAdminModal
          isOpen={showEditModal}
          onClose={handleCloseModal}
          adherent={currentAdherent}
          refreshList={() => {
            const fetchData = async () => {
              try {
                const data = await getAllAdherentsWithGroupAndUsers();
                setAdherents(data);
              } catch (error) {
                console.error(
                  "Erreur lors du chargement des adhérents:",
                  error
                );
                toast.error("Erreur lors du chargement des adhérents");
              }
            };
            fetchData();
          }}
        />
      )}
      {isChangeGroupModalOpen && (
        <AddGroupModal
          isOpen={isChangeGroupModalOpen}
          onClose={closeChangeGroupModal}
          adherentId={currentAdherentId}
          groups={groups} // Passer les groupes récupérés ici
          onGroupSelected={setSelectedGroupToAdd} // Passer la fonction handleAddGroup ici
        />
      )}
    </div>
  );
};

export default ListingAdherentsAdmin;
