import React, { useState, useEffect } from "react";
import {
  getSettings,
  updateSettings,
} from "../../../services/settingsServices";
import { getAllGalleries } from "../../../services/gallerieServices";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { useUser } from "../../Home/UserContext"; // Importer useUser
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import "react-quill/dist/quill.snow.css";

const HomeSettingsForm = () => {
  const [settings, setSettings] = useState({
    header_url1: "",
    header_texte1: "",
    header_url2: "",
    header_texte2: "",
    about_title: "",
    about_content: "",
    carrousel_id: "",
  });
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser(); // Récupérer l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    const fetchSettingsAndGalleries = async () => {
      try {
        const [settingsData, galleriesData] = await Promise.all([
          getSettings(),
          getAllGalleries(),
        ]);
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
        setGalleries(galleriesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setLoading(false);
    };

    fetchSettingsAndGalleries();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleContentChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success("Paramètres mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4 max-w-4xl">
      <h3 className="text-xl font-semibold mb-6">
        Paramètres de la Page d'Accueil
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Lien de la première image du header
          </label>
          <input
            type="text"
            name="header_url1"
            value={settings.header_url1}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Texte de la première image du header
          </label>
          <ReactQuill
            value={settings.header_texte1}
            onChange={(value) => handleContentChange("header_texte1", value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Lien de la deuxième image du header
          </label>
          <input
            type="text"
            name="header_url2"
            value={settings.header_url2}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Texte de la deuxième image du header
          </label>
          <ReactQuill
            value={settings.header_texte2}
            onChange={(value) => handleContentChange("header_texte2", value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Titre de la section "À propos"
          </label>
          <input
            type="text"
            name="about_title"
            value={settings.about_title}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Contenu de la section "À propos"
          </label>
          <ReactQuill
            value={settings.about_content}
            onChange={(value) => handleContentChange("about_content", value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Sélectionnez la galerie pour le carrousel
          </label>
          <select
            name="carrousel_id"
            value={settings.carrousel_id}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            {galleries.map((gallery) => (
              <option key={gallery.id} value={gallery.id}>
                {gallery.titre}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <Button
            text="Mettre à jour"
            type="submit"
            className={`btn-primary gap-2 ${
              userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""
            }`}
            hoverLabel={
              userRole === "visiteur"
                ? "Action désactivée pour les visiteurs"
                : "Mettre à jour"
            }
            disabled={userRole === "visiteur"}
          />
        </div>
      </form>
    </div>
  );
};

export default HomeSettingsForm;
