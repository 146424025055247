import React from "react";
import { FcDiploma1 } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

const ButtonDiplome = ({ adherentId, adherentNom, adherentPrenom, dateNaissance, datePassage, beltImage, beltName, beltColor }) => {
    const navigate = useNavigate();
  const handleGenerateDiploma = () => {
    navigate("/diplome-grade", {
      state: {
        adherentId,
        adherentNom,
        adherentPrenom,
        dateNaissance,
        datePassage: new Date(datePassage).toLocaleDateString(),
        beltImage,
        beltName,
      }
    });
  };

  return (
    <button
      onClick={handleGenerateDiploma}
      title="Générer le diplôme"
      className=" text-blue-400 text-3xl font-satoshi tracking-wider hover:shadow-sm"
    >
      <FcDiploma1 />
    </button>
  );
};

export default ButtonDiplome;
