// Button.js
import React from 'react';

const Button = ({ text, onClick, icon: Icon, className = '', hoverLabel = '' }) => {
  return (
    <button
      onClick={onClick}
      aria-label={hoverLabel} // Bon pour l'accessibilité
      title={hoverLabel} // Ajout de l'infobulle au survol
      className={`bg-primary text-white font-satoshi px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center ${className}`}
    >
      {Icon && <Icon className="text-xl" />}
      {text}
    </button>
  );
};

export default Button;
