import React, { useState } from 'react';
import { FaUsers, FaUserFriends, FaBuilding, FaHome, FaFileInvoice, FaBook, FaCheck, FaHandshake } from 'react-icons/fa';
import { useUser } from '../../Home/UserContext';
import { getRoleFromRoleId } from '../../../utils/roleHelper';
import DashboardNavigation from '../../DashboardNavigation/DashboardNavigation';
import useScrollToTop from '../../../hooks/useScollToTop';
import SettingsGroups from './SettingsGroups';
import SettingsForm from './SettingsForm';
import HomeSettingsForm from './HomeSettingsForm';
import PaiementSettingsForm from './PaiementSettingsForm';
import TeamManagement from './TeamTable';
import DisciplinesTable from './DisciplinesTable';
import ModuleSettingsForm from './ModuleSettingsForm';
import ReglementInterieurForm from './ReglementInterieurForm'; // Import du nouveau composant
import SettingsPartenaires from './SettingsPartenaires'; // Import du nouveau composant

const SettingsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [activeTab, setActiveTab] = useState('groupes');

  const tabClasses = (tab) =>
    `tab tab-lifted ${activeTab === tab ? 'tab-active bg-blue-500 text-white' : 'text-black'}`;

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Paramètres
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="w-full">
        <div className="tabs tabs-lifted flex-wrap justify-center mb-4 text-black">
          <button className={tabClasses('groupes')} onClick={() => setActiveTab('groupes')}>
            <FaUsers className={`mr-2 ${activeTab === 'groupes' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Groupes</span>
          </button>
          <button className={tabClasses('equipes')} onClick={() => setActiveTab('equipes')}>
            <FaUserFriends className={`mr-2 ${activeTab === 'equipes' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Equipes</span>
          </button>
          <button className={tabClasses('disciplines')} onClick={() => setActiveTab('disciplines')}>
            <FaBook className={`mr-2 ${activeTab === 'disciplines' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Disciplines</span>
          </button>
          <button className={tabClasses('association')} onClick={() => setActiveTab('association')}>
            <FaBuilding className={`mr-2 ${activeTab === 'association' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Association</span>
          </button>
          <button className={tabClasses('pageAccueil')} onClick={() => setActiveTab('pageAccueil')}>
            <FaHome className={`mr-2 ${activeTab === 'pageAccueil' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Page Accueil</span>
          </button>
          <button className={tabClasses('reglements')} onClick={() => setActiveTab('reglements')}>
            <FaFileInvoice className={`mr-2 ${activeTab === 'reglements' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Règlements</span>
          </button>
          <button className={tabClasses('reglementInterieur')} onClick={() => setActiveTab('reglementInterieur')}>
            <FaFileInvoice className={`mr-2 ${activeTab === 'reglementInterieur' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Règlement Intérieur</span>
          </button>
          <button className={tabClasses('partenaires')} onClick={() => setActiveTab('partenaires')}>
            <FaHandshake className={`mr-2 ${activeTab === 'partenaires' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Partenaires</span>
          </button>
          <button className={tabClasses('modules')} onClick={() => setActiveTab('modules')}>
            <FaCheck className={`mr-2 ${activeTab === 'modules' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Modules</span>
          </button>
        </div>

        <div className="flex flex-col w-full px-2 mb-8 gap-5 items-center text-black">
          {activeTab === 'groupes' && <SettingsGroups />}
          {activeTab === 'equipes' && <TeamManagement />}
          {activeTab === 'disciplines' && <DisciplinesTable />}
          {activeTab === 'association' && <SettingsForm />}
          {activeTab === 'pageAccueil' && <HomeSettingsForm />}
          {activeTab === 'reglements' && <PaiementSettingsForm />}
          {activeTab === 'reglementInterieur' && <ReglementInterieurForm />}
          {activeTab === 'partenaires' && <SettingsPartenaires />}
          {activeTab === 'modules' && <ModuleSettingsForm />}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
