import React, { useState, useEffect } from "react";
import { updateAdherentResults  } from "../../../services/eventServices"; // Assurez-vous d'implémenter ces fonctions
import { addPalmares, checkPalmaresExists, updatePalmares } from "../../../services/palmaresServices"; // Assurez-vous d'implémenter ces fonctions
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import { FaSave, FaTimes, FaTrophy, FaEdit } from "react-icons/fa";
import getCategory from "../../../utils/getCategory";
import getWeightCategory from "../../../utils/getWeightCategory";


const EditResultCompetitorEvent = ({ isOpen, onClose, event, adherentDetails, results }) => {
  const [formData, setFormData] = useState({
    results: results.resultats || "",
    ranking: results.classement || "",
    observations: results.observations || "",
    coach_hour: results.coach_hour || 0
  });
  const [palmaresExists, setPalmaresExists] = useState(false);
  const [palmaresId, setPalmaresId] = useState(null);

  useEffect(() => {
    const fetchPalmaresExists = async () => {
        const response = await checkPalmaresExists(adherentDetails.id, event.id);
        if (response && response.exists) {
            setPalmaresExists(true);
            setPalmaresId(response.palmaresId);  // Assurez-vous que c'est 'palmaresId' qui est utilisé ici et que c'est bien la propriété retournée par l'API
        } else {
            setPalmaresExists(false);
            setPalmaresId(null);
        }
    };

    fetchPalmaresExists();
}, [adherentDetails.id, event.id]);


  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Submit form data
  const handleSaveResults = async () => {
  try {
    const dataToSubmit = {
      adherent_id: adherentDetails.id,
      event_id: event.id,
      resultats: formData.results,
      classement: formData.ranking,
      observations: formData.observations,
      coach_hour: formData.coach_hour
    };
    
    console.log("Data to submit:", dataToSubmit); // Vérification des données soumises

    await updateAdherentResults(dataToSubmit);
    toast.success("Résultats enregistrés avec succès.");
    onClose();
  } catch (error) {
    console.error("Failed to update results:", error);
    toast.error("Erreur lors de l'enregistrement des résultats.");
  }
};

const handlePalmaresAction = async () => {
  const palmaresData = {
    adherent_palmares_id: adherentDetails.id,
    event_palmares_id: event.id, // Assurez-vous que cette ligne est correcte
    date_palmares: new Date().toISOString(),
    titre_palmares: event.titre,
    resultats_palmares: formData.results,
    classement_palmares: formData.ranking,
    observations_palmares: formData.observations,
  };

  if (palmaresExists && !palmaresId) {
    toast.error("Erreur interne. Aucun ID de palmarès valide pour la mise à jour.");
    return;
  }

  try {
    if (palmaresExists) {
      await updatePalmares(palmaresId, palmaresData);
      toast.success("Palmarès mis à jour avec succès.");
    } else {
      await addPalmares(palmaresData);
      toast.success("Ajouté au Palmarès avec succès.");
    }
  } catch (error) {
    console.error("Erreur lors de l'ajout/mise à jour du palmarès:", error);
    toast.error("Erreur lors de l'ajout/mise à jour du palmarès.");
  }
  onClose();
};



  // Fonction pour choisir l'image par défaut en fonction du genre
  const getDefaultPhoto = (genre) => {
    return genre === "Masculin"
      ? "/img/judoka_avatar.png"
      : "/img/judokate_avatar.png";
  };

  const ageCategory = adherentDetails ? getCategory(adherentDetails.date_naissance) : "";
  const weightCategory = adherentDetails ? getWeightCategory(adherentDetails.genre, ageCategory, adherentDetails.poids) : "";

  // Tags style function for results
  const addResultTag = (tag) => {
    setFormData({
      ...formData,
      results: `${formData.results}${tag} | `
    });
  };

  const removeLastTag = () => {
    const newResults = formData.results.split(' | ').slice(0, -1).join(' | ') + (formData.results.trim().endsWith('|') ? '' : ' | ');
    setFormData({
      ...formData,
      results: newResults
    });
  };

  // Ajouté au Palmarès
  const handleAddToPalmares = async () => {
    // Vérifiez que tous les champs nécessaires sont remplis
    if (!formData.results && !formData.ranking && !formData.observations) {
      toast.error("Aucun résultat à ajouter au palmarès.");
      return;
    }

    try {
      const palmaresData = {
        adherent_palmares_id: adherentDetails.id,
        date_palmares: new Date().toISOString(), // Utilisez la date actuelle ou celle de l'événement
        titre_palmares: event.titre,
        resultats_palmares: formData.results,
        classement_palmares: formData.ranking,
        observations_palmares: formData.observations,
      };

      await addPalmares(palmaresData);
      toast.success("Ajouté au Palmarès avec succès.");
      onClose(); // Fermez la modale après l'ajout
    } catch (error) {
      console.error("Erreur lors de l'ajout au palmarès:", error);
      toast.error("Erreur lors de l'ajout au palmarès.");
    }
};

   const isFormFilled = formData.results || formData.ranking || formData.observations;

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${!isOpen && "hidden"}`}>
      <div className="bg-white dark:bg-gray-600 p-4 rounded-lg shadow-lg max-w-lg w-full font-satoshi">
        <header className="flex justify-between items-center border-b pb-3 mb-4">
          <div>
            <h2 className="text-lg font-bold text-gray-700 dark:text-base-200">{event?.titre}</h2>
            <p className="text-sm italic text-gray-600 dark:text-base-200">{event?.start}</p>
          </div>
          
          <button onClick={onClose} className="text-white hover:text-white bg-primary p-4 rounded-full ">
            <FaTimes />
          </button>
        </header>
        <div className="flex items-center space-x-4">
          <img className="h-16 w-16 rounded-full object-cover" src={adherentDetails?.photo_url || getDefaultPhoto(adherentDetails?.genre)} alt="Adhérent" />
          <div>
            <h3 className="font-bold font-satoshi dark:text-base-200">{adherentDetails?.nom} {adherentDetails?.prenom}</h3>
           <p className="text-sm text-gray-500 font-satoshi dark:text-gray-200">{adherentDetails.ageCategory} | {adherentDetails.weightCategory}</p>
          </div>
        </div>
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-base-200">Résultats</label>
          <div className="flex flex-wrap gap-2 mb-2">
            <button onClick={() => addResultTag('✅')} className="rounded-full bg-green-500 text-white text-xl px-3 py-1">Gagné</button>
            <button onClick={() => addResultTag('❌')} className="rounded-full bg-red-500 text-white text-xl px-3 py-1">Perdu</button>
            <button onClick={() => addResultTag('N')} className="rounded-full bg-yellow-500 text-white text-xl px-3 py-1">Nul</button>
            <button onClick={() => addResultTag('F')} className="rounded-full bg-gray-500 text-white text-xl px-3 py-1">Forfait</button>
            <button onClick={removeLastTag} className="rounded-full bg-blue-400 text-white text-xl px-3 py-1"><span className="text-primary">⬅️</span> Effacer</button>
          </div>
          <input type="text" name="results" value={formData.results} onChange={handleInputChange} className="input input-bordered w-full mt-2 dark:bg-slate-200" />
        </div>
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-base-200 ">Classement</label>
          <input type="text" name="ranking" value={formData.ranking} onChange={handleInputChange} className="input input-bordered w-full dark:bg-slate-200" />
        </div>
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-base-200">Observations</label>
          <textarea name="observations" value={formData.observations} onChange={handleInputChange} className="textarea textarea-bordered w-full dark:bg-slate-200"></textarea>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <Button text="Sauvegarder" onClick={handleSaveResults} icon={FaSave} className="gap-1 text-sm"/>
          {isFormFilled && (
            <Button text={palmaresExists ? "Màj du Palmarès" : "Ajouter au Palmarès"} 
                    onClick={handlePalmaresAction} 
                    icon={palmaresExists ? FaEdit : FaTrophy} className="gap-1"/>
          )}
          <Button text="Annuler" onClick={() => onClose()} icon={FaTimes} className="gap-1 text-sm"/>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};


export default EditResultCompetitorEvent;
