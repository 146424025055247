import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";
import { getAllAdherentsList, getAdherentById } from "../../../services/adherentServices";
import AdherentDetails from "./AdherentDetails";
import AdherentGroupCategory from "./AdherentGroupCategory";
import AdherentManagers from "./AdherentManagers";
import AdherentPassageCeinture from "./AdherentPassageCeinture";
import AdherentPalmares from "./AdherentPalmares";
import AdherentCompetitions from "./AdherentCompetitions";
import DossierInscription from "./DossierInscription";
import ReglementInscription from "./ReglementInscription";
import AchatsAdherent from "./AchatsAdherent";
import AttendanceList from "./AttendanceList";
import ManageGroupModal from "../../PagesEnseignants/ListingAdherents/ManageGroupModal";
import EditAdherentModal from "../../PagesEnseignants/ListingAdherents/EditAdherentModal";
import "react-toastify/dist/ReactToastify.css";

const DetailsAdherent = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [adherents, setAdherents] = useState([]);
  const [selectedAdherentId, setSelectedAdherentId] = useState(null);
  const [selectedAdherent, setSelectedAdherent] = useState(null);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const data = await getAllAdherentsList();
        setAdherents(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents:", error);
        toast.error("Erreur lors de la récupération des adhérents");
      }
    };
    fetchAdherents();
  }, []);

  const handleSelectAdherent = async (e) => {
    const adherentId = e.target.value;
    if (adherentId) {
      try {
        const adherent = await getAdherentById(adherentId);
        setSelectedAdherentId(adherentId);
        setSelectedAdherent(adherent);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'adhérent:", error);
        toast.error("Erreur lors de la récupération de l'adhérent");
      }
    } else {
      setSelectedAdherentId(null);
      setSelectedAdherent(null);
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Détails Adhérents
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mb-4 max-w-[1280px] mx-auto">
  <label htmlFor="adherent-select" className="block text-sm font-semibold text-gray-800 dark:text-gray-200 mb-2">
    Sélectionnez un adhérent
  </label>
  <div className="relative">
    <select
      id="adherent-select"
      className="block w-full pl-4 pr-10 py-3 text-base border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150 bg-white dark:bg-slate-700 dark:border-slate-600 dark:text-white"
      onChange={handleSelectAdherent}
    >
      <option value="">-- Choisissez un adhérent --</option>
      {adherents.map((adherent) => (
        <option key={adherent.id} value={adherent.id}>
          {adherent.nom} {adherent.prenom}
        </option>
      ))}
    </select>
    {/* Icône de sélection */}
    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
      {/* <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
      </svg> */}
    </div>
  </div>
</div>


      {selectedAdherent && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-5xl mx-auto">
          <div className="col-span-3">
            <AdherentDetails adherent={selectedAdherent} />
          </div>
          <div className="col-span-3">
            <AdherentGroupCategory adherentId={selectedAdherentId} />
          </div>
          <div className="col-span-3">
            <AdherentManagers adherentId={selectedAdherentId} />
          </div>
          
          <div className="col-span-3">
            <AdherentPassageCeinture adherentId={selectedAdherentId} />
          </div>
          <div className="col-span-3">
            <AdherentCompetitions adherentId={selectedAdherentId} />
          </div>
          <div className="col-span-3">
            <AdherentPalmares adherentId={selectedAdherentId} />
          </div>
          
          <div className="col-span-3">
            <DossierInscription adherentId={selectedAdherentId} />
          </div>
          <div className="col-span-3">
            <ReglementInscription adherentId={selectedAdherentId} saison={selectedAdherent.saison_active} />
          </div>
          <div className="col-span-3">
            <AchatsAdherent adherentId={selectedAdherentId} />
          </div>
          <div className="col-span-3">
            <AttendanceList adherentId={selectedAdherentId} />
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default DetailsAdherent;
