import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllGalleries } from "../../services/gallerieServices"; // Assurez-vous que le chemin est correct
import Button from "../../components/Button/Button";
import { useUser } from "../Home/UserContext";

const LastGallerie = () => {
  const [galleries, setGalleries] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState(null); // État pour gérer la galerie sélectionnée
  const [selectedImage, setSelectedImage] = useState(null); // État pour gérer l'image sélectionnée pour l'agrandissement
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const allGalleries = await getAllGalleries(); // Fonction API pour obtenir les galeries
        const activeGalleries = allGalleries.filter((gallery) => gallery.is_active); // Filtrer les galeries actives
        setGalleries(activeGalleries.slice(0, 3)); // Gardez seulement les trois premières galeries actives
      } catch (error) {
        console.error("Failed to fetch galleries", error);
      }
    };

    fetchGalleries();
  }, []);

  const handleReadMore = (galleryId) => {
    navigate(`/galerie-photos/album/${galleryId}`);
  };

  // Fonction pour ouvrir la modale de galerie avec les photos
  const openGalleryModal = (gallery) => {
    setSelectedGallery(gallery);
  };

  // Fonction pour fermer la modale
  const closeGalleryModal = () => {
    setSelectedGallery(null);
    setSelectedImage(null);
  };

  // Fonction pour ouvrir l'image sélectionnée
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <section className="py-12 bg-lightBackground dark:bg-darkBackground text-black dark:text-white">
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold mb-4 text-primary mx-auto text-center font-satoshi uppercase">
        Derniers Albums Photos
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        {galleries.map((gallery) => (
          <div
            key={gallery.id}
            className="flex flex-col bg-slate-100 dark:bg-slate-500 shadow-md rounded-lg p-4 justify-between h-full"
          >
            <div
              className="cursor-pointer"
              onClick={() => openGalleryModal(gallery)} // Ouvre la modale au clic
            >
              {/* Vérifier si gallery.photos existe et qu'il a au moins une photo, sinon montrer une image par défaut */}
              <img
                src={
                  gallery.photos && gallery.photos.length > 0
                    ? gallery.photos[0].photo_url
                    : "/img/no-image.jpg"
                }
                alt={gallery.titre}
                className="w-full h-64 object-cover rounded-t-lg"
              />
              <h3 className="text-xl font-bold mb-2 font-satoshi mt-4">{gallery.titre}</h3>
              <p className="text-sm text-gray-600 dark:text-slate-300 mb-4 font-satoshi">
                {gallery.description}
              </p>
            </div>
            {user && (
              <Button
                text="Voir Album"
                onClick={() => handleReadMore(gallery.id)}
                className="self-center mt-auto"
              />
            )}
          </div>
        ))}
      </div>

      {/* Modale pour afficher toutes les photos d'une galerie */}
      {selectedGallery && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeGalleryModal}
        >
          <div className="relative bg-white dark:bg-gray-800 p-4 rounded-lg max-w-4xl w-full">
            <button
              className="absolute top-2 right-2 text-black dark:text-white text-2xl"
              onClick={closeGalleryModal}
              aria-label="Fermer"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold text-center mb-4 dark:text-white">
              {selectedGallery.titre}
            </h2>
            <div className="grid grid-cols-3 gap-2">
              {selectedGallery.photos.map((photo) => (
                <img
                  key={photo.id}
                  src={photo.photo_url}
                  alt={photo.titre}
                  className="w-full h-32 object-cover rounded-lg cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation(); // Empêche la fermeture de la modale quand on clique sur l'image
                    openImageModal(photo.photo_url);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Modale pour afficher l'image sélectionnée en grand */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Agrandissement"
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={() => setSelectedImage(null)}
              aria-label="Fermer"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
    </section>
  );
};

export default LastGallerie;
