import React, { useState, useEffect } from "react";
import { getPalmaresByAdherentId } from "./../../../services/palmaresServices";

const AdherentPalmares = ({ adherentId }) => {
  const [palmaress, setPalmaress] = useState([]);

  useEffect(() => {
    const getPalmaresByAdherent = async () => {
      try {
        const data = await getPalmaresByAdherentId(adherentId);
        if (Array.isArray(data)) {
          setPalmaress(data);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès:", error);
      }
    };

    getPalmaresByAdherent();
  }, [adherentId]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-4 shadow-lg max-w-[800px] mx-auto font-satoshi">
      <div className="bg-gray-300 dark:bg-gray-600 p-4 rounded">
        <h3 className="text-2xl font-light uppercase mb-4 text-left">Palmarès</h3>

        {palmaress.length > 0 ? (
          <div className="space-y-4">
            {palmaress.map((palmares, index) => (
              <div key={index} className="bg-white dark:bg-slate-800 rounded-lg shadow-lg p-4">
                <h4 className="text-lg font-semibold text-gray-800 dark:text-white">
                  {palmares.titre_palmares || "Inconnu"}
                </h4>
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                  Date : {new Date(palmares.date_palmares).toLocaleDateString()}
                </p>
                {palmares.resultats_palmares && (
                  <p className="text-sm text-gray-800 dark:text-gray-300">
                    <strong className="dark:text-gray-300 ">Résultats :</strong> {palmares.resultats_palmares}
                  </p>
                )}
                {palmares.classement_palmares && (
                  <p className="text-sm text-gray-800 dark:text-gray-300">
                    <strong className="dark:text-gray-300 ">Classement :</strong> {palmares.classement_palmares}
                  </p>
                )}
                {palmares.observations_palmares && (
                  <p className="text-sm text-gray-800 dark:text-gray-300">
                    <strong className="dark:text-gray-300 ">Observations :</strong> {palmares.observations_palmares}
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-800 dark:text-gray-200 py-4">
            Aucun palmarès disponible.
          </p>
        )}
      </div>
    </div>
  );
};

export default AdherentPalmares;
