import React, { useState, useEffect } from "react";
import Button from "./../../../components/Button/Button"; // Assurez-vous du chemin
import { useNavigate, useLocation } from "react-router-dom";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { getGroupsHome } from "./../../../services/groupServices";
import { getBeltsList } from "./../../../services/beltServices";
import {
  addNewAdherent,
  getAllAdherentsList,
} from "./../../../services/adherentServices";
import { assignAdherentToUser } from "./../../../services/userServices";
import { createNewDossierInscription } from "./../../../services/dossierServices";
import {
  sendNotification,
  sendNotificationToAdmin,
} from "./../../../services/notificationServices";
import { getSettings } from "./../../../services/settingsServices";
import { getGroupsList } from "./../../../services/groupServices";
import { useUser } from "../../Home/UserContext";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment"; // Assurez-vous d'avoir installé moment
import InfoBubble from "./../../../components/InfoBubble/InfoBubble";

// Fonction pour formater le prénom
const formatPrenom = (prenom) => {
  return prenom
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join("-");
};

const InscriptionPage = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const { user } = useUser();
  const location = useLocation();
  const groupeIdFromState = location.state?.groupeId; // Accès à l'ID du groupe passé via le state de navigation
  const [belts, setBelts] = useState([]);
  const [saisonActive, setSaisonActive] = useState("");
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    genre: "",
    date_naissance: "",
    poids: "",
    adresse: "",
    cp: "",
    commune: "",
    tel: "",
    email: "",
    groupeId: "",
    statut_inscription: "",
    autorisation_photo: true,
    belt_id: "",
    date_passage: "",
    notes_adherent: "",
    saison_active: "",
  });

  const [userInfo, setUserInfo] = useState({
    nom: "",
    prenom: "",
    // Vous pouvez ajouter d'autres attributs au besoin
  });

  const [groupes, setGroupes] = useState([]); // Pour stocker les groupes récupérés de l'API
  const [selectedGroup, setSelectedGroup] = useState(null); // Le groupe sélectionné
  const [dateError, setDateError] = useState("");
  const [groupError, setGroupError] = useState("");
  const [adherentsList, setAdherentsList] = useState([]);
  const [duplicateWarning, setDuplicateWarning] = useState("");

  useEffect(() => {
    const fetchBelts = async () => {
      const beltsData = await getBeltsList();
      setBelts(beltsData);
    };
    fetchBelts();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        setSaisonActive(settingsData[0].saison_active); // Mise à jour pour utiliser les données de settings
        setFormData((prev) => ({
          ...prev,
          saison_active: settingsData[0].saison_active,
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres:", error);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    // Tentez de récupérer les données utilisateur de localStorage
    const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (storedUserInfo) {
      setUserInfo({
        nom: storedUserInfo.nom || "",
        prenom: storedUserInfo.prenom || "",
        userId: storedUserInfo.userId || "",
        // Assurez-vous d'ajouter ici d'autres attributs si nécessaire
      });
    }
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getGroupsHome(); // Utilisez la fonction importée
        setGroupes(response);
        if (groupeIdFromState) {
          // Logique pour gérer la sélection du groupe directement basé sur groupeIdFromState
          const selected = response.find(
            (groupe) => groupe.id.toString() === groupeIdFromState.toString()
          );
          setSelectedGroup(selected);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des groupes:", error);
      }
    };

    fetchGroups();
  }, [groupeIdFromState]);

  useEffect(() => {
    const fetchAdherentsList = async () => {
      try {
        const adherents = await getAllAdherentsList();
        setAdherentsList(adherents);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la liste des adhérents:",
          error
        );
      }
    };

    fetchAdherentsList();
  }, []);

  // Utilisez `getGroupsList` pour récupérer les groupes avec le nombre d'adhérents
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getGroupsList(); // Utilisez la fonction importée
        const activeGroups = response.filter((group) => group.is_active); // Filtrer les groupes actifs
        setGroupes(activeGroups);

        if (groupeIdFromState) {
          // Logique pour gérer la sélection du groupe directement basé sur groupeIdFromState
          const selected = activeGroups.find(
            (groupe) => groupe.id.toString() === groupeIdFromState.toString()
          );
          setSelectedGroup(selected);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des groupes:", error);
      }
    };

    fetchGroups();
  }, [groupeIdFromState]);

  const checkForDuplicate = (nom, prenom) => {
    const duplicate = adherentsList.find(
      (adherent) => adherent.nom === nom && adherent.prenom === prenom
    );
    if (duplicate) {
      setDuplicateWarning(
        `Un adhérent avec le nom ${nom} et le prénom ${prenom} existe déjà.`
      );
    } else {
      setDuplicateWarning("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "tel") {
      // Retirer tous les espaces et autres caractères non-numériques
      formattedValue = value.replace(/\D/g, "");
    } else if (name === "nom") {
      // Autoriser les lettres, espaces et tirets
      formattedValue = value.toUpperCase().replace(/[^A-Z\s-]/g, "");
    } else if (name === "prenom") {
      // Autoriser les lettres (avec accents), espaces et tirets, supprimer les autres caractères
      formattedValue = formatPrenom(value.replace(/[^a-zA-ZÀ-ÿ\s-]/g, ""));
    } else if (name === "poids") {
      // Retirer tout ce qui n'est pas un chiffre ou un point (pour les décimales)
      formattedValue = value.replace(/[^0-9.]/g, "");
    } else if (name === "commune") {
      // Convertir en majuscules toute la chaîne
      formattedValue = value.toUpperCase();
    }

    setFormData((prev) => ({ ...prev, [name]: formattedValue }));

    if (name === "nom" || name === "prenom") {
      checkForDuplicate(
        name === "nom" ? formattedValue : formData.nom,
        name === "prenom" ? formattedValue : formData.prenom
      );
    }
  };

  const handleChangeGroup = (e) => {
    const groupeId = e.target.value;
    const groupe = groupes.find((groupe) => groupe.id.toString() === groupeId);
    if (groupe) {
      let updatedGroup = { ...groupe };

      // Convertir annees_naissance en tableau si ce n'est pas déjà un tableau
      if (typeof updatedGroup.annees_naissance === "string") {
        if (updatedGroup.annees_naissance.includes("à partir de 14 ans")) {
          updatedGroup.annees_naissance = ["à partir de 14 ans"];
        } else {
          updatedGroup.annees_naissance = updatedGroup.annees_naissance
            .split(/[-, ]+/)
            .map((item) => item.trim());
        }
      }

      setSelectedGroup(updatedGroup);

      // Vérifiez si le quota est atteint
      if (updatedGroup.nombreAdherents >= updatedGroup.quota_groupe) {
        setGroupError(
          `Le groupe ${updatedGroup.nom} a atteint son quota maximum de ${updatedGroup.quota_groupe} adhérents. Veuillez sélectionner un autre groupe ou contacter le responsable du club.`
        );
      } else {
        setGroupError("");
      }
    }
  };

  const validerEtFormatterDate = (dateStr) => {
    // Retirer tous les caractères non-numériques sauf '/'
    const cleaned = dateStr.replace(/[^0-9/]/g, "");
    // Essayer de construire une date avec les différents formats possibles
    const formats = ["DD/MM/YY", "DDMMYY", "DDMMYYYY", "DD/MM/YYYY"];
    for (let format of formats) {
      const momentDate = moment(cleaned, format, true);
      if (momentDate.isValid()) {
        return momentDate.format("YYYY-MM-DD"); // Format standard pour l'enregistrement
      }
    }
    return null; // Retourner null si aucun format n'est valide
  };

  const handleDateChange = (e) => {
    let { value } = e.target;
    let formattedValue = value.replace(/[^\d/]/g, ""); // Supprime tout caractère qui n'est ni un chiffre ni '/'

    // Ajoute automatiquement '/' après le jour et le mois si nécessaire
    if (formattedValue.length === 2 || formattedValue.length === 5) {
      if (formattedValue.slice(-1) !== "/") {
        formattedValue += "/";
      }
    }

    // Limite la longueur maximale pour éviter une saisie excessive
    if (formattedValue.length > 10) {
      formattedValue = formattedValue.slice(0, 10);
    }

    setFormData((prev) => ({ ...prev, date_naissance: formattedValue }));

    // Validation de la date
    if (formattedValue.length === 10) {
      const momentDate = moment(formattedValue, "DD/MM/YYYY", true);
      const isValidDate = momentDate.isValid();
      const isFutureDate = momentDate.isAfter(moment());

      if (!isValidDate || isFutureDate) {
        setDateError(
          "La date n'est pas valide ou est une date future. Veuillez entrer une date au format JJ/MM/AAAA."
        );
        setGroupError(""); // Réinitialiser l'erreur de groupe si la date est invalide
        return; // Sortir de la fonction si la date n'est pas valide
      } else {
        setDateError("");
        const birthYear = momentDate.year();

        if (
          selectedGroup &&
          !selectedGroup.annees_naissance.includes(birthYear.toString())
        ) {
          if (!selectedGroup.annees_naissance.includes("à partir de 14 ans")) {
            setGroupError(
              `L'année de naissance ne correspond pas au groupe sélectionné.`
            );
          } else {
            setGroupError("");
          }
        } else {
          setGroupError(""); // Réinitialiser l'erreur de groupe si la date est correcte
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si les champs obligatoires sont remplis
    if (!formData.date_naissance || dateError) {
      toast.error(
        "Veuillez corriger les erreurs avant de soumettre le formulaire."
      );
      return;
    }

    if (!user || !user.userId) {
      toast.error("Vous devez être connecté pour effectuer cette action.");
      return; // Arrêter l'exécution si nous n'avons pas de données utilisateur valides
    }

    // Mise à jour de photo_url en fonction de l'état de autorisation_photo
    const photo_url = formData.autorisation_photo
      ? ""
      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714598563/images/adherents/panneau-interdiction-de-filmer-ou-de-photographier_shhqq4.jpg";

    // Inclusion des données de ceinture dans les données à envoyer
    const adherentData = {
      ...formData,
      date_naissance: validerEtFormatterDate(formData.date_naissance),
      groupes: selectedGroup ? [selectedGroup.id] : [], // Assurez-vous que c'est un tableau d'IDs
      photo_url,
      belt_id: formData.belt_id,
      date_passage: formData.date_passage,
      saison_active: saisonActive,
    };

    try {
      const addResponse = await addNewAdherent(adherentData);

      // Vérifier que addResponse contient un `id` avant de continuer
      if (addResponse && addResponse.id) {
        const associationData = {
          userId: user.userId,
          adherentId: addResponse.id,
        };
        const assocResponse = await assignAdherentToUser(
          user.userId,
          addResponse.id,
          user.token
        );

        // Création du dossier d'inscription
        const dossierResponse = await createNewDossierInscription(
          addResponse.id
        );
        toast.success("Adhérent ajouté avec succès.");
        // Préparation de la notification
        // Send notification to user
        const notificationUser = `Félicitations, vous venez d'inscrire ${
          formData.prenom
        } ${formData.nom} sur le groupe ${
          selectedGroup ? selectedGroup.nom : "sélectionné"
        }.`;
        sendNotification(notificationUser, user.userId, user.token);

        // Send notification to admin
        const notificationAdmin = `${userInfo.prenom} ${
          userInfo.nom
        } vient d'inscrire ${formData.prenom} ${formData.nom} sur le groupe ${
          selectedGroup ? selectedGroup.nom : "sélectionné"
        }.`;
        sendNotificationToAdmin(notificationAdmin, user.token);

        setFormData({
          // Clear the form
          nom: "",
          prenom: "",
          genre: "",
          date_naissance: "",
          poids: "",
          adresse: "",
          cp: "",
          commune: "",
          tel: "",
          email: "",
          groupeId: "",
          statut_inscription: "",
          belt_id: "",
          date_passage: "",
          notes_adherent: "",
          saison_active: saisonActive,
        });
        navigate("/dossier-d-inscription");
      } else {
        toast.error(
          "Erreur lors de l'ajout de l'adhérent. Veuillez réessayer."
        );
      }
    } catch (error) {
      toast.error("Erreur lors de l'ajout de l'adhérent. Veuillez réessayer.");
    }
  };

  // Calculer le tarif total
  const calculerTarifTotal = () => {
    if (selectedGroup) {
      return selectedGroup.tarif_licence + selectedGroup.tarif_cotisation;
    }
    return 0; // Retourner 0 si aucun groupe n'est sélectionné
  };

  return (
    <div className="container mx-auto pt-[150px] px-4 mb-10 font-satoshi">
        <InfoBubble title="Mode Démonstration">
        <p>
          ✅ L'inscription d'un adhérent est possible si vous êtes connecté en tant qu'utilisateur.
        </p>
      </InfoBubble>
      {userInfo.nom && userInfo.prenom && (
        <h2 className="text-normal italic font-normal mb-2">
          Bienvenue {userInfo.prenom} {userInfo.nom} sur la page d'inscription
          des adhérents
        </h2>
      )}
      <p className="text-normal italic font-normal mb-2 text-gray-400">
        Pour un renouvellement d'adhésion, merci de le faire sur la page
        "Dossier d'Inscription"
      </p>
      <h2 className="text-2xl uppercase font-bold mb-4">
        Formulaire d'Inscription pour la saison {saisonActive}
      </h2>
      {/* Image en en-tête */}
      <div className="text-center mb-4">
        <img src="/img/inscription.png" alt="Inscription" className="mx-auto" />
      </div>
      <div className="text-red-500 text-sm mb-4">* : champs obligatoires</div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Sélecteur de groupe avec card pour les informations */}
        <div className="flex flex-col md:flex-row md:items-start md:space-x-4 mb-4">
          <div className="md:w-1/2">
            <label className="label block mb-2 dark:text-white">
              Groupe<span className="text-red-500 ml-1">*</span>
            </label>
            <select
              name="groupeId"
              onChange={handleChangeGroup}
              required
              className="input w-full px-2 py-1 border rounded"
            >
              <option value="">Sélectionnez un groupe</option>
              {groupes.map((groupe) => (
                <option key={groupe.id} value={groupe.id}>
                  {groupe.nom}
                </option>
              ))}
            </select>
          </div>
          {selectedGroup && (
            <div className="mt-4 md:mt-0 md:w-1/2 bg-slate-200 shadow-md rounded-lg overflow-hidden">
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-4 bg-gradient-to-r from-slate-400 to-slate-200 text-gray-800 p-2 rounded">
                  {selectedGroup.nom}
                </h3>
                <p>
                  <strong>Horaire 1 :</strong> {selectedGroup.horaire1}
                </p>
                {selectedGroup.horaire2 && (
                  <p>
                    <strong>Horaire 2 :</strong> {selectedGroup.horaire2}
                  </p>
                )}
                <p>
                  <strong>Enseignant(s) :</strong> {selectedGroup.enseignant1}{" "}
                  {selectedGroup.enseignant2 &&
                    `, ${selectedGroup.enseignant2}`}
                </p>
                <p>
                  <strong>Années de naissance :</strong>{" "}
                  {selectedGroup.annees_naissance.join(", ")}
                </p>
                <p>
                  <strong>Tarif licence :</strong> {selectedGroup.tarif_licence}
                  €
                </p>
                <p>
                  <strong>Tarif cotisation :</strong>{" "}
                  {selectedGroup.tarif_cotisation}€
                </p>
                <p>
                  <strong>Tarif total annuel :</strong> {calculerTarifTotal()}€
                </p>
                <p>
                  <strong>Quota groupe :</strong> {selectedGroup.quota_groupe}{" "}
                  adhérents
                </p>
                <p>
                  <strong>Nombre d'adhérents inscrits :</strong>{" "}
                  {selectedGroup.nombreAdherents
                    ? selectedGroup.nombreAdherents
                    : 0}{" "}
                  adhérents
                </p>
              </div>
            </div>
          )}
        </div>

        {groupError && (
          <div className="text-red-500 text-sm mb-4">{groupError}</div>
        )}

        <div className="field">
          <label className="label dark:text-white">
            Saison<span className="text-red-500 ml-1">*</span>
          </label>
          <select
            name="saison_active"
            value={formData.saison_active}
            onChange={handleChange}
            required
            className="input w-full px-2 py-1 border rounded"
          >
            {saisonActive && (
              <option value={saisonActive}>{saisonActive}</option>
            )}
          </select>
        </div>

        <div className="mt-4">
          <p className="label mb-2 dark:text-white">
            Statut d'inscription<span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex items-center space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="statut_inscription"
                value="Cours d'essai"
                checked={formData.statut_inscription === "Cours d'essai"}
                onChange={handleChange}
                className="radio radio-primary mr-2"
              />
              Cours d'Essai
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="statut_inscription"
                value="Inscrit"
                checked={formData.statut_inscription === "Inscrit"}
                onChange={handleChange}
                className="radio radio-primary mr-2"
              />
              Inscription
            </label>
          </div>
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="flex-1 md:flex-[3]">
            <label className="label block mb-2 dark:text-white">
              Nom<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-full px-4 py-2 border rounded"
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex-1 md:flex-[3]">
            <label className="label block mb-2 dark:text-white">
              Prénom<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-full px-4 py-2 border rounded"
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div>
          {duplicateWarning && (
            <div className="text-red-500 text-sm mb-4">{duplicateWarning}</div>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="field flex-1">
            <label className="label dark:text-white">
              Genre<span className="text-red-500 ml-1">*</span>
            </label>
            <select
              className="input w-full px-2 py-1 border rounded"
              name="genre"
              value={formData.genre}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez le genre</option>
              <option value="Masculin">Masculin</option>
              <option value="Féminin">Féminin</option>
            </select>
          </div>
          <div>
            <label className="label dark:text-white">
              Date de Naissance<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              name="date_naissance"
              placeholder="JJ/MM/AAAA"
              value={formData.date_naissance}
              onChange={handleDateChange}
              className="input w-full px-2 py-1 border rounded"
            />
            {dateError && (
              <p className="text-red-500 text-xs italic">{dateError}</p>
            )}
            {groupError && (
              <p className="text-red-500 text-xs italic">{groupError}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label dark:text-white">
            Poids (en kg, facultatif)
          </label>
          <input
            className="input w-1/2 px-2 py-1 border rounded"
            type="number"
            name="poids"
            placeholder="Poids de l'adhérent en kg (ex: 70.5)"
            value={formData.poids}
            onChange={handleChange}
            min="1"
            step="0.1"
          />
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="field flex-1">
            <label className="label dark:text-white">
              Adresse<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input 1/3 px-2 py-1 border rounded"
              type="text"
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
              required
            />
          </div>
          <div className="field flex-1">
            <label className="label dark:text-white">
              Code Postal<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-1/3 px-2 py-1 border rounded"
              type="text"
              pattern="\d{5}"
              name="cp"
              value={formData.cp}
              onChange={handleChange}
              required
            />
          </div>
          <div className="field">
            <label className="label dark:text-white">
              Commune<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-1/3 px-2 py-1 border rounded"
              type="text"
              name="commune"
              value={formData.commune}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="field flex-1">
            <label className="label dark:text-white">
              Téléphone<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-full px-2 py-1 border rounded"
              type="tel"
              pattern="^\+?\d{0,15}"
              name="tel"
              value={formData.tel}
              onChange={handleChange}
              required
            />
          </div>
          <div className="field flex-1">
            <label className="label dark:text-white">
              Email<span className="text-red-500 ml-1">*</span>
            </label>
            <input
              className="input w-full px-2 py-1 border rounded"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        <div className="field">
          <label className="label dark:text-white">N° de Licence</label>
          <p className="italic text-sm">Laissez vide pour débutants.</p>
          <input
            className="input w-full px-2 py-1 border rounded"
            type="text"
            name="licence_number"
            value={formData.licence_number}
            onChange={handleChange}
          />
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        <div>
          <label className="label dark:text-white">Ceinture Actuelle</label>
          <p className="italic text-sm">
            Mettre Ceinture Blanche pour débutants
          </p>
          <select
            value={formData.belt_id}
            onChange={(e) =>
              setFormData({ ...formData, belt_id: e.target.value })
            }
            className="input w-full px-2 py-1 border rounded mb-4"
          >
            {belts.map((belt) => (
              <option key={belt.id} value={belt.id}>
                {belt.nom}
              </option>
            ))}
          </select>
          <p className="italic text-sm">
            Date de passage de grade, pour débutants mettez la date du premier
            cours.
          </p>
          <input
            type="date"
            value={formData.date_passage}
            onChange={(e) =>
              setFormData({ ...formData, date_passage: e.target.value })
            }
            className="input w-full px-2 py-1 border rounded"
          />
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        <div>
          <label className="label dark:text-white">Notes Personnelles</label>
          <p className="italic text-sm">
            Remarques, allergies, troubles particuliers ...
          </p>
          <textarea
            value={formData.notes_adherent}
            onChange={(e) =>
              setFormData({ ...formData, notes_adherent: e.target.value })
            }
            className="input w-full px-2 py-1 border rounded"
          />
        </div>

        <div className="my-4 border-t border-gray-300"></div>

        {/* Checkbox pour le droit à l'image */}
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.autorisation_photo}
              onChange={() =>
                setFormData({
                  ...formData,
                  autorisation_photo: !formData.autorisation_photo,
                })
              }
              className="mr-2"
            />
            Droit à l'image : En cochant cette case, j'autorise l'association à
            diffuser les représentations photographiques sur lesquelles pourrait
            apparaître mon enfant, prises à l'occasion de manifestations
            organisées par le club, sur tous supports numériques et site
            internet pour la durée la plus longue prévue par la loi.
          </label>
          {!formData.autorisation_photo && (
            <p className="text-red-500 ml-5">
              Vous ne souhaitez pas que votre enfant soit photographié.
            </p>
          )}
        </div>

        {/* Chckbox pour avoir lu le règlement intérieur, coche obligatoire pour valider le formulaire */}
        <div className="mb-4">
          <label className="flex items-center">
            <input type="checkbox" required className="mr-2" />
            <span>J'ai lu et j'accepte le &nbsp;</span>
            <a
              href="/reglement-interieur"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              règlement intérieur
            </a>
            <span>&nbsp; du club.</span>
            <span className="text-red-500 ml-1">*</span>
          </label>
        </div>

        <Button
          text="Valider l'inscription"
          className="mx-auto mb-4"
          disabled={groupError} // Désactivez le bouton si groupError est défini
        />
      </form>
      <ToastContainer />
    </div>
  );
};

export default InscriptionPage;
