import React, { useState, useEffect } from "react";
import {
  getAllDisciplines,
  addDiscipline,
  updateDiscipline,
  deleteDiscipline,
  updateOrderDiscipline,
  updateDisciplinePhoto
} from "../../../services/disciplinesServices";
import { getAllDisciplinegenerales } from "../../../services/disciplinegeneraleSettings";
import { getSettings, updateSettings } from "../../../services/settingsServices";
import Button from "../../../components/Button/Button";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import { FaSave, FaTimes, FaPlus, FaFutbol, FaFootballBall, FaTableTennis, FaBicycle, FaRunning, FaSwimmer, FaBasketballBall, FaVolleyballBall, FaGolfBall, FaHockeyPuck, FaMountain, FaSkiing } from "react-icons/fa";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import CreateDisciplineModal from "./CreateDisciplineModal";
import EditDisciplineModal from "./EditDisciplineModal";
import { GiKimono, GiFencer, GiTennisRacket, GiHorseHead, GiHighKick, GiBoxingGlove } from 'react-icons/gi';
import { MdOutlineSportsHandball, MdRowing, MdOutlineSportsMartialArts, MdSportsKabaddi } from "react-icons/md";
import { useUser } from "../../Home/UserContext"; // Importer useUser
import { getRoleFromRoleId } from "../../../utils/roleHelper";

const icons = {
  FaFutbol: FaFutbol,
  FaFootballBall: FaFootballBall,
  FaTableTennis: FaTableTennis,
  FaBicycle: FaBicycle,
  FaRunning: FaRunning,
  FaSwimmer: FaSwimmer,
  FaBasketballBall: FaBasketballBall,
  MdOutlineSportsHandball: MdOutlineSportsHandball,
  GiKimono: GiKimono,
  GiFencer: GiFencer,
  GiTennisRacket: GiTennisRacket,
  GiBoxingGlove: GiBoxingGlove,
  FaVolleyballBall: FaVolleyballBall,
  GiHorseHead: GiHorseHead,
  FaGolfBall: FaGolfBall,
  FaHockeyPuck: FaHockeyPuck,
  GiHighKick: GiHighKick,
  FaMountain: FaMountain,
  MdRowing: MdRowing,
  FaSkiing: FaSkiing,
  MdOutlineSportsMartialArts : MdOutlineSportsMartialArts,
  MdSportsKabaddi: MdSportsKabaddi,
};

const DisciplinesTable = () => {
  const [disciplines, setDisciplines] = useState([]);
  const [allDisciplines, setAllDisciplines] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [selectedDisciplineIcon, setSelectedDisciplineIcon] = useState("");
  const [selectedDisciplineIllustration, setSelectedDisciplineIllustration] = useState("");
  const [selectedDisciplineLexiqueDatabase, setSelectedDisciplineLexiqueDatabase] = useState("");
  const [selectedDisciplineTechniquesDatabase, setSelectedDisciplineTechniquesDatabase] = useState("");
  const [currentDiscipline, setCurrentDiscipline] = useState({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDisciplineData, setSelectedDisciplineData] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingDiscipline, setUploadingDiscipline] = useState(null);

  const { user } = useUser(); // Récupérer l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    fetchDisciplines();
    fetchAllDisciplines();
    fetchSettings();
  }, []);

  const fetchDisciplines = async () => {
    try {
      const data = await getAllDisciplines();
      setDisciplines(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des disciplines", error);
    }
  };

  const fetchAllDisciplines = async () => {
    try {
      const data = await getAllDisciplinegenerales();
      setAllDisciplines(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des disciplines", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings.length > 0) {
        const current = settings[0];
        setCurrentDiscipline(current);
        setSelectedDiscipline(current.discipline_nom);
        setSelectedDisciplineIcon(current.discipline_icone);
        setSelectedDisciplineIllustration(current.discipline_illustration);
        setSelectedDisciplineLexiqueDatabase(current.discipline_lexique_database);
        setSelectedDisciplineTechniquesDatabase(current.discipline_techniques_database);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres", error);
    }
  };

  const handleDeleteDiscipline = async (discipline) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${discipline.nom_discipline} ?`)) {
      try {
        await deleteDiscipline(discipline.id);
        toast.success("Discipline supprimée avec succès");
        fetchDisciplines();
      } catch (error) {
        toast.error("Erreur lors de la suppression d’une discipline");
        console.error("Erreur lors de la suppression d’une discipline", error);
      }
    }
  };

  const openEditModal = (discipline) => {
    setSelectedDisciplineData(discipline);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedDisciplineData(null);
    setIsEditModalOpen(false);
  };

  const openUploadModal = (discipline) => {
    setUploadingDiscipline(discipline);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadingDiscipline(null);
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  const moveDisciplineUp = async (index) => {
    if (index === 0) return;
    const newDisciplines = [...disciplines];
    const temp = newDisciplines[index - 1];
    newDisciplines[index - 1] = newDisciplines[index];
    newDisciplines[index] = temp;
    newDisciplines[index - 1].ordre_discipline--;
    newDisciplines[index].ordre_discipline++;
    setDisciplines(newDisciplines);
    await updateOrderDiscipline(newDisciplines);
    fetchDisciplines();
  };

  const moveDisciplineDown = async (index) => {
    if (index === disciplines.length - 1) return;
    const newDisciplines = [...disciplines];
    const temp = newDisciplines[index + 1];
    newDisciplines[index + 1] = newDisciplines[index];
    newDisciplines[index] = temp;
    newDisciplines[index + 1].ordre_discipline++;
    newDisciplines[index].ordre_discipline--;
    setDisciplines(newDisciplines);
    await updateOrderDiscipline(newDisciplines);
    fetchDisciplines();
  };

  const handleSelectDiscipline = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = allDisciplines.find(d => d.discipline_icone === selectedValue);
    setSelectedDiscipline(selectedOption.nom);
    setSelectedDisciplineIcon(selectedOption.discipline_icone);
    setSelectedDisciplineIllustration(selectedOption.discipline_illustration);
    setSelectedDisciplineLexiqueDatabase(selectedOption.discipline_lexique_database);
    setSelectedDisciplineTechniquesDatabase(selectedOption.discipline_techniques_database);
  };

  const handleSaveDiscipline = async () => {
    const selectedOption = allDisciplines.find(d => d.discipline_icone === selectedDisciplineIcon);
    try {
      await updateSettings({
        discipline_nom: selectedDiscipline,
        discipline_icone: selectedDisciplineIcon,
        discipline_illustration: selectedDisciplineIllustration,
        discipline_lexique_database: selectedDisciplineLexiqueDatabase,
        discipline_techniques_database: selectedDisciplineTechniquesDatabase,
        display_lexique: selectedOption.discipline_lexique_display,
        display_techniques: selectedOption.discipline_techniques_display,
        display_arbitrage: selectedOption.discipline_arbitrage_display
      });
      toast.success("Discipline générale mise à jour avec succès");
      fetchSettings();
      window.location.reload();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de la discipline générale");
      console.error("Erreur lors de la mise à jour de la discipline générale", error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateDisciplinePhoto(uploadingDiscipline.id, selectedFile, token);
      setDisciplines((prevDisciplines) =>
        prevDisciplines.map((discipline) =>
          discipline.id === uploadingDiscipline.id ? { ...discipline, image_discipline: imageUrl } : discipline
        )
      );
      toast.success("Photo mise à jour avec succès!");
      closeUploadModal();
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-left dark:text-black">
          Discipline Principale
        </h2>
        <div className="flex flex-col items-center mb-4">
          <div className="flex items-center justify-between w-full mb-4">
            <div className="text-lg font-semibold">
              Votre discipline principale est le : {currentDiscipline.discipline_nom}
            </div>
          </div>
          {currentDiscipline.discipline_illustration && (
            <div className="flex justify-center mb-4">
              <img
                src={currentDiscipline.discipline_illustration}
                alt={currentDiscipline.discipline_nom}
                className="max-w-[400px] object-contain rounded-lg shadow-lg dark:shadow-none"
              />
            </div>
          )}
          <label htmlFor="discipline-select" className="mb-2 text-lg font-semibold">Choisissez la discipline principale</label>
          <div className="flex items-center">
            <select
              id="discipline-select"
              className="select select-bordered w-full dark:bg-slate-300"
              value={selectedDisciplineIcon}
              onChange={handleSelectDiscipline}
            >
              <option value="">Sélectionner une discipline</option>
              {allDisciplines.map((discipline) => (
                <option key={discipline.id} value={discipline.discipline_icone}>
                  {discipline.nom}
                </option>
              ))}
            </select>
            {selectedDisciplineIcon && (
              <div className="ml-4 text-2xl">
                {React.createElement(icons[selectedDisciplineIcon])}
              </div>
            )}
          </div>
           <Button
            text="Valider"
            onClick={handleSaveDiscipline}
            className={`mt-4 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
            hoverLabel={userRole === "visiteur" ? "Action désactivée pour les visiteurs" : "Valider"}
            disabled={userRole === "visiteur"}
          />
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4 text-left dark:text-black">
          Gestion des Disciplines
        </h2>
        <div className="flex justify-center mb-4">
          <Button
            text="Ajouter une discipline"
            icon={FaPlus}
            onClick={() => setIsCreateModalOpen(true)}
            className={`mb-3 gap-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
            hoverLabel={userRole === "visiteur" ? "Action désactivée pour les visiteurs" : "Ajouter une nouvelle discipline"}
            disabled={userRole === "visiteur"}
          />
        </div>
        {isCreateModalOpen && (
          <CreateDisciplineModal
            isActive={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
            refreshDisciplines={fetchDisciplines}
          />
        )}
        {isEditModalOpen && (
          <EditDisciplineModal
            isActive={isEditModalOpen}
            onClose={closeEditModal}
            refreshDisciplines={fetchDisciplines}
            disciplineData={selectedDisciplineData}
          />
        )}
        {isUploadModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
              <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                Changer la photo de {uploadingDiscipline.nom_discipline}
              </h2>
              <form
                onSubmit={handleUploadSubmit}
                className="flex flex-col items-center space-y-4 w-full"
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  className="my-2"
                />
                <div className="flex flex-auto gap-4">
                  <Button
                    text="Sauvegarder"
                    type="submit"
                    icon={FaSave}
                    className="gap-2"
                    isLoading={isUploading}
                  />
                  <Button
                    text="Fermer"
                    onClick={closeUploadModal}
                    icon={FaTimes}
                    className="gap-2"
                  />
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="block lg:hidden">
          {disciplines.map((discipline, index) => (
            <div
              key={discipline.id}
              className="bg-slate-100 dark:bg-slate-200 shadow-lg rounded-lg mb-4 p-4 dark:text-black"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <div className="flex flex-col items-center">
                    <img
                      src={discipline.image_discipline}
                      alt={discipline.nom_discipline}
                      className="w-16 h-16 rounded-full cursor-pointer"
                      onClick={() => openUploadModal(discipline)}
                    />
                    <span className="text-xs text-gray-500 mt-1 cursor-pointer" onClick={() => openUploadModal(discipline)}>
                      Modifier la photo
                    </span>
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold text-lg">{discipline.nom_discipline}</h3>
                    <p className="text-sm text-gray-700 dark:text-gray-800">
                      {discipline.description_discipline}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => moveDisciplineUp(index)}
                    className="text-blue-400 hover:text-blue-900"
                  >
                    <TbTriangleFilled />
                  </button>
                  <button
                    onClick={() => moveDisciplineDown(index)}
                    className="text-blue-400 hover:text-blue-900"
                  >
                    <TbTriangleInvertedFilled />
                  </button>
                </div>
              </div>
              <div className="mb-2 text-sm text-gray-700 dark:text-gray-800">
                <div dangerouslySetInnerHTML={{ __html: discipline.contenu_discipline }} />
              </div>
              <div className="flex justify-end">
                <ButtonEdit
                  onClick={() => openEditModal(discipline)}
                  className="mr-2"
                />
                <ButtonDelete
                  onClick={() => handleDeleteDiscipline(discipline)}
                  className="ml-2"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="hidden lg:block overflow-x-auto">
          <table className="table-auto bg-white dark:bg-slate-600 font-satoshi w-full mx-auto border-collapse border-2 border-slate-300 max-w-[1280px]">
            <thead className="bg-gray-800 text-white">
              <tr className="border-collapse border-2 border-slate-300">
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Contenu
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Position
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {disciplines.map((discipline, index) => (
                <tr
                  key={discipline.id}
                  className="hover:bg-gray-200 dark:hover:bg-gray-400"
                >
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex flex-col items-center">
                      <img
                        src={discipline.image_discipline}
                        alt={discipline.nom_discipline}
                        className="w-16 h-16 rounded-full cursor-pointer"
                        onClick={() => openUploadModal(discipline)}
                      />
                      <span className="text-xs text-gray-500 mt-1 cursor-pointer" onClick={() => openUploadModal(discipline)}>
                        Modifier la photo
                      </span>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {discipline.nom_discipline}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {discipline.description_discipline}
                  </td>
                  <td className="px-3 py-4 text-sm font-medium text-gray-900 max-w-xs overflow-hidden overflow-ellipsis">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: discipline.contenu_discipline,
                      }}
                    />
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                    {discipline.ordre_discipline}
                    <div className="flex flex-col ml-2">
                      <button
                        onClick={() => moveDisciplineUp(index)}
                        className="text-blue-400 hover:text-blue-900"
                      >
                        <TbTriangleFilled />
                      </button>
                      <button
                        onClick={() => moveDisciplineDown(index)}
                        className="text-blue-400 hover:text-blue-900"
                      >
                        <TbTriangleInvertedFilled />
                      </button>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex">
                       <ButtonEdit
                  onClick={() => openEditModal(discipline)}
                  className={`mr-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                  hoverLabel={userRole === "visiteur" ? "Édition désactivée pour les visiteurs" : "Éditer"}
                  disabled={userRole === "visiteur"}
                />
                <ButtonDelete
                  onClick={() => handleDeleteDiscipline(discipline)}
                  className={`ml-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                  hoverLabel={userRole === "visiteur" ? "Suppression désactivée pour les visiteurs" : "Supprimer"}
                  disabled={userRole === "visiteur"}
                />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DisciplinesTable;
