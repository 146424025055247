import React, { useState, useEffect } from "react";
import { getGroupMessages, deleteMessage, createMessage } from "./../../../services/chatServices";
import { getGroupsList } from "./../../../services/groupServices";
import { getAdherentsByUser } from "./../../../services/userServices";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import Button from "./../../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import MessageBubble from "./MessageBubble";
import { FaPaperPlane } from "react-icons/fa";


const ChatPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userId = user.userId;
  const userRole = getRoleFromRoleId(user?.role_id);
  const [message, setMessage] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [messages, setMessages] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  

useEffect(() => {
    if (userRole === "admin" || userRole === "enseignant") {
      const fetchAllGroups = async () => {
        try {
          const groupsData = await getGroupsList();
          setAllGroups(groupsData);
          if (groupsData.length > 0) {
            setSelectedGroupId(groupsData[0].id);
            setSelectedGroupName(groupsData[0].nom);
            fetchMessagesForGroup(groupsData[0].id);
          }
        } catch (error) {
          console.error('Error fetching all groups:', error);
        }
      };
      fetchAllGroups();
    } else {
      const fetchAdherents = async () => {
        const adherents = await getAdherentsByUser(userId);
        const uniqueGroups = [];
        adherents.forEach(adherent => {
          adherent.Groups.forEach(group => {
            if (!uniqueGroups.find(g => g.id === group.id)) {
              uniqueGroups.push({
                id: group.id,
                nom: group.nom,
                adherentNom: `${adherent.prenom} ${adherent.nom}`
              });
            }
          });
        });
        setGroups(uniqueGroups);
        if (uniqueGroups.length > 0) {
          setSelectedGroupId(uniqueGroups[0].id);
          setSelectedGroupName(uniqueGroups[0].nom);
          fetchMessagesForGroup(uniqueGroups[0].id);
        }
      };
      fetchAdherents();
    }
  }, [userId, userRole]);

  useEffect(() => {
  let intervalId;

  const fetchMessages = async () => {
    if (selectedGroupId) {
      try {
        const msgs = await getGroupMessages(selectedGroupId);
        const sortedMsgs = msgs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setMessages(sortedMsgs.map(msg => ({
          ...msg,
          currentUserId: userId,
        })));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }
  };

  fetchMessages(); // Initial fetch
  intervalId = setInterval(fetchMessages, 5000); // Polling every 5 seconds

  return () => clearInterval(intervalId); // Clean up on component unmount or change of `selectedGroupId`
}, [selectedGroupId, userId]);


  const handleGroupChange = (e) => {
  const groupId = Number(e.target.value);
  setSelectedGroupId(groupId);
  
  // Rechercher dans tous les groupes si l'utilisateur est admin ou enseignant, sinon dans les groupes d'adhérents
  const group = (userRole === "admin" || userRole === "enseignant" ? allGroups : groups).find(g => g.id === groupId);
  
  setSelectedGroupName(group ? group.nom : '');
  fetchMessagesForGroup(groupId);
};

  // Ajoutez cette nouvelle fonction pour charger les messages
const fetchMessagesForGroup = async (groupId) => {
  try {
    const msgs = await getGroupMessages(groupId);
    const sortedMsgs = msgs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    setMessages(sortedMsgs.map(msg => ({
      ...msg,
      currentUserId: userId,
    })));
  } catch (error) {
    console.error('Error fetching messages for group:', error);
    toast.error('Failed to fetch messages for the selected group');
  }
};

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() && selectedGroupId) {
      try {
        const newMessage = await createMessage({
          group_id: selectedGroupId,
          user_id: user.userId,
          message
        });
        if (newMessage) {
          setMessages(prevMessages => [...prevMessages, {...newMessage, currentUserId: user.userId}]);
          setMessage(""); // Clear input after sending
        } else {
          toast.error("No response from server, message might not have been sent.");
        }
      } catch (error) {
        console.error("Failed to send message:", error);
        toast.error("Failed to send message");
      }
    }
};

const handleDeleteMessage = async (messageId) => {
  try {
    await deleteMessage(messageId);  // Supposons que deleteMessage est une fonction d'API pour supprimer un message
    setMessages(messages.filter(msg => msg.id !== messageId));
    toast.success("Message supprimé avec succès.");
  } catch (error) {
    console.error("Failed to delete message:", error);
    toast.error("Erreur lors de la suppression du message.");
  }
};


  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Discussion entre Groupes
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto dark:text-gray-800" >
        <p className="text-lg font-semibold">Bienvenue sur le Chat du Club!</p>
        <p className="text-sm">Cet espace est réservé pour échanger sur notre passion du judo. Veuillez respecter les règles suivantes : pas de politique, pas de religion, pas de données personnelles, respect des autres et aucun langage grossier. Le Code Moral du Judo nous unit. L'administrateur peut supprimer des messages trop anciens.</p>
      </div>

     {userRole === "admin" || userRole === "enseignant" ? (
      <div className="w-full mx-auto mt-4 mb-4 max-w-[800px]">
        <p className="text-xs font-semibold mb-1 ml-1 w-full">Sélection du groupe</p>
        <select onChange={e => handleGroupChange(e)} value={selectedGroupId} className="select select-bordered w-full dark:bg-slate-200 dark:text-gray-800">
          {allGroups.map(group => (
            <option key={group.id} value={group.id}>{group.nom}</option>
          ))}
        </select>
      </div>
    ) : (
      <div className="w-full mx-auto mt-4 max-w-[800px]">
        <p className="text-xs font-semibold mb-1 ml-1 w-full">Sélectionnez le groupe pour votre/vos adhérents</p>
        <select onChange={e => handleGroupChange(e)} value={selectedGroupId} className="select select-bordered w-full dark:bg-slate-200 dark:text-gray-800">
          {groups.map(group => (
            <option key={group.id} value={group.id}>{group.nom} ({group.adherentNom})</option>
          ))}
        </select>
      </div>
    )}
        <div className="bg-gray-100 p-4 shadow rounded-lg max-w-[800px] mx-auto w-full dark:bg-slate-400">
          <h3 className="text-xl font-semibold bg-primary text-white p-4 font-satoshi rounded-md mb-4">
            Groupe actuel : {selectedGroupName}
          </h3>
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              message={msg}
              onDeleteMessage={() => handleDeleteMessage(msg.id)}
              canDelete={userId === msg.user_id || userRole === 'admin' || userRole === 'enseignant'}
            />
          ))}
        </div>
      
      <form onSubmit={handleSendMessage} className="flex items-center justify-between p-2 mr-2 max-w-[800px] mx-auto w-full">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="input input-bordered flex-grow dark:bg-slate-200"
          placeholder="Ecrire un message..."
        />
        <Button text="Send" onClick={handleSendMessage} icon={FaPaperPlane} className="gap-2 ml-2"/>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ChatPage;