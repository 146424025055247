import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "./../../../components/Button/Button";
import { createDossierInscription } from "./../../../services/dossierServices";
import { getAllAdherentsList } from "./../../../services/adherentServices";
// import { useSendNotificationToHandlers } from "../../hooks/useSendNotificationToHandlers";

const CreateDossierInscriptionModal = ({ isOpen, onClose, refreshList }) => {
  const [formData, setFormData] = useState({
    inscription_adherent_id: "",
    cm_url: "",
    date_cm: "",
    validate_cm: false,
    formulaire_licence_a_signer: "",
    formulaire_licence_signe: "",
    licence_url: "",
    validate_licence: false,
    validate_reglement: false,
    lien_drive: "",
  });
  const [adherents, setAdherents] = useState([]);
  // const sendNotificationToHandlers = useSendNotificationToHandlers();

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const adherentsList = await getAllAdherentsList();
        console.log("Adherents received:", adherentsList); // Vérifiez la structure ici
        setAdherents(adherentsList);
      } catch (error) {
        console.error("Erreur lors du chargement des destinataires:", error);
        toast.error("Erreur lors du chargement des destinataires.");
      }
    };

    fetchAdherents();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`${name} changed to: `, value); // Affiche les changements en temps réel
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inscription_adherent_id) {
      toast.error("Veuillez sélectionner un adhérent.");
      return;
    }

    try {
      await createDossierInscription(formData);
      refreshList();
      onClose();
      toast.success("Dossier d'inscription créé avec succès.");
      setFormData({
        inscription_adherent_id: "",
        cm_url: "",
        date_cm: "",
        validate_cm: false,
        formulaire_licence_a_signer: "",
        formulaire_licence_signe: "",
        licence_url: "",
        validate_licence: false,
        validate_reglement: false,
        lien_drive: "",
      }); // Reset form after submission

      // Vérifiez si tous les booléens sont à true et envoyez la notification
      //  if (
      //    formData.validate_cm &&
      //    formData.validate_licence &&
      //    formData.validate_reglement
      //  ) {
      //    sendNotificationToHandlers(formData.inscription_adherent_id);
      //  }
    } catch (error) {
      console.error("Erreur lors de la création du dossier", error);
      toast.error("Erreur lors de la création du dossier.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 dark:text-black font-satoshi">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Créer un Dossier d'inscription
        </h3>
        <form onSubmit={handleSubmit}>
          <select
            name="inscription_adherent_id"
            className="block w-full p-2 border border-gray-300 rounded"
            value={formData.inscription_adherent_id}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un adhérent</option>
            {adherents.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom} {adherent.id}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="cm_url"
            placeholder="URL du Certificat Médical"
            value={formData.cm_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="date"
            name="date_cm"
            value={formData.date_cm}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex items-center">
            <input
              type="checkbox"
              name="validate_cm"
              checked={formData.validate_cm}
              onChange={handleChange}
              className="checkbox checkbox-primary mb-3"
            />
            <span className="ml-2">Certificat Médical Validé</span>
          </div>
          <input
            type="text"
            name="formulaire_licence_a_signer"
            placeholder="Formulaire de Licence à signer"
            value={formData.formulaire_licence_a_signer}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="text"
            name="formulaire_licence_signe"
            placeholder="Formulaire de Licence signé"
            value={formData.formulaire_licence_signe}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="text"
            name="licence_url"
            placeholder="Licence URL"
            value={formData.licence_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex items-center">
            <input
              type="checkbox"
              name="validate_licence"
              checked={formData.validate_licence}
              onChange={handleChange}
              className="checkbox checkbox-primary mb-3"
            />
            <span className="ml-2">Licence Validée</span>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="validate_reglement"
              checked={formData.validate_reglement}
              onChange={handleChange}
              className="checkbox checkbox-primary"
            />
            <span className="ml-2">Règlement Validé</span>
          </div>
          <input
            type="text"
            name="lien_drive"
            placeholder="Lien Drive"
            value={formData.lien_drive}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 mt-4"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDossierInscriptionModal;
