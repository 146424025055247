import React, { useEffect, useState } from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getAllEnseignants } from "../../../services/adherentServices";
import {
  getAllHeures_enseignement,
  updateHeures_enseignement,
  deleteHeures_enseignement,
  deleteAllHeures_enseignement, // Importer la fonction ici
} from "../../../services/heuresenseignementServices";
import { toast } from "react-toastify";
import AddHoursModal from "./AddHoursModal";
import UpdateHoursModal from "./UpdateHoursModal";
import Button from "../../../components/Button/Button";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";

const HeuresEnseignement = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [enseignants, setEnseignants] = useState([]);
  const [heuresEnseignement, setHeuresEnseignement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedHeure, setSelectedHeure] = useState(null);

  const fetchData = async () => {
    try {
      const enseignantsData = await getAllEnseignants();
      setEnseignants(enseignantsData);

      const heuresData = await getAllHeures_enseignement();
      setHeuresEnseignement(heuresData);
    } catch (error) {
      toast.error("Erreur lors du chargement des données.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const calculateTotalHours = (enseignantId) => {
    return heuresEnseignement
      .filter((item) => item.adherent_id === enseignantId)
      .reduce((total, item) => total + parseFloat(item.heures), 0)
      .toFixed(2);
  };

  const calculateMonthlyHours = (month, enseignantId) => {
    return heuresEnseignement
      .filter((item) => item.adherent_id === enseignantId && item.mois === month)
      .reduce((total, item) => total + parseFloat(item.heures), 0)
      .toFixed(2);
  };

  const formatHeures = (heures) => {
    return parseFloat(heures)
      .toFixed(2)
      .replace(/\.00$/, "")
      .replace(/(\.\d)0$/, "$1");
  };

  const handleEditClick = (heure) => {
    setSelectedHeure(heure);
    setIsUpdateModalOpen(true);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette entrée?")) {
      try {
        await deleteHeures_enseignement(id);
        toast.success("Heures d'enseignement supprimées avec succès.");
        fetchData();
      } catch (error) {
        toast.error("Erreur lors de la suppression des heures d'enseignement.");
        console.error(
          "Erreur lors de la suppression des heures d'enseignement:",
          error
        );
      }
    }
  };

  const handleDeleteAllClick = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer toutes les heures d'enseignement? Cette action est irréversible.")) {
      try {
        await deleteAllHeures_enseignement(); // Appeler la fonction pour supprimer toutes les heures
        toast.success("Toutes les heures d'enseignement ont été supprimées avec succès.");
        fetchData(); // Recharger les données après suppression
      } catch (error) {
        toast.error("Erreur lors de la suppression de toutes les heures d'enseignement.");
        console.error(
          "Erreur lors de la suppression de toutes les heures d'enseignement:",
          error
        );
      }
    }
  };

  const renderTable = () => {
    const semaines = [
      ...new Set(heuresEnseignement.map((item) => item.semaine)),
    ].sort();
    const enseignantsMap = enseignants.reduce((acc, enseignant) => {
      acc[enseignant.id] = `${enseignant.nom} ${enseignant.prenom}`;
      return acc;
    }, {});
    const mois = [
      ...new Set(heuresEnseignement.map((item) => item.mois)),
    ].sort();

    return (
      <div className="overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-satoshi max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr className="border-collapse border-2 border-slate-300">
              <th className="px-2 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Semaine
              </th>
              {enseignants.map((enseignant) => (
                <th
                  key={enseignant.id}
                  className="px-2 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider"
                >
                  {enseignant.nom} {enseignant.prenom}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-700 cursor-pointer">
            {mois.map((mois, moisIndex) => (
              <React.Fragment key={mois}>
                {semaines
                  .filter((semaine) =>
                    heuresEnseignement.find(
                      (item) => item.semaine === semaine && item.mois === mois
                    )
                  )
                  .map((semaine, semaineIndex) => (
                    <tr
                      key={`${mois}-${semaine}`}
                      className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white h-full text-sm"
                    >
                      <td className="px-2 py-2 border-b-2 border-gray-200 text-center text-sm">
                        {semaine}
                      </td>
                      {enseignants.map((enseignant) => {
                        const heure = heuresEnseignement.find(
                          (item) =>
                            item.semaine === semaine &&
                            item.adherent_id === enseignant.id
                        );
                        return (
                          <td
                            key={`${mois}-${semaine}-${enseignant.id}`}
                            className="px-2 py-2 border-b-2 border-gray-200 text-center text-sm"
                          >
                            {heure ? (
                              <div className="flex items-center justify-center">
                                <span>{formatHeures(heure.heures)}</span>
                                <div className="flex space-x-1 ml-2">
                                  <FaEdit
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() => handleEditClick(heure)}
                                  />
                                  <FaTrash
                                    className="text-red-500 cursor-pointer"
                                    onClick={() => handleDeleteClick(heure.id)}
                                  />
                                </div>
                              </div>
                            ) : (
                              0
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                <tr key={`subtotal-${mois}`} className="bg-gray-200">
                  <td className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider">
                    {mois}
                  </td>
                  {enseignants.map((enseignant) => (
                    <td
                      key={`subtotal-${mois}-${enseignant.id}`}
                      className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-bold pl-6 uppercase tracking-wider"
                    >
                      {formatHeures(calculateMonthlyHours(mois, enseignant.id))}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
          <tfoot className="bg-black text-white">
            <tr className="border-collapse border-2 border-slate-300">
              <td className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider">
                Total
              </td>
              {enseignants.map((enseignant) => (
                <td
                  key={`total-${enseignant.id}`}
                  className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider pl-6"
                >
                  {formatHeures(calculateTotalHours(enseignant.id))}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Heures d'Enseignement
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="flex mx-auto">
        <div className="px-2 mb-8 gap-5 mx-auto">
          <Button
            text="Ajouter des Heures"
            icon={FaPlus}
            onClick={() => setIsAddModalOpen(true)}
            className="btn-primary mb-4"
          />
          <Button
            text="Supprimer toutes les Heures"
            icon={FaTrash}
            onClick={handleDeleteAllClick}
            className="btn-error mb-4"
          />
          {loading ? <p>Chargement...</p> : renderTable()}
        </div>
        <AddHoursModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          refreshData={fetchData}
          enseignants={enseignants}
          heuresEnseignement={heuresEnseignement} // Ajouter cette ligne
        />
        {selectedHeure && (
          <UpdateHoursModal
            isOpen={isUpdateModalOpen}
            onClose={() => setIsUpdateModalOpen(false)}
            heure={selectedHeure}
            refreshData={fetchData}
          />
        )}
      </div>
    </div>
  );
};

export default HeuresEnseignement;
