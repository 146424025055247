// components/CreateMessageGroupForm.js

import React, { useState, useEffect } from 'react';
import { getAllGroupsMessage } from './../../../services/groupServices';
import { sendGroupMessage } from './../../../services/messageServices';
import Button from "./../../../components/Button/Button";
import { IoIosSend } from "react-icons/io";
import { toast } from 'react-toastify';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importez les styles de React-Quill

const CreateMessageGroupForm = ({ userId, onHide, token }) => {
  const [formData, setFormData] = useState({
    groupId: '',
    subject: '',
    content: '',
  });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const fetchedGroups = await getAllGroupsMessage();
        setGroups(fetchedGroups);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const messageData = {
        sender_id: userId,
        group_id: formData.groupId,
        subject: formData.subject,
        content: formData.content
      };
      await sendGroupMessage(messageData);  // Assurez-vous de passer le token correctement
      toast.success("Message envoyé avec succès.");
      onHide();
    } catch (error) {
      console.error("Erreur lors de l'envoi du message groupé:", error);
      toast.error("Erreur lors de l'envoi du message.");
    }
  };

  return (
    <div className="mt-5 container mx-auto p-4 shadow-lg rounded-lg bg-white text-black font-satoshi">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold font-satoshi">Groupe:</label>
          <select name="groupId" value={formData.groupId} onChange={handleChange} className="select select-bordered w-full max-w-xs font-satoshi" required>
            <option value="" className='font-satoshi'>Choisissez un groupe</option>
            {groups.map(group => <option key={group.id} value={group.id}>{group.nom}</option>)}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Objet:</label>
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="input input-bordered w-full max-w-xs font-satoshi"
            required
          >
            <option value="">Sélectionnez une option</option>
            <option value="Adhésion">Adhésion</option>
            <option value="Dossier d'inscription">Dossier d'inscription</option>
            <option value="Règlement">Règlement</option>
            <option value="Informations cours">Informations cours</option>
            <option value="Absence">Absence</option>
            <option value="Inscription compétition">
              Inscription compétition
            </option>
            <option value="Perte d'objet">Perte d'objet</option>
            <option value="Autre">Autre</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Contenu:</label>
          <ReactQuill
            value={formData.content}
            onChange={(content) => setFormData({ ...formData, content })}
            className="quill"
            required
          />
        </div>

        <div className="flex justify-center gap-4 items-center w-full px-4 mt-4">
          <Button
            text="Envoyer"
            icon={IoIosSend}
            type="submit"
            className="w-xl gap-2 mb-4"
          />
          <Button
            text="Annuler"
            onClick={onHide}
            className="w-xl gap-2 mb-4"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateMessageGroupForm;
