import React, { useState, useEffect } from "react";
import { getNews, deleteNews, createNews } from "./../../../services/newsServices";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import Button from "./../../../components/Button/Button";
import NewsCard from './NewsCard';
import CreateNewsModal from "./CreateNewsModal";
import EditNewsModal from "./EditNewsModal";
import { ToastContainer, toast } from "react-toastify";

const NewsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [newsList, setNewsList] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getNews();
        setNewsList(data);
      } catch (error) {
        console.error('Failed to fetch news', error);
      }
    };
    fetchNews();
  }, []);

  const canViewInactive = (news) => {
    return news.is_active || userRole === "admin" || userRole === "enseignant" || userRole === "visiteur";
  };

  const handleAddNewsClick = async () => {
    if (!user || !user.userId) {
      console.error("User ID is missing.");
      toast.error("Erreur lors de la création de l'actualité : utilisateur non identifié.");
      return;
    }

    const newsData = { title: '', content: '', image_url: '', author_id: user.userId, is_active: true };

    try {
      const newNews = await createNews(newsData);
      setCurrentNews(newNews);
      setIsCreateModalOpen(true);
    } catch (error) {
      console.error("Erreur lors de la création de l'actualité:", error);
      toast.error("Erreur lors de la création de l'actualité.");
    }
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateSuccess = () => {
    closeCreateModal();
    refreshNewsList()
      .then(() => {
        toast.success('Actualité créée avec succès');
      })
      .catch(error => {
        toast.error('Erreur lors de la mise à jour des actualités');
      });
  };

  const handleEditNews = (news) => {
    if (userRole === "admin" || userRole === "enseignant" || userRole === "visiteur") {
      setCurrentNews(news); // Set current news to be edited
      setIsEditModalOpen(true); // Open the modal
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentNews(null);
  };

  const handleEditSuccess = () => {
    closeEditModal();
    refreshNewsList()
      .then(() => {
        toast.success('Actualité mise à jour avec succès');
      })
      .catch(error => {
        toast.error('Erreur lors de la mise à jour des actualités');
      });
  };

  const handleDeleteNews = async (id) => {
    if (userRole === "admin" || userRole === "enseignant" || userRole === "visiteur") {
      try {
        await deleteNews(id);
        setNewsList(newsList.filter(news => news.id !== id)); // Update state after deletion
        toast.success('Actualité supprimée avec succès');
      } catch (error) {
        console.error('Failed to delete news', error);
        toast.error('Erreur lors de la suppression de l\'actualité');
      }
    }
  };

  const refreshNewsList = async () => {
    try {
      const updatedNewsList = await getNews();
      setNewsList(updatedNewsList);
      return updatedNewsList;  // Retourner la promesse pour un chaînage correct
    } catch (error) {
      console.error('Failed to refresh news', error);
      throw error;  // Propager l'erreur pour le catch
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Actualités & Résultats
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {(userRole === "admin" || userRole === "enseignant") && (
            <Button text="Ajouter une Actualité" onClick={handleAddNewsClick} className="mb-4" />
          )}
          <CreateNewsModal isOpen={isCreateModalOpen} onClose={closeCreateModal} onCreateSuccess={handleCreateSuccess} news={currentNews} />
          <EditNewsModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            refreshList={refreshNewsList}
            news={currentNews}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
          {newsList.filter(news => canViewInactive(news)).map(news => (
            <NewsCard 
              key={news.id} 
              news={news} 
              newsId={news.id}
              onDelete={() => handleDeleteNews(news.id)}
              canModify={userRole === "admin" || userRole === "enseignant"}
              refreshList={() => refreshNewsList()}
            />
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewsPage;
