import React, { useState, useEffect } from "react";
import { getLexique } from "./../../../services/lexiqueServices"; // Assurez-vous que le chemin est correct
import { getLexiqueKarate } from "./../../../services/lexiquekarateServices"; // Assurez-vous que le chemin est correct
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { getSettings } from "./../../../services/settingsServices";

const LexiquePage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [lexique, setLexique] = useState([]);
  const [filteredLexique, setFilteredLexique] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLetterIndex, setSelectedLetterIndex] = useState(0);
  const [discipline, setDiscipline] = useState('');

  useEffect(() => {
    const fetchLexique = async () => {
      try {
        const settings = await getSettings();
        const discipline = settings[0].discipline_nom;
        setDiscipline(discipline);

        let data;
        switch (discipline) {
          case 'Karate':
            data = await getLexiqueKarate();
            break;
          case 'Judo':
          default:
            data = await getLexique();
            break;
        }
        setLexique(data);
        setFilteredLexique(data);
      } catch (error) {
        console.error("Erreur lors de la récupération du lexique:", error);
      }
    };
    fetchLexique();
  }, []);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    filterLexique(event.target.value, selectedLetterIndex);
  };

  const handleSliderChange = event => {
    const letterIndex = parseInt(event.target.value);
    setSelectedLetterIndex(letterIndex);
    filterLexique(searchTerm, letterIndex);
  };

  const filterLexique = (term, letterIndex) => {
    const lowerCaseTerm = term.toLowerCase();
    const filteredData = lexique.filter(entry => {
      const matchesSearchTerm = entry.japonais.toLowerCase().includes(lowerCaseTerm) || entry.traduction.toLowerCase().includes(lowerCaseTerm);
      const matchesLetter = letterIndex === 0 || entry.japonais.toLowerCase().startsWith(String.fromCharCode(64 + letterIndex).toLowerCase());
      return matchesSearchTerm && matchesLetter;
    });
    setFilteredLexique(filteredData);
  };

  const getLetter = (index) => {
    if (index === 0) return "Toutes";
    return String.fromCharCode(64 + index);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Lexique {discipline}
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="overflow-x-auto mx-auto max-w-[1280px]">
        <input
          type="text"
          placeholder="Rechercher un terme..."
          value={searchTerm}
          onChange={handleSearch}
          className="input input-bordered w-full max-w-xs mb-4 mt-2"
        />
        
        <div className="flex justify-center items-center mb-4">
          <input
            type="range"
            min={0}
            max={26}
            value={selectedLetterIndex}
            onChange={handleSliderChange}
            className="range range-warning"
          />
          <span className="ml-2 text-lg font-semibold">
            {getLetter(selectedLetterIndex)}
          </span>
        </div>

        <div className="overflow-y-auto w-[400px] mt-4 font-satoshi mb-[500px]">
          {filteredLexique.map(item => (
            <div key={item.japonais} className="flex bg-base-100 shadow-xl mb-1 pl-3 dark:bg-slate-400 hover:bg-slate-200 dark:hover:bg-slate-500 w-full">
              <div className="flex flex-row justify-between w-full">
                <h2 className="text-sm font-semibold mr-2 w-1/3">{item.japonais} :</h2>
                <p className="italic text-sm pr-4 w-2/3">{item.traduction}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LexiquePage;
