import React, { useState, useEffect } from "react";
import { getBeltHistoryByAdherent, deleteBeltHistory, getBeltsList, addBeltHistory, editBeltHistory } from "../../../services/beltServices";
import Button from "../../../components/Button/Button";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import { FaSave, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const AdherentPassageCeinture = ({ adherentId, onBeltHistoryChange }) => {
  const [ceintures, setCeintures] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [belts, setBelts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedBelt, setSelectedBelt] = useState("");
  const [datePassage, setDatePassage] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentEditingId, setCurrentEditingId] = useState(null);

  const loadBeltHistories = async () => {
    try {
      const data = await getBeltHistoryByAdherent(adherentId);
      setCeintures(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des ceintures:", error);
      toast.error("Impossible de charger les historiques de passage de ceinture.");
    }
  };

  useEffect(() => {
    const fetchBelts = async () => {
      try {
        const beltsList = await getBeltsList();
        setBelts(beltsList);
        await loadBeltHistories();
      } catch (error) {
        console.error("Erreur lors de la récupération des listes de ceintures:", error);
      }
    };
    fetchBelts();
  }, [adherentId]);

  const handleAddClick = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setSelectedBelt("");
    setDatePassage("");
  };

  const handleEditClick = (ceinture) => {
    setSelectedBelt(ceinture.belt_id);
    setDatePassage(ceinture.date_passage);
    setCurrentEditingId(ceinture.id);
    setEditing(true);
    setShowForm(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce passage de grade ?")) {
      try {
        await deleteBeltHistory(id);
        toast.success("Passage de grade supprimé avec succès");
        await loadBeltHistories();
        onBeltHistoryChange();  // Notifier le composant parent
      } catch (error) {
        console.error("Erreur lors de la suppression:", error);
        toast.error("Erreur lors de la suppression du passage de grade.");
      }
    }
  };

  const handleSave = async () => {
    if (!selectedBelt || !datePassage) {
      toast.error("Veuillez remplir tous les champs");
      return;
    }
    try {
      await addBeltHistory({ adherent_id: adherentId, belt_id: selectedBelt, date_passage: datePassage });
      toast.success("Nouveau passage de grade ajouté avec succès");
      await loadBeltHistories();
      onBeltHistoryChange();  // Notifier le composant parent
      setShowForm(false);
      setSelectedBelt("");
      setDatePassage("");
    } catch (error) {
      console.error("Erreur lors de l'ajout du passage de grade", error);
      toast.error("Erreur lors de l'ajout du passage de grade");
    }
  };

  const handleUpdate = async () => {
    if (!selectedBelt || !datePassage) {
      toast.error("Veuillez remplir tous les champs");
      return;
    }
    try {
      await editBeltHistory(currentEditingId, {
        belt_id: selectedBelt,
        date_passage: datePassage
      });
      toast.success("Passage de grade mis à jour avec succès");
      await loadBeltHistories();
      onBeltHistoryChange();  // Notifier le composant parent
      setEditing(false);
      setSelectedBelt("");
      setDatePassage("");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du passage de grade", error);
      toast.error("Erreur lors de la mise à jour du passage de grade");
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg max-w-[800px] mx-auto font-satoshi dark:bg-gray-400">
      <h3 className="text-xl font-semibold text-gray-800 mb-4 dark:text-white">Historique de Passage de Ceinture</h3>
      <div className="max-w-[800px]">
        <table className="table-auto bg-white dark:bg-slate-300 font-satoshi mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr className="border-collapse  border-2 border-slate-300 text-white">
              <th scope="col" className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">Date de Passage</th>
              <th scope="col" className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider">Ceinture</th>
              <th scope="col" className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {ceintures.length > 0 ? ceintures.map((ceinture, index) => (
              <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-800 dark:hover:text-white">
                <td className="px-5 py-3">{new Date(ceinture.date_passage).toLocaleDateString()}</td>
                <td className="px-5 py-3">{ceinture.BeltColor.nom || "Inconnu"}</td>
                <td className="px-5 py-3 flex gap-2">
                  <ButtonEdit
                    text="Modifier"
                    onClick={() => handleEditClick(ceinture)}
                  />
                  <ButtonDelete
                    text="Supprimer"
                    onClick={() => handleDelete(ceinture.id)}
                  />
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="3" className="text-center py-4 px-6 text-gray-800">Aucun historique de passage de ceinture disponible.</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="mt-4 flex justify-center gap-2">
          {!editing && (
            <Button text="Ajouter un passage de ceinture" onClick={handleAddClick} className="btn btn-primary mb-4" />
          )}
          {(editing || showForm) && (
            <div>
              <select className="select select-bordered w-full mb-2 dark:bg-slate-200 dark:text-gray-800" value={selectedBelt} onChange={e => setSelectedBelt(e.target.value)}>
                <option value="">Sélectionnez une ceinture</option>
                {belts.map(belt => <option key={belt.id} value={belt.id}>{belt.nom}</option>)}
              </select>
              <input type="date" className="input input-bordered w-full mb-2 dark:bg-slate-200" value={datePassage} onChange={e => setDatePassage(e.target.value)} />
              <div className="flex justify-between">
                {editing ? (
                  <Button text="Mise à jour" icon={FaSave} onClick={handleUpdate} className="btn btn-success" />
                ) : (
                  <Button text="Valider" icon={FaSave} onClick={handleSave} className="btn btn-success" />
                )}
                <Button text="Annuler" icon={FaTimes} onClick={() => { setEditing(false); handleCancel(); }} className="btn btn-error" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdherentPassageCeinture;
