import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getAllEnseignants } from "../../../services/adherentServices";
import {
  getTeacherEvents,
  unregisterAdherent,
} from "../../../services/eventServices";
import EditHoursModal from "./EditHoursModal";
import CoachingSummary from "./CoachingSummary";
import CoachingHoursChart from "./CoachingHoursChart";

// This is a React component for managing coaching.
const Coaching = () => {
  // Hook to scroll to top of page when component mounts.
  useScrollToTop();
   // Context hook to get the current user and their role.
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const isAdmin = userRole === "admin";

   // State hooks for teachers, selected teacher, events, edit modal visibility, and current event.
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [events, setEvents] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  // This useEffect hook fetches teachers when the component mounts.
  useEffect(() => {
    fetchTeachers();
  }, []);

// This function fetches all teachers.
  const fetchTeachers = async () => {
    try {
      const data = await getAllEnseignants();
      setTeachers(data);
    } catch (error) {
      toast.error("Erreur lors de la récupération des enseignants");
    }
  };

  // This function handles changes to the selected teacher and fetches their events.
  const handleTeacherChange = async (e) => {
    const teacherId = e.target.value;
    setSelectedTeacher(teacherId);
    if (teacherId) {
      fetchEventsForTeacher(teacherId);
    }
  };

// This function fetches events for a given teacher.
  const fetchEventsForTeacher = async (teacherId) => {
    try {
      const eventsData = await getTeacherEvents(teacherId);
      setEvents(eventsData.Events || []);
    } catch (error) {
      toast.error(
        "Erreur lors de la récupération des événements pour l'enseignant"
      );
    }
  };

  // This function opens the edit modal for a given event.
  const openEditModal = (event) => {
    setCurrentEvent(event);
    setIsEditModalOpen(true);
  };

  // This function closes the edit modal and refreshes the events for the selected teacher.
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchEventsForTeacher(selectedTeacher); // Refresh events after editing
  };

  // This function handles unregistering an adherent from an event.
  const handleUnregisterAdherent = async (adherentId, eventId) => {
    if (
      window.confirm(
        "Voulez-vous vraiment supprimer ce coach de l'événement? Cela supprimera également les heures de coaching enregistrées."
      )
    ) {
      try {
        await unregisterAdherent(adherentId, eventId);
        toast.success("Coach supprimé de l'événement avec succès");
        fetchEventsForTeacher(selectedTeacher); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error(
          "Erreur lors de la suppression du coach de l'événement",
          error
        );
        toast.error("Erreur lors de la suppression du coach de l'événement");
      }
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Suivi Coaching
      </h2>
      <DashboardNavigation role={userRole} />
      <CoachingHoursChart />
      <div className="flex w-full max-w-[800px] mx-auto mt-4">
        <select
          value={selectedTeacher}
          onChange={handleTeacherChange}
          className="select select-bordered w-full max-w-xs mb-4 dark:text-black"
        >
          <option value="">Sélectionnez un enseignant</option>
          {teachers.map((teacher) => (
            <option key={teacher.id} value={teacher.id}>
              {teacher.nom} {teacher.prenom}
            </option>
          ))}
        </select>
      </div>

      {events.length > 0 && (
        <div className="overflow-x-auto shadow-xl font-satoshi mb-6">
          <table className=" bg-white divide-y divide-gray-200 max-w-[800px] mx-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-black">
              <tr>
                <th className="px-6 py-3">Date</th>
                <th className="px-6 py-3">Titre de l'événement</th>
                <th className="px-6 py-3">Heures de présence</th>
                <th className="px-6 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr
                  key={event.id}
                  className="dark:text-black dark:bg-slate-200"
                >
                  <td className="px-6 py-4 text-sm">
                    {new Date(event.start).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm">{event.titre}</td>
                  <td className="px-6 py-4 text-sm">
                    {event.AdherentEvent.coach_hour || "Non renseigné"}
                  </td>
                  <td className="px-6 py-4 flex space-x-2 text-sm">
                    <ButtonEdit onClick={() => openEditModal(event)} />
                    <ButtonDelete
                      onClick={() =>
                        handleUnregisterAdherent(
                          event.AdherentEvent.adherent_id,
                          event.AdherentEvent.event_id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <CoachingSummary events={events} />
        </div>
      )}
      {isEditModalOpen && currentEvent && (
        <EditHoursModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          event={currentEvent}
          refreshEvents={() => fetchEventsForTeacher(selectedTeacher)}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Coaching;
