import React, { useState } from "react";
import Button from "../../../components/Button/Button"; // Assurez-vous du chemin

const DisciplineCard = ({ title, ageRange, imageSrc, textDiscipline }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  const toggleDetails = () => setDetailsVisible(!detailsVisible);

  return (
    <div className="relative overflow-hidden shadow-lg mb-4 flex flex-col items-center bg-white dark:bg-gray-700 p-4 hover:shadow-xl transition duration-300 ease-in-out rounded-lg">
      <img
        src={imageSrc}
        alt={title}
        className="w-full h-56 object-cover hover:scale-105 transition duration-300 ease-in-out rounded-lg"
      />
      <div className="text-center mt-4">
        <h3 className="font-bold font-satoshi text-2xl dark:text-white">{title}</h3>
        <p className="text-base font-satoshi mb-2 dark:text-gray-400">{ageRange}</p>
      </div>
      <Button
        text={detailsVisible ? "Moins de détails" : "Détails"}
        onClick={toggleDetails}
        className="btn btn-sm mt-2"
      />
      {detailsVisible && (
        <div className="text-justify font-satoshi mt-2 text-gray-800 dark:text-gray-200">
          <p dangerouslySetInnerHTML={{ __html: textDiscipline }} />
        </div>
      )}
    </div>
  );
};

export default DisciplineCard;
