// /client/src/components/TableauAchatsGroupes.js
import React, { useState, useEffect } from "react";
import { getReglements } from "./../../../services/achatsServices";

const TableauAchatsGroupes = ({ selectedSeason }) => {
  const [achatsGroupes, setAchatsGroupes] = useState([]);
  const [prixGlobal, setPrixGlobal] = useState(0);

  useEffect(() => {
    if (selectedSeason) {
      getReglements()
        .then((achats) => {
          if (!achats || !Array.isArray(achats)) {
            console.error("Les données reçues ne sont pas un tableau:", achats);
            return; // Stoppe l'exécution pour éviter d'autres erreurs
          }

          // Définir les dates de début et de fin pour la saison sélectionnée
          const [startYear, endYear] = selectedSeason.split("-");
          const startDate = new Date(`${startYear}-08-01`);
          const endDate = new Date(`${endYear}-07-31`);

          // Filtrer les achats par date de saison
          const filteredAchats = achats.filter((achat) => {
            const achatDate = new Date(achat.date_achat);
            return achatDate >= startDate && achatDate <= endDate;
          });

          const groupes = filteredAchats.reduce((acc, achat) => {
            if (!achat || !achat.Article || !achat.Article.nom) {
              console.error("Achat ou Article invalide:", achat);
              return acc;
            }

            const key = achat.Article.nom; // Groupement par le nom de l'article
            if (!acc[key]) {
              acc[key] = { ...achat, quantite: 0, prix_total: 0 };
            }
            acc[key].quantite += achat.quantite;
            acc[key].prix_total += parseFloat(achat.prix_total);
            return acc;
          }, {});

          const groupesValues = Object.values(groupes);
          setAchatsGroupes(groupesValues);

          const prixGlobal = groupesValues.reduce((acc, curr) => {
            return acc + curr.prix_total;
          }, 0);
          setPrixGlobal(prixGlobal);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des achats", error);
        });
    }
  }, [selectedSeason]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-2 pt-2 pb-2 shadow-lg max-w-[800px] mx-auto font-satoshi">
      <div className="overflow-x-auto mb-10">
        <table className="table-auto bg-white dark:bg-slate-300 font-satoshi max-w-full mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Q.
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider">
                Articles
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider">
                Prix Total
              </th>
            </tr>
          </thead>
          <tbody>
            {achatsGroupes.map((achat, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white text-sm "
              >
                <td className="px-5 py-3 dark:text-gray-800">
                  {achat.quantite}
                </td>
                <td className="px-2 py-3 dark:text-gray-800">
                  <div className="font-semibold">{achat.Article.nom}</div>
                  <div>{achat.Article.description}</div>
                </td>
                <td className="px-2 py-3 dark:text-gray-800">{`${achat.prix_total.toFixed(
                  2
                )} €`}</td>
              </tr>
            ))}
            {/* Ligne du prix global */}
            <tr className="bg-gray-800 text-white">
              <td
                colSpan="2"
                className="px-2 py-4 whitespace-nowrap text-sm font-bold"
              >
                Prix Global de tous les articles
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm font-bold">{`${prixGlobal.toFixed(
                2
              )} €`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableauAchatsGroupes;
