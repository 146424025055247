import React, { useState, useEffect } from "react";
import CreateAchatModal from "./createAchatModal";
import EditAchatModal from "./EditAchatModal";
import CreateSpecialAchatModal from "./CreateSpecialAchatModal"; // Import de la nouvelle modale
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import Button from "../../../components/Button/Button";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import {
  getReglements,
  deleteReglement,
} from "../../../services/achatsServices";
import { getSettings } from "../../../services/settingsServices";
import { getArticles } from "../../../services/articlesServices"; // Import de getArticles
import { FaCartPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Achats = () => {
  useScrollToTop();
  const [reglements, setReglements] = useState([]);
  const [filteredReglements, setFilteredReglements] = useState([]);
  const [articles, setArticles] = useState([]); // État pour les articles
  const [isModalActive, setIsModalActive] = useState(false);
  const [isSpecialModalActive, setIsSpecialModalActive] = useState(false); // État pour la nouvelle modale
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editableReglement, setEditableReglement] = useState(null);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [filterNom, setFilterNom] = useState("");
  const [filterArticle, setFilterArticle] = useState("");
  const [filterStatutReglement, setFilterStatutReglement] = useState("");
  const [filterTypeReglement, setFilterTypeReglement] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [activeSeason, setActiveSeason] = useState("");
  const [totalAchats, setTotalAchats] = useState(0); // État pour la somme totale des achats filtrés
  const [totalAchatsCount, setTotalAchatsCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reglementsData, settingsData, articlesData] = await Promise.all([
          getReglements(),
          getSettings(),
          getArticles(),
        ]);
        setReglements(reglementsData);
        setFilteredReglements(reglementsData);
        setArticles(articlesData);

        const uniqueSeasons = getUniqueSeasons(reglementsData);
        const activeSeason = settingsData[0]?.saison_active;
        setActiveSeason(activeSeason);

        if (activeSeason && !uniqueSeasons.includes(activeSeason)) {
          uniqueSeasons.push(activeSeason);
        }

        setSeasons(uniqueSeasons.sort());
        setSelectedSeason(activeSeason);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error.message);
        toast.error("Erreur lors du chargement des données.");
      }
    };
    fetchData();
  }, []);

  const getUniqueSeasons = (reglements) => {
    const seasonsSet = new Set();
    reglements.forEach((reglement) => {
      const date = new Date(reglement.date_achat);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      let season = "";
      if (month >= 8) {
        season = `${year}-${year + 1}`;
      } else {
        season = `${year - 1}-${year}`;
      }
      seasonsSet.add(season);
    });
    return Array.from(seasonsSet);
  };

  const handleSearchChange = (e) => {
    setFilterNom(e.target.value);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleFilterArticleChange = (e) => {
    setFilterArticle(e.target.value);
  };

  const handleFilterStatutReglementChange = (e) => {
    setFilterStatutReglement(e.target.value);
  };

  const handleFilterTypeReglementChange = (e) => {
    setFilterTypeReglement(e.target.value); // Gestion du changement du filtre type de règlement
  };

  const isWithinSeason = (date, season) => {
    const [startYear, endYear] = season.split("-").map(Number);
    const seasonStart = new Date(`${startYear}-08-01`);
    const seasonEnd = new Date(`${endYear}-07-31`);
    return new Date(date) >= seasonStart && new Date(date) <= seasonEnd;
  };

  useEffect(() => {
    const filterData = () => {
      const filteredData = reglements.filter((reglement) => {
        const matchesNom = reglement.User?.nom
          .toLowerCase()
          .includes(filterNom.toLowerCase());
        const matchesArticle = filterArticle
          ? reglement.Article?.nom === filterArticle ||
            (filterArticle === "Règlement Inscription" && !reglement.Article)
          : true;
        const matchesStatut = filterStatutReglement
          ? reglement.statut_reglement === filterStatutReglement
          : true;
        const matchesType = filterTypeReglement
          ? reglement.type_reglement === filterTypeReglement
          : true; // Nouveau filtre sur le type de règlement
        const matchesSeason =
          selectedSeason === "" ||
          isWithinSeason(reglement.date_achat, selectedSeason);

        return (
          matchesNom &&
          matchesArticle &&
          matchesStatut &&
          matchesType && // Appliquer le filtre de type
          matchesSeason
        );
      });
      setFilteredReglements(filteredData);

      // Calculer le nombre et la somme totale des achats filtrés
      const total = filteredData.reduce(
        (acc, curr) => acc + parseFloat(curr.prix_total),
        0
      );
      setTotalAchats(total.toFixed(2)); // Mettre à jour la somme totale
      setTotalAchatsCount(filteredData.length); // Mettre à jour le nombre d'achats
    };
    filterData();
  }, [
    filterNom,
    filterArticle,
    filterStatutReglement,
    filterTypeReglement, // Ajout du filtre type
    selectedSeason,
    reglements,
  ]);

  const refreshAchats = async () => {
    try {
      const data = await getReglements();
      setReglements(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteAchat = async (id) => {
    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à supprimer les achats."
          );
          return;
        }
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet achat ?")) {
      try {
        await deleteReglement(id);
        toast.success("Achat supprimé avec succès.");
        refreshAchats();
      } catch (error) {
        console.error(error.message);
        toast.error("Erreur lors de la suppression de l'achat.");
      }
    }
  };

  const openEditModal = (reglement) => {
    setEditableReglement(reglement);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Non Réglé":
        return "bg-red-500 text-white";
      case "Réglé":
        return "bg-green-500 text-white";
      case "En Attente":
        return "bg-yellow-300 text-black";
      case "Annulé":
        return "bg-black text-white";
      case "Remboursé":
        return "bg-purple-500 text-white";
      case "Partiellement Réglé":
        return "bg-green-200 text-black";
      default:
        return "bg-gray-200 text-black";
    }
  };

  const getPaymentPlanTag = (paymentPlan) => {
    switch (paymentPlan) {
      case "One-time":
        return "1x";
      case "Two-times":
        return "2x";
      case "Three-times":
        return "3x";
      case "Four-times":
        return "4x";
      case "Five-times":
        return "5x";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Achats Boutique & Réglement Inscription
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="w-full max-w-[1280px] mx-auto flex flex-col sm:flex-row justify-between items-center px-2 gap-4">
        <input
          type="text"
          placeholder="Filtrer par nom"
          className="input input-bordered flex-1 dark:bg-slate-200 dark:text-gray-800 mb-2 sm:mb-0"
          value={filterNom}
          onChange={handleSearchChange}
        />
        <select
          className="select select-bordered flex-1 dark:bg-slate-200 dark:text-gray-800 mb-2 sm:mb-0 w-full"
          value={filterArticle}
          onChange={handleFilterArticleChange}
        >
          <option value="">Filtrer par article</option>
          <option value="Règlement Inscription">Règlement Inscription</option>
          {articles.map((article) => (
            <option key={article.id} value={article.nom}>
              {article.nom}
            </option>
          ))}
        </select>
        <select
          className="select select-bordered flex-1 dark:bg-slate-200 dark:text-gray-800 mb-2 sm:mb-0 w-full sm:w-auto"
          value={filterStatutReglement}
          onChange={handleFilterStatutReglementChange}
        >
          <option value="">Filtrer par statut</option>
          <option value="Non Réglé">Non Réglé</option>
          <option value="Réglé">Réglé</option>
          <option value="En Attente">En Attente</option>
          <option value="Annulé">Annulé</option>
          <option value="Remboursé">Remboursé</option>
          <option value="Partiellement Réglé">Partiellement Réglé</option>
        </select>
        {/* Nouveau filtre par type de règlement */}
        <select
          className="select select-bordered flex-1 dark:bg-slate-200 dark:text-gray-800 mb-2 sm:mb-0 w-full sm:w-auto"
          value={filterTypeReglement}
          onChange={handleFilterTypeReglementChange}
        >
          <option value="">Sélectionnez un type de règlement</option>
          <option value="En ligne">En ligne</option>
          <option value="Chèque">Chèque</option>
          <option value="Espèces">Espèces</option>
          <option value="Virement">Virement</option>
          <option value="Autre">Autre</option>
        </select>
      </div>

      <div className="w-full max-w-[1280px] mx-auto flex flex-col sm:flex-row justify-between items-center px-2 gap-4 mb-2">
        <select
          className="select select-bordered flex-1 dark:bg-slate-200 dark:text-gray-800 mb-2 sm:mb-0 mt-4 min-w-4xl"
          value={selectedSeason}
          onChange={handleSeasonChange}
        >
          {seasons.map((season) => (
            <option key={season} value={season}>
              {season}
            </option>
          ))}
        </select>
        <div className="flex w-full gap-2">
          <Button
            text="Ajouter un achat"
            onClick={() => setIsModalActive(true)}
            icon={FaCartPlus}
            className="bg-primary text-white rounded-3xl shadow-sm hover:bg-white hover:text-primary border flex-1 items-center justify-center gap-2 sm:mb-0"
          />
          <Button
            text="Licence-Cotisation"
            onClick={() => setIsSpecialModalActive(true)}
            icon={FaCartPlus}
            className="bg-primary text-white rounded-3xl shadow-sm hover:bg-white hover:text-primary border flex-1 items-center justify-center gap-2"
          />
        </div>
      </div>

      {/* Affichage du nombre d'achats filtrés et de la somme totale */}
      <div className="w-full max-w-[1280px] mx-auto flex justify-start items-center px-2 gap-4 my-2">
        <p className="text-lg font-semibold">
          Total achats : {totalAchatsCount} | Somme totale : {totalAchats} €
        </p>
      </div>

      <CreateAchatModal
        isActive={isModalActive}
        onClose={() => setIsModalActive(false)}
        refreshAchats={refreshAchats}
      />

      <CreateSpecialAchatModal
        isActive={isSpecialModalActive}
        onClose={() => setIsSpecialModalActive(false)}
        refreshAchats={refreshAchats}
      />

      <EditAchatModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        reglement={editableReglement}
        refreshAchats={refreshAchats}
      />

      <div className="overflow-x-auto">
        <table className="table bg-white dark:bg-slate-300 font-satoshi max-w-[1280px] mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr className="border-collapse  border-2 border-slate-300">
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Nom Utilisateur
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Article
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Message Utilisateur
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Quantité
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Prix Total
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Statut Règlement
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Plan
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Type Règlement
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700 cursor-pointer">
            {filteredReglements.map((reglement) => (
              <tr
                key={reglement.id}
                className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white h-full"
              >
                <td>{new Date(reglement.date_achat).toLocaleDateString()}</td>
                <td>
                  {reglement.User?.nom} {reglement.User?.prenom}
                </td>
                <td>
                  {reglement.Article
                    ? reglement.Article.nom
                    : "Règlement Inscription"}
                </td>
                <td>{reglement.message_achat}</td>
                <td>{reglement.quantite}</td>
                <td>{parseFloat(reglement.prix_total).toFixed(2)}€</td>
                <td
                  className={`text-center ${getStatusColor(
                    reglement.statut_reglement
                  )}`}
                >
                  {reglement.statut_reglement}
                </td>
                <td>
                  {reglement.type_achat === "licence-assurance-cotisation" ? (
                    <span className="inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs bg-primary text-white">
                      {getPaymentPlanTag(reglement.payment_plan)}
                    </span>
                  ) : (
                    <span className="inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs bg-gray-200">
                      {/* Espace réservé pour aligner correctement les colonnes */}
                    </span>
                  )}
                </td>
                <td>{reglement.type_reglement}</td>
                <td className="flex gap-2 flex-row">
                  <ButtonEdit
                    onClick={() => openEditModal(reglement)}
                    hoverLabel="Modifier"
                  />
                  <ButtonDelete
                    onClick={() => handleDeleteAchat(reglement.id)}
                    hoverLabel="Supprimer"
                    className={`${
                      userRole === "visiteur"
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    disabled={userRole === "visiteur"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Achats;
