import React, { useState, useEffect } from "react";
import { getAllMessages, deleteMessage } from "./../../../services/chatServices";
import { getGroupsHome } from "./../../../services/groupServices";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { ToastContainer, toast } from "react-toastify";

const ChatPageAdmin = () => {
  useScrollToTop();
  const { user } = useUser();
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupColors, setGroupColors] = useState({});
  const [searchUser, setSearchUser] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const data = await getAllMessages();
        setMessages(data);
        setFilteredMessages(data);
      } catch (error) {
        toast.error("Erreur lors du chargement des messages.");
      }
    };

    const fetchGroups = async () => {
      try {
        const data = await getGroupsHome();
        setGroups(data);
        const colors = data.reduce((acc, group) => {
          acc[group.nom] = group.color_groupe;
          return acc;
        }, {});
        setGroupColors(colors);
      } catch (error) {
        toast.error("Erreur lors du chargement des groupes.");
      }
    };

    fetchMessages();
    fetchGroups();
  }, []);

  useEffect(() => {
    let filtered = messages;
    if (searchUser) {
      filtered = filtered.filter((message) =>
        `${message.Users.nom} ${message.Users.prenom}`
          .toLowerCase()
          .includes(searchUser.toLowerCase())
      );
    }
    if (selectedGroup) {
      filtered = filtered.filter((message) => message.group.nom === selectedGroup);
    }
    setFilteredMessages(filtered);
  }, [searchUser, selectedGroup, messages]);

  const handleDeleteMessage = async (messageId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
      try {
        await deleteMessage(messageId);
        setMessages(messages.filter((message) => message.id !== messageId));
        toast.success("Message supprimé avec succès.");
      } catch (error) {
        toast.error("Erreur lors de la suppression du message.");
      }
    }
  };

  if (!user) {
    return <div>Chargement...</div>;
  }

  const userRole = getRoleFromRoleId(user.role_id);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Chat Admin
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="max-w-[1280px] mx-auto flex flex-col items-center justify-center gap-4 mt-4 w-full">
        <input
          type="text"
          placeholder="Rechercher par nom"
          value={searchUser}
          onChange={(e) => setSearchUser(e.target.value)}
          className="input input-bordered w-full dark:bg-slate-200 dark:text-gray-800"
        />
        <select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          className="select select-bordered w-full dark:bg-slate-200 dark:text-gray-800"
        >
          <option value="">Sélectionner un groupe</option>
          {groups.map((group) => (
            <option key={group.id} value={group.nom}>
              {group.nom}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto shadow-xl w-full max-w-[1280px] mx-auto mt-8">
        <table className="table-auto w-full bg-white dark:bg-slate-300 font-satoshi border-collapse border-2 border-slate-300 mb-4">
          <thead className="bg-gray-200 dark:bg-gray-700 text-white dark:text-white">
            <tr className="border-collapse border-2 border-slate-300">
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider dark:text-white" style={{ width: "80%" }}>
                Détails
              </th>
              <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider dark:text-white" style={{ width: "20%" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-600 divide-y divide-gray-200">
            {filteredMessages.map((message) => (
              <tr key={message.id} className="border-collapse border-2 border-slate-300">
                <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                  <div className={`inline-block px-2 py-1 rounded-full text-white text-xs ${groupColors[message.group.nom] || "bg-gray-500"}`}>
                    {message.group.nom}
                  </div>
                  <div className="mt-2">{message.Users.nom} {message.Users.prenom}</div>
                  <div className="mt-2 text-xs italic text-wrap">{message.message}</div>
                </td>
                <td className="px-2 py-4 whitespace-nowrap text-sm text-right">
                  <div className="flex justify-end">
                    <ButtonDelete
                      onClick={() => handleDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChatPageAdmin;
