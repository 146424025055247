import React, { useEffect, useState } from "react";
import EditDossierInscriptionModal from "./EditDossierInscriptionModal";
import CreateDossierInscriptionModal from "./CreateDossierInscriptionModal";
import formatDate from "./../../../utils/formatDate";
import { GoFileSymlinkFile } from "react-icons/go";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { FaPlusSquare } from "react-icons/fa";
import {
  getAllDossiersInscription,
  deleteDossierInscription,
} from "./../../../services/dossierServices";
import { getSettings } from "./../../../services/settingsServices";
import { getGroupsList } from "../../../services/groupServices";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";

import "./DossierInscriptionPage.css";

function DossierInscriptionPage() {
  useScrollToTop();
  const [dossiers, setDossiers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editableDossier, setEditableDossier] = useState(null);
  const [validateCmFilter, setValidateCmFilter] = useState("");
  const [validateLicenceFilter, setValidateLicenceFilter] = useState("");
  const [validateReglementFilter, setValidateReglementFilter] = useState("");
  const [seasonFilter, setSeasonFilter] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [duplicateWarning, setDuplicateWarning] = useState(null);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  useEffect(() => {
    fetchDossiers();
    fetchSettings();
    fetchGroupsData();
  }, []);

  const fetchDossiers = async () => {
    try {
      const data = await getAllDossiersInscription();
      setDossiers(data);
      checkForDuplicates(data);
    } catch (error) {
      console.error(
        "Erreur lors du chargement des dossiers d'inscription:",
        error
      );
      toast.error(
        "Une erreur est survenue lors du chargement des dossiers d'inscription."
      );
    }
  };

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setSeasonFilter(activeSeason); // Définir le filtre saison sur la saison active
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const fetchGroupsData = async () => {
    try {
      const groupsList = await getGroupsList();
      setGroups(groupsList);
    } catch (error) {
      console.error("Erreur lors du chargement des groupes:", error);
    }
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const getFilteredDossiers = () => {
    return dossiers.filter((dossier) => {
      const matchAdherent =
        dossier.Adherent.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dossier.Adherent.prenom
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      const matchValidateCm = validateCmFilter
        ? dossier.validate_cm.toString() === validateCmFilter
        : true;
      const matchValidateLicence = validateLicenceFilter
        ? dossier.validate_licence.toString() === validateLicenceFilter
        : true;
      const matchValidateReglement = validateReglementFilter
        ? dossier.validate_reglement.toString() === validateReglementFilter
        : true;
      const matchSeason = seasonFilter
        ? dossier.Adherent.saison_active === seasonFilter
        : true;
      const matchGroup =
        selectedGroup === "" ||
        (dossier.Adherent.Groups &&
          dossier.Adherent.Groups.some(
            (group) => group.id.toString() === selectedGroup
          ));

      return (
        matchAdherent &&
        matchValidateCm &&
        matchValidateLicence &&
        matchValidateReglement &&
        matchSeason &&
        matchGroup
      );
    });
  };

  // Vérifie les doublons dans les noms et prénoms des adhérents
  const checkForDuplicates = (data) => {
    const names = data.map(
      (dossier) =>
        `${dossier.Adherent.nom.toLowerCase()} ${dossier.Adherent.prenom.toLowerCase()}`
    );
    const duplicates = names.filter(
      (name, index) => names.indexOf(name) !== index
    );
    if (duplicates.length > 0) {
      setDuplicateWarning(
        `Attention, il existe un doublon dans ce tableau : ${duplicates.join(
          ", "
        )}`
      );
    } else {
      setDuplicateWarning(null);
    }
  };

  // Handlers pour les champs de filtre
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const openEditModal = (dossier) => {
    setEditableDossier(dossier);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const deleteDossier = async (id) => {
    if (userRole === "visiteur") {
          toast.error(
            "Les visiteurs ne sont pas autorisés à supprimer les dossiers d'inscription."
          );
          return;
        }
    try {
      await deleteDossierInscription(id);
      fetchDossiers();
      toast.success("Dossier supprimé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression du dossier", error);
      toast.error("Erreur lors de la suppression du dossier.");
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Dossiers d'inscription
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4">
        <div className="mb-4 w-full md:w-auto">
          <div className="w-full md:w-auto font-satoshi ">
            {/* Boutons et champs de filtre ici */}
            {duplicateWarning && (
              <div className="bg-red-500 text-white p-4 mb-4 rounded">
                {duplicateWarning}
              </div>
            )}
            <div className="mb-4 w-full md:w-auto">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white font-satoshi">
                Recherche
              </label>
              <input
                type="text"
                className="input input-bordered w-full"
                placeholder="Rechercher par nom d'adhérent..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className="flex flex-col md:flex-row font-satoshi gap-4">
              <div className="form-control w-full">
                <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                  CM valide
                </label>
                <select
                  className="select select-bordered w-full dark:text-black"
                  value={validateCmFilter}
                  onChange={(e) => setValidateCmFilter(e.target.value)}
                >
                  <option value="">Tous</option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </select>
              </div>

              <div className="form-control w-full">
                <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                  Licence valide
                </label>
                <select
                  className="select select-bordered w-full dark:text-black"
                  value={validateLicenceFilter}
                  onChange={(e) => setValidateLicenceFilter(e.target.value)}
                >
                  <option value="">Tous</option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </select>
              </div>

              <div className="form-control w-full">
                <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                  Règlement valide
                </label>
                <select
                  className="select select-bordered w-full dark:text-black"
                  value={validateReglementFilter}
                  onChange={(e) => setValidateReglementFilter(e.target.value)}
                >
                  <option value="">Tous</option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </select>
              </div>

              <div className="form-control w-full">
                <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                  Saison active
                </label>
                <select
                  className="select select-bordered w-full dark:text-black"
                  value={seasonFilter}
                  onChange={(e) => setSeasonFilter(e.target.value)}
                >
                  <option value="">Tous</option>
                  {seasons.map((season) => (
                    <option key={season} value={season}>
                      {season}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-control w-full">
                <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                  Groupe
                </label>
                <select
                  className="select select-bordered w-full dark:text-black"
                  value={selectedGroup}
                  onChange={(e) => setSelectedGroup(e.target.value)}
                >
                  <option value="">Tous les groupes</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.nom}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <Button
              label="Ajouter un dossier"
              text="Ajouter un dossier d'inscription"
              onClick={() => setIsCreateModalOpen(true)}
              icon={FaPlusSquare}
              className="mb-8 mt-4 align-right gap-2"
              hoverLabel="Ajouter un dossier d'inscription"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-white">
              Nombre d'adhérents affichés : {getFilteredDossiers().length}
            </span>
          </div>

          {/* Tableau des dossiers d'inscription */}
          <div className="overflow-x-auto table-scrollable">
            <table className="table bg-white dark:bg-slate-300 font-satoshi max-w-[1024px] mx-auto border-collapse border-2 border-slate-300">
              <thead className="bg-gray-800 text-white">
                <tr className="border-collapse  border-2 border-slate-300">
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider sticky-col">
                    Adhérent
                    <br />
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Lien CM
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Date CM
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    CM
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Form.
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Form. Signé
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Licence
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    LIC
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    REG
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Drive
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Groupe
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-800 dark:text-gray-200  cursor-pointer">
                {getFilteredDossiers().map((dossier) => (
                  <tr
                    key={dossier.id}
                    className="hover:bg-gray-100 dark:hover:bg-slate-600 border-b border-gray-200 dark:border-slate-700"
                  >
                    <td className="px-4 py-3 text-sm">
                      {dossier.Adherent.nom}
                      <br />
                      {dossier.Adherent.prenom}
                    </td>
                    <td className="px-2 py-3">
                      {dossier.cm_url && (
                        <a
                          href={dossier.cm_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 dark:text-blue-300"
                        >
                          <GoFileSymlinkFile />
                        </a>
                      )}
                    </td>
                    <td className="px-2 py-3 text-sm">
                      {formatDate(dossier.date_cm)}
                    </td>
                    <td className="px-2 py-3">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ${
                          dossier.validate_cm
                            ? "bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100"
                            : "bg-red-100 text-red-800 dark:bg-red-700 dark:text-red-100"
                        }`}
                      >
                        {dossier.validate_cm ? "Oui" : "Non"}
                      </span>
                    </td>
                    <td className="px-2 py-3">
                      {dossier.formulaire_licence_a_signer && (
                        <a
                          href={dossier.formulaire_licence_a_signer}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 dark:text-blue-300"
                        >
                          <GoFileSymlinkFile />
                        </a>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      {dossier.formulaire_licence_signe && (
                        <a
                          href={dossier.formulaire_licence_signe}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 dark:text-blue-300"
                        >
                          <GoFileSymlinkFile />
                        </a>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      {dossier.licence_url && (
                        <a
                          href={dossier.licence_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 dark:text-blue-300"
                        >
                          <GoFileSymlinkFile />
                        </a>
                      )}
                    </td>
                    <td className="px-2 py-3">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ${
                          dossier.validate_licence
                            ? "bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100"
                            : "bg-red-100 text-red-800 dark:bg-red-700 dark:text-red-100"
                        }`}
                      >
                        {dossier.validate_licence ? "Oui" : "Non"}
                      </span>
                    </td>
                    <td className="px-2 py-3">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm ${
                          dossier.validate_reglement
                            ? "bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100"
                            : "bg-red-100 text-red-800 dark:bg-red-700 dark:text-red-100"
                        }`}
                      >
                        {dossier.validate_reglement ? "Oui" : "Non"}
                      </span>
                    </td>
                    <td className="px-2 py-3">
                      {dossier.lien_drive && (
                        <a
                          href={dossier.lien_drive}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 dark:text-blue-300"
                        >
                          <GoFileSymlinkFile />
                        </a>
                      )}
                    </td>
                    <td className="px-2 py-3 text-xs">
                      {dossier.Adherent.Groups &&
                      dossier.Adherent.Groups.length > 0
                        ? dossier.Adherent.Groups.map(
                            (group) => group.nom
                          ).join(", ")
                        : "N/A"}
                    </td>
                    <td className="px-2 py-3 flex gap-2">
                      <ButtonEdit
                        onClick={() => openEditModal(dossier)}
                        className="mr-2"
                      />
                      <ButtonDelete onClick={() => deleteDossier(dossier.id)} className={`${
                          userRole === "visiteur"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        disabled={userRole === "visiteur"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modales pour la création et l'édition */}
        <EditDossierInscriptionModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          dossier={editableDossier}
          refreshList={fetchDossiers}
        />

        <CreateDossierInscriptionModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshList={fetchDossiers}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default DossierInscriptionPage;
