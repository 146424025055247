import React, { useEffect, useState, useRef } from "react";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import { getAdherentsByUser } from "../../../services/adherentServices";
import AdherentDetails from "./AdherentDetails";
import AdherentGroupCategory from "./AdherentGroupCategory";
import AdherentPassageCeinture from "./AdherentPassageCeinture";
import AdherentPalmares from "./AdherentPalmares";
import AdherentGradeCN from "./AdherentGradeCN";
import useScrollToTop from "../../../hooks/useScollToTop";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function FicheAdherent() {
  useScrollToTop();
  const [adherents, setAdherents] = useState([]);
  const [activeAdherentId, setActiveAdherentId] = useState(null);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const swiperRef = useRef(null);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const adherents = await getAdherentsByUser();
        setAdherents(adherents);
        if (adherents.length > 0) {
          setActiveAdherentId(adherents[0].id);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents:", error);
        toast.error(
          "Une erreur est survenue lors de la récupération des adhérents."
        );
      }
    };

    fetchAdherents();
  }, []);

  const handleSlideChange = (swiper) => {
    const newAdherents = adherents.map((adherent, idx) => ({
      ...adherent,
      isActive: idx === swiper.activeIndex,
    }));
    setAdherents(newAdherents);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Fiche Adhérent
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="overflow-x-auto ">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          className="mx-auto cursor-pointer"
          slidesPerView="auto"
          centeredSlides={true}
          spaceBetween={30}
          navigation={true}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={handleSlideChange}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
              width: 500,
            },
            768: {
              slidesPerView: 1.2,
              spaceBetween: 20,
              width: 1024,
            },
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 20,
              width: 1024,
            },
          }}
        >
          {adherents.map((adherent) => (
            <SwiperSlide
              key={adherent.id}
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <AdherentDetails adherentId={adherent.id} />
              <AdherentGroupCategory adherentId={adherent.id} />
              <AdherentPassageCeinture adherentId={adherent.id} />
              <AdherentGradeCN adherentId={adherent.id} />
              <AdherentPalmares adherentId={adherent.id} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FicheAdherent;
