import React, { useState, useEffect } from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import { LuMessageSquarePlus } from "react-icons/lu";
import Button from "./../../../components/Button/Button";
import ButtonEyeNoRead from "../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import {
  getMessagesByUser,
  deleteMessageById,
  markMessageAsRead,
  markAllMessagesAsRead,
} from "./../../../services/messageServices";
import { useMessage } from "./../../../hooks/MessageContext";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./styles.css";

const MessagesDisplay = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const { unreadMessageCount, setUnreadMessageCount } = useMessage();
  const navigate = useNavigate();

  const loadMessages = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getMessagesByUser(userId);
      const filteredMessages = data.filter((message) => !message.is_copy);
      setMessages(filteredMessages);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
      toast.error("Erreur lors du chargement des messages.");
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      loadMessages();
      toast.success("Message supprimé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression du message:", error);
      toast.error("Erreur lors de la suppression du message.");
    }
  };

  const toggleReadStatus = async (messageId) => {
    try {
      const message = messages.find((m) => m.id === messageId);
      if (!message) {
        console.error("Message non trouvé");
        return;
      }

      const currentReadStatus = message.read_message;

      await markMessageAsRead(messageId);

      setMessages((prevMessages) =>
        prevMessages.map((m) =>
          m.id === messageId ? { ...m, read_message: !m.read_message } : m
        )
      );

      setUnreadMessageCount((prevCount) =>
        currentReadStatus ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllMessagesAsRead(userId);
      setMessages(messages.map((m) => ({ ...m, read_message: true })));
      setUnreadMessageCount(0);
    } catch (error) {
      console.error(
        "Erreur lors du marquage de tous les messages comme lus:",
        error
      );
    }
  };

  const navigateToMessages = () => {
    navigate("/messages");
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="overflow-x-auto shadow-xl font-satoshi">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2">
          <div className="relative">
            <FaEnvelope
              className={`icon ${unreadMessageCount > 0 ? "" : ""}`}
              title="Messages Reçus"
            />
            {unreadMessageCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
                {unreadMessageCount}
              </span>
            )}
          </div>
          Messages Reçus
        </h3>
        <div className="flex">
          <Button
            onClick={handleMarkAllAsRead}
            icon={RiCheckDoubleFill}
            className="mb-4 align-right"
            hoverLabel="Tout Marquer Comme Lu"
          />
          <Button
            label="Voir Messages"
            aria-label="Voir Messages"
            onClick={navigateToMessages}
            icon={LuMessageSquarePlus}
            className="mb-4 ml-2 align-right"
            hoverLabel="Ajouter un message"
          />
        </div>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-50 divide-y divide-gray-400">
              <thead className="bg-gray-50">
                <tr className="bg-slate-400">
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {messages.map((message) => (
                  <tr
                    key={message.id}
                    className={`hover:bg-gray-300 ${
                      message.read_message ? "bg-gray-200" : "bg-gray-100"
                    }`}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
                      {new Date(message.created_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="font-semibold">{`${message.Sender.nom} ${message.Sender.prenom}`}</div>
                      <div className="text-sm text-blue-800 font-bold">
                        {message.subject}
                      </div>
                      <div
                        className="text-sm"
                        dangerouslySetInnerHTML={{ __html: message.content }}
                      ></div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2 flex">
                      {message.read_message ? (
                        <ButtonEyeRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme non lu"
                        />
                      ) : (
                        <ButtonEyeNoRead
                          onClick={() => toggleReadStatus(message.id)}
                          hoverLabel="Marquer comme lu"
                        />
                      )}
                      <ButtonDelete
                        onClick={() => handleDeleteMessage(message.id)}
                        hoverLabel="Supprimer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`shadow-md rounded-lg p-4 mb-4 ${
                  message.read_message ? "bg-gray-200" : "bg-white"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-900">
                    {new Date(message.created_at).toLocaleDateString("fr-FR", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                  <div className="flex space-x-2">
                    {message.read_message ? (
                      <ButtonEyeRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme non lu"
                      />
                    ) : (
                      <ButtonEyeNoRead
                        onClick={() => toggleReadStatus(message.id)}
                        hoverLabel="Marquer comme lu"
                      />
                    )}
                    <ButtonDelete
                      onClick={() => handleDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                    />
                  </div>
                </div>
                <div className="text-sm text-gray-500 mb-2">
                  <div className="font-semibold">{`${message.Sender.nom} ${message.Sender.prenom}`}</div>
                  <div className="text-sm text-blue-800 font-bold">
                    {message.subject}
                  </div>
                  <div
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-white dark:text-gray-800">
          Aucuns messages.
        </div>
      )}
    </div>
  );
};

export default MessagesDisplay;
