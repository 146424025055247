import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosMenu } from "react-icons/io"; // Importer les icônes pour le menu hamburger
import "./LinkNav.css";
import MegaMenu from "./MegaMenu";
import LogoSection from "./LogoSection"; // Assurez-vous que le chemin est correct
import Modal from "./../../../hooks/Modal"; // Importer le composant Modal pour les alertes
import { getSettings } from "../../../services/settingsServices";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

import {
  FaFutbol, FaFootballBall, FaTableTennis, FaBicycle, FaRunning, FaSwimmer, FaBasketballBall, FaVolleyballBall, FaGolfBall, FaHockeyPuck, FaMountain, FaSkiing
} from "react-icons/fa";
import {
  GiKimono, GiFencer, GiTennisRacket, GiHorseHead, GiHighKick, GiBoxingGlove
} from 'react-icons/gi';
import {
  MdOutlineSportsHandball, MdRowing, MdOutlineSportsMartialArts, MdSportsKabaddi
} from "react-icons/md";

const icons = {
  FaFutbol, FaFootballBall, FaTableTennis, FaBicycle, FaRunning, FaSwimmer,
  FaBasketballBall, MdOutlineSportsHandball, GiKimono, GiFencer, GiTennisRacket,
  GiBoxingGlove, FaVolleyballBall, GiHorseHead, FaGolfBall, FaHockeyPuck,
  GiHighKick, FaMountain, MdRowing, FaSkiing, MdOutlineSportsMartialArts, MdSportsKabaddi
};

const LinkNav = ({ user }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState({
    display_boutique: true,
    display_chat: true,
    display_news: true,
    display_galerie: true,
    display_lexique: true,
    display_techniques: true,
    display_appels: true,
    display_todolist: true,
    display_coaching: true,
    display_heures_enseignants: true,
    display_inventaire: true,
    display_chat_admin: true,
    display_messages_admin: true,
    adresse_club: "",
    tel_club: "",
    mail_club: "",
    facebook_club: "",
    instagram_club: "",
  });
  const [IconComponent, setIconComponent] = useState(() => GiKimono);
  const megaMenuRef = useRef(null); // Référence pour MegaMenu
  const menuRef = useRef(null); // Référence pour MegaMenu

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
        const iconName = data[0].discipline_icone;
        if (iconName && icons[iconName]) {
          setIconComponent(() => icons[iconName]);
        }
      }
    };

    fetchSettings();
  }, []);

  // Gestion des clics extérieurs
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
        setShowMegaMenu(false); // Ferme MegaMenu uniquement si clic à l'extérieur
      }
    };

    if (showMegaMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMegaMenu]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showMegaMenu) setShowMegaMenu(false); // Ferme MegaMenu s'il est ouvert
  };

  // Fonction pour ouvrir ou fermer le MegaMenu
  const toggleMegaMenu = () => {
    setShowMegaMenu(!showMegaMenu);
  };

  // Fonction pour fermer le MegaMenu
  const closeMegaMenu = () => {
    setShowMegaMenu(false);
  };

  

  // Fonction pour gérer le défilement vers les ancres
  const handleNavigate = (id) => {
    if (id === "accueil") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    toggleDropdown(); // Ferme le menu dropdown après la navigation
  };

  const handleInscription = (event) => {
    if (!user) {
      event.preventDefault(); // Empêche la navigation
      setShowModal(true); // Montre la modale si l'utilisateur n'est pas connecté
    } else {
      toggleDropdown(); // Assure que le menu est fermé avant de naviguer
      navigate("/ajouter-inscription-adherent"); // Navigue vers la page d'inscription
    }
  };

  return (
    <div
      className={`flex h-15 ${
        window.innerWidth < 400 ? "h-15" : ""
      } bg-white bg-opacity-85 text-black py-2 shadow-md items-center justify-between w-full gap-2 font-satoshi`}
    >
      <div className="flex items-center ml-2">
        <NavLink to="/">
          <LogoSection />
        </NavLink>
        <div className="lg:hidden ml-4">
          <button
            onClick={toggleDropdown}
            aria-label="Menu"
            title="Menu"
            className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center w-sm h-10 gap-2"
          >
            <IoIosMenu className="text-xl" />
            {window.innerWidth >= 400 && "Menu"}
          </button>
        </div>
      </div>

      {showDropdown && (
        <div className="dropdown-content backdrop-filter backdrop-blur-sm shadow absolute top-full left-0 py-2 z-20 w-full lg:hidden bg-white/50">
          <div className="flex flex-col items-center font-satoshi">
            {["accueil", "cours", "contact"].map((section) => (
              <NavLink
                key={section}
                to="/"
                state={{ scrollTo: section }}
                onClick={() => {
                  handleNavigate(section);
                }}
                className="block w-full px-4 py-3 text-xl hover:bg-primary hover:text-white text-black link-nav2 text-center"
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}{" "}
                {/* Capitalize first letter */}
              </NavLink>
            ))}
            <button
              onClick={handleInscription}
              className="block w-full px-4 py-3 text-xl hover:bg-primary hover:text-white text-black link-nav2 text-center"
            >
              Inscriptions
            </button>
            <div className="flex flex-col items-center mt-4">
              <a href={`mailto:${settings.mail_club}`} className="text-black hover:text-primary">
                {settings.mail_club}
              </a>
              <p className="text-black">{settings.tel_club}</p>
              <div className="flex space-x-4 mt-2">
                <a href={settings.facebook_club} target="_blank" rel="noopener noreferrer" className="text-black hover:text-primary">
                  <FaFacebookF />
                </a>
                <a href={settings.instagram_club} target="_blank" rel="noopener noreferrer" className="text-black hover:text-primary">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden lg:flex space-x-6 grow justify-center mt-2 font-satoshi">
        {["accueil", "cours", "contact", "inscription"].map((section) => (
          <NavLink
            key={section}
            to={
              user && section === "inscription"
                ? "/ajouter-inscription-adherent"
                : "/"
            }
            state={section === "inscription" ? {} : { scrollTo: section }}
            onClick={(e) =>
              section === "inscription"
                ? handleInscription(e)
                : handleNavigate(section)
            }
            className="link-nav2 uppercase font-satoshi font-medium hover:text-orange-600 text-black"
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}{" "}
            {/* Capitalize first letter */}
          </NavLink>
        ))}
      </div>

      {/* Bouton "Application" visible même pour les visiteurs */}
        <div className="relative lg:flex items-center mr-2">
          <button
            onClick={toggleMegaMenu}
            aria-label="Application"
            title="Application"
            className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center w-sm h-10 gap-2"
          >
            <IconComponent className="text-xl" />
            {window.innerWidth >= 400 && "Application"}
          </button>
          {showMegaMenu && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-start">
              <div
                ref={megaMenuRef} // Associe le ref au MegaMenu
                className="absolute top-32 right-0 left-0 mx-auto w-full max-w-[1280px] px-4 shadow-xl"
              >
                <MegaMenu
                  role_id={user?.role_id || 4}
                  settings={settings}
                  closeMegaMenu={closeMegaMenu}
                />
              </div>
            </div>
          )}
        </div>
      
      {showModal && (
        <Modal title="Inscription Adhérent" onClose={() => setShowModal(false)}>
          <div className="text-center space-y-4">
            <p>
              Merci de vous connecter à votre compte ou de vous inscrire pour faire une adhésion.
            </p>
            <div className="flex justify-around p-4">
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                  navigate("/login");
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                Se connecter
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                  navigate("/register");
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                S'inscrire
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LinkNav;
