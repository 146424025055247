import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import {
  getArticles,
  deleteArticle,
  activateArticle,
  updateOrderArticle,
  updateArticleImage
} from "./../../../services/articlesServices";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import Button from "./../../../components/Button/Button";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import AddArticleModal from "./AddArticleModal";
import EditArticleModal from "./EditArticleModal";
import { FaSave, FaTimes } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

const ArticlesAdmin = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [articles, setArticles] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [editableArticle, setEditableArticle] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const data = await getArticles();
      setArticles(data);
    } catch (error) {
      console.error("Erreur lors du chargement des articles:", error);
      toast.error("Erreur lors du chargement des articles");
    }
  };

  const handleDeleteArticle = async (articleId) => {
    try {
      await deleteArticle(articleId);
      fetchArticles();
      toast.success("Article supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'article:", error);
      toast.error("Erreur lors de la suppression de l'article");
    }
  };

  const toggleActiveStatus = async (articleId) => {
    try {
      await activateArticle(articleId);
      toast.success("Statut de l'article mis à jour avec succès");
      fetchArticles();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du statut de l'article");
      console.error("Erreur lors de la mise à jour du statut de l'article", error);
    }
  };

  const moveArticleUp = async (index) => {
    if (index === 0) return;
    const newArticles = [...articles];
    const temp = newArticles[index - 1];
    newArticles[index - 1] = newArticles[index];
    newArticles[index] = temp;
    newArticles[index - 1].ordre_article--;
    newArticles[index].ordre_article++;
    setArticles(newArticles);
    await updateOrderArticle(newArticles);
    fetchArticles();
  };

  const moveArticleDown = async (index) => {
    if (index === articles.length - 1) return;
    const newArticles = [...articles];
    const temp = newArticles[index + 1];
    newArticles[index + 1] = newArticles[index];
    newArticles[index] = temp;
    newArticles[index + 1].ordre_article++;
    newArticles[index].ordre_article--;
    setArticles(newArticles);
    await updateOrderArticle(newArticles);
    fetchArticles();
  };

  const openEditModal = (article) => {
    setEditableArticle(article);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditableArticle(null);
    setIsEditModalOpen(false);
  };

  const openUploadModal = (article) => {
    setEditableArticle(article);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setEditableArticle(null);
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateArticleImage(editableArticle.id, selectedFile, token);
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === editableArticle.id ? { ...article, photo_url: imageUrl } : article
        )
      );
      toast.success("Image de l'article mise à jour avec succès!");
      closeUploadModal(); // Fermer la modale après le succès
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
    fetchArticles();
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-black">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-black mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-black mb-4 font-satoshi uppercase">
        Articles Administrateur
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex justify-center mb-4">
        <Button text="Ajouter un Article" onClick={() => setIsAddModalOpen(true)} className={`${
                          userRole === "visiteur"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        disabled={userRole === "visiteur"}/>
      </div>

      <div className="hidden md:block overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-satoshi w-full mx-auto border-collapse border-2 border-slate-300 max-w-[1280px]">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Ordre
              </th>
              <th className="px-4 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Photo
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Nom
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Description
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Prix
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Taille
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Stock
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Catégorie
              </th>
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Boutique
              </th>
              
              <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {articles.map((article, index) => (
              <tr key={article.id} className="hover:bg-gray-100">
                <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                  {article.ordre_article}
                  <div className="flex flex-col ml-2">
                    <button
                      onClick={() => moveArticleUp(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleFilled />
                    </button>
                    <button
                      onClick={() => moveArticleDown(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleInvertedFilled />
                    </button>
                  </div>
                </td>
                <td className="px-5 py-3 text-center">
                  {article.photo_url && (
                    <img
                      src={article.photo_url}
                      alt={article.nom}
                      className="w-full h-auto object-cover mx-auto min-w-[150px]"
                    />
                  )}
                  <p className="text-xs mt-2 cursor-pointer" onClick={() => openUploadModal(article)}>Modifier Image</p>
                </td>
                <td className="font-bold">{article.nom}</td>
                <td className="text-sm">{article.description}</td>
                <td className="font-bold text-center">{article.prix}€</td>
                <td className="text-sm">{article.taille}</td>
                <td className="text-sm">{article.quantite_stock}</td>
                <td>{article.categorie}</td>
                <td className="text-center">
                  <button onClick={() => toggleActiveStatus(article.id)}>
                    {article.is_active ? "✅" : "❌"}
                  </button>
                </td>
                
                <td className="flex justify-center gap-2">
                  <ButtonEdit onClick={() => openEditModal(article)} />
                  <ButtonDelete onClick={() => handleDeleteArticle(article.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="block md:hidden">
        {articles.map((article, index) => (
          <div key={article.id} className="bg-white dark:bg-slate-300 p-4 rounded-lg shadow-lg mb-4">
            <div className="flex justify-between items-center">
              <div className="flex-1">
                <h3 className="font-bold text-lg">{article.nom}</h3>
                <p className="text-sm">{article.description}</p>
                <p className="font-bold">{article.prix}€</p>
                <p className="text-sm">Taille: {article.taille}</p>
                <p className="text-sm">Stock: {article.quantite_stock}</p>
                <p className="text-sm">Catégorie: {article.categorie}</p>
                <p className="text-sm">
                  Boutique:{" "}
                  <button onClick={() => toggleActiveStatus(article.id)}>
                    {article.is_active ? "✅" : "❌"}
                  </button>
                </p>
                <div className="flex mt-2">
                  <button
                    onClick={() => moveArticleUp(index)}
                    className="text-blue-400 hover:text-blue-900"
                  >
                    <TbTriangleFilled />
                  </button>
                  <button
                    onClick={() => moveArticleDown(index)}
                    className="text-blue-400 hover:text-blue-900"
                  >
                    <TbTriangleInvertedFilled />
                  </button>
                </div>
                <div className="flex justify-between mt-4">
                  <ButtonEdit onClick={() => openEditModal(article)} />
                  <ButtonDelete onClick={() => handleDeleteArticle(article.id)} />
                </div>
              </div>
              <div className="flex flex-col items-center ml-4">
                {article.photo_url && (
                  <img
                    src={article.photo_url}
                    alt={article.nom}
                    className="w-36 h-48 object-cover mb-2"
                  />
                )}
                <p className="text-xs mt-2 cursor-pointer" onClick={() => openUploadModal(article)}>Modifier Image</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer l'image de {editableArticle.nom}
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  icon={FaSave}
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={closeUploadModal}
                  icon={FaTimes}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}

      <AddArticleModal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} refreshList={fetchArticles} />
      <EditArticleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} article={editableArticle} refreshList={fetchArticles} />

      <ToastContainer />
    </div>
  );
};

export default ArticlesAdmin;
