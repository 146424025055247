import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditReglementInscriptionModal from "./EditReglementInscriptionModal";
import CreateReglementInscriptionModal from "./CreateReglementInscriptionModal";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import {
  getAllReglementInscription,
  deleteReglementInscription,
} from "../../../services/reglementinscriptionServices";
import { getSettings } from "../../../services/settingsServices"; // Importer getSettings
import { getGroupsList } from "../../../services/groupServices"; // Importer getGroupsList
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import ButtonCBplus from "./../../../components/Button/ButtonCBplus";
import { FaPlusSquare, FaCreditCard } from "react-icons/fa";
import "./ReglementInscriptionPage.css";
import { ToastContainer, toast } from "react-toastify";

function ReglementInscriptionPage() {
  useScrollToTop();
  const [reglements, setReglements] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editableReglement, setEditableReglement] = useState({});

  // Ajoute des états pour les valeurs des filtres
  const [saisonFilter, setSaisonFilter] = useState("");
  const [passSportFilter, setPassSportFilter] = useState("");
  const [statutReglementFilter, setStatutReglementFilter] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [seasons, setSeasons] = useState([]); // État pour les saisons
  const [selectedSeason, setSelectedSeason] = useState(""); // État pour la saison sélectionnée
  const [groups, setGroups] = useState([]); // État pour les groupes
  const [selectedGroup, setSelectedGroup] = useState(""); // État pour le groupe sélectionné

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const navigate = useNavigate();

  const calculateTotal = (reglement) => {
    const {
      montant_lic_assurance,
      montant_cotisation,
      reduction_foyer,
      pass_sport,
      reduction_saison,
    } = reglement;
    return (
      parseFloat(montant_lic_assurance) +
      parseFloat(montant_cotisation) +
      parseFloat(reduction_foyer) +
      (parseFloat(pass_sport) || 0) + // Pass sport peut être nul
      parseFloat(reduction_saison)
    ).toFixed(2); // Fixer à 2 décimales
  };

  // Fonction pour calculer la somme totale des règlements filtrés
  const calculateTotalSum = () => {
    return getFilteredReglements()
      .reduce(
        (acc, reglement) => acc + parseFloat(calculateTotal(reglement)),
        0
      )
      .toFixed(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllReglementInscription();
        setReglements(data); // Utiliser directement 'data' si getAllReglementInscription retourne 'response.data'
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setReglements([]); // Gérer l'état en cas d'erreur
      }
    };

    fetchData();
    fetchSettingsData(); // Appeler fetchSettingsData pour récupérer les saisons
    fetchGroupsData(); // Appeler fetchGroupsData pour récupérer les groupes
  }, []);

  const fetchSettingsData = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setSelectedSeason(activeSeason);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const fetchGroupsData = async () => {
    try {
      const groupsList = await getGroupsList();
      setGroups(groupsList);
    } catch (error) {
      console.error("Erreur lors du chargement des groupes:", error);
    }
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSaisonFilterChange = (e) => {
    setSelectedSeason(e.target.value); // Utiliser selectedSeason pour la saison sélectionnée
  };

  const handlePassSportFilterChange = (e) => {
    setPassSportFilter(e.target.value);
  };

  const handleStatutReglementFilterChange = (e) => {
    setStatutReglementFilter(e.target.value);
  };

  const handleGroupFilterChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const getFilteredReglements = () => {
    return reglements.filter((reglement) => {
      const matchesSearchTerm = reglement.Adherent.nom
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesSeason =
        selectedSeason === "" || reglement.saison === selectedSeason;
      const matchesPassSport =
        passSportFilter === "" || reglement.pass_sport === passSportFilter;
      const matchesStatutReglement =
        statutReglementFilter === "" ||
        reglement.statut_reglement === statutReglementFilter;
      const matchesGroup =
        selectedGroup === "" ||
        (reglement.Adherent.Groups &&
          reglement.Adherent.Groups.some(
            (group) => group.id.toString() === selectedGroup
          ));

      return (
        matchesSearchTerm &&
        matchesSeason &&
        matchesPassSport &&
        matchesStatutReglement &&
        matchesGroup
      );
    });
  };

  const fetchReglements = async () => {
    try {
      const data = await getAllReglementInscription();
      setReglements(data);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      setReglements([]);
    }
  };

  useEffect(() => {
    fetchReglements();
  }, []);

  const openEditModal = (reglement) => {
    setEditableReglement(reglement);
    setIsModalOpen(true);
  };

  const closeCreateModal = () => {
    fetchReglements();
    setIsCreateModalOpen(false);
  };

  const closeEditModal = () => {
    // rafraichier les règlements après une mise à jour
    fetchReglements();
    setIsModalOpen(false);
  };

  const deleteReglement = async (id) => {
    try {
      await deleteReglementInscription(id);
      // Recharger la liste des règlements pour refléter la suppression
      const updatedReglements = reglements.filter(
        (reglement) => reglement.id !== id
      );
      setReglements(updatedReglements);
      console.log(`Règlement avec l'ID ${id} supprimé.`);
    } catch (error) {
      console.error("Erreur lors de la suppression du règlement", error);
      // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
    }
  };

  // Ajoute la fonction pour rafraîchir les règlements après une mise à jour
  const fetchReglementsAgain = () => {
    fetchReglements();
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Règlements Inscriptions
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4">
        <div className="mb-4 w-full md:w-auto">
          <div className="w-full md:w-auto font-satoshi ">
            <div className="form-control">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white font-satoshi">
                Recherche
              </label>
              <input
                type="text"
                placeholder="Rechercher par nom d'adhérent..."
                className="input input-bordered w-full mb-4"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row font-satoshi gap-4">
            <div className="form-control w-full">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                Saison
              </label>
              <select
                className="select select-bordered w-full dark:text-black"
                value={selectedSeason} // Utiliser selectedSeason
                onChange={handleSaisonFilterChange}
              >
                {seasons.map((season) => (
                  <option key={season} value={season}>
                    {season}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control w-full">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                PassPort
              </label>
              <select
                className="select select-bordered w-full dark:text-black"
                onChange={handlePassSportFilterChange}
              >
                <option value="">Tous les PassSports</option>
                <option value="-50.00">-50.00</option>
                {/* Plus d'options basées sur le statut */}
              </select>
            </div>

            <div className="form-control w-full font-satoshi">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                Statut Règlement
              </label>
              <select
                className="select select-bordered w-full dark:text-black font-satoshi"
                onChange={handleStatutReglementFilterChange}
              >
                <option value="">Tous les statuts</option>
                <option value="Réglé">Réglé</option>
                <option value="En cours">En cours</option>
                <option value="Non réglé">Non réglé</option>
                <option value="Problème">Problème</option>
                <option value="Annulé">Annulé</option>
                {/* Plus d'options basées sur le statut */}
              </select>
            </div>

            <div className="form-control w-full font-satoshi">
              <label className="block mb-2 text-xs font-medium text-gray-900 dark:text-white">
                Groupe
              </label>
              <select
                className="select select-bordered w-full dark:text-black"
                value={selectedGroup}
                onChange={handleGroupFilterChange}
              >
                <option value="">Tous les groupes</option>
                {groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.nom}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <Button
              text="Créer un Règlement"
              onClick={() => setIsCreateModalOpen(true)}
              icon={FaPlusSquare}
              className=" mt-4 mb-4 gap-2"
              hoverLabel="Créer un règlement"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-white">
              Nombre d'adhérents affichés : {getFilteredReglements().length}
            </span>
            <div className="bg-blue-300 p-2 rounded-lg shadow-md dark:bg-gray-700">
              <span className="text-sm font-medium text-gray-700 dark:text-white">
                Total des règlements affichés : {calculateTotalSum()} €
              </span>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto table-scrollable">
          <table className="table bg-white dark:bg-slate-300 font-satoshi max-w-[1024] mx-auto border-collapse border-2 border-slate-300">
            <thead className="bg-gray-800 text-white">
              <tr className="border-collapse  border-2 border-slate-300">
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider sticky-col">
                  Nom-Prénom
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Total
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Statut Règl.
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Saison
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Lic-Ass
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Cotisation
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Réduction Foyer
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Pass Sport
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Réduction Saison
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Type
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Nb
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Obs. / N° Pass'Sport
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Groupe
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-slate-200">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {getFilteredReglements().map((reglement) => (
                <tr key={reglement.id} className="hover:bg-gray-100 h-full">
                  <td className="sticky-col bg-white/90">{`${reglement.Adherent.nom} ${reglement.Adherent.prenom}`}</td>
                  <td>{`${calculateTotal(reglement)} €`}</td>
                  <td className="text-center">
                    <span
                      className={`inline-block ml-2 px-3 py-1 rounded-full text-white font-semibold text-xs ${
                        reglement.statut_reglement === "Réglé"
                          ? "bg-green-500"
                          : reglement.statut_reglement === "En cours"
                          ? "bg-orange-500"
                          : reglement.statut_reglement === "Non réglé"
                          ? "bg-black"
                          : reglement.statut_reglement === "Problème"
                          ? "bg-red-500"
                          : reglement.statut_reglement === "Annulé"
                          ? "bg-gray-400"
                          : "bg-gray-200"
                      }`}
                    >
                      {reglement.statut_reglement}
                    </span>
                  </td>
                  <td>{reglement.saison}</td>
                  <td>{`${reglement.montant_lic_assurance} €`}</td>
                  <td>{`${reglement.montant_cotisation} €`}</td>
                  <td>{`${reglement.reduction_foyer} €`}</td>
                  <td>
                    {reglement.pass_sport ? `${reglement.pass_sport} €` : "N/A"}
                  </td>
                  <td>{`${reglement.reduction_saison} €`}</td>
                  <td>{reglement.type_reglement}</td>
                  <td>{reglement.nb_reglement}</td>
                  <td>{reglement.observations}</td>
                  <td>
                    {reglement.Adherent.Groups &&
                    reglement.Adherent.Groups.length > 0
                      ? reglement.Adherent.Groups.map(
                          (group) => group.nom
                        ).join(", ")
                      : "N/A"}
                  </td>
                  <td className="flex justify-center gap-2">
                    {reglement.type_reglement === "En ligne" && (
                      <ButtonCBplus
                        onClick={() => {
                          if (userRole === "visiteur") {
                            toast.error(
                              "Les visiteurs ne peuvent pas ajouter de paiement en ligne."
                            );
                          } else {
                            navigate("/achats-boutique");
                          }
                        }}
                        hoverLabel={
                          userRole === "visiteur"
                            ? "Inactif"
                            : "Ajouter un paiement en ligne"
                        }
                        className={
                          userRole === "visiteur"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }
                      />
                    )}
                    <ButtonEdit
                      onClick={() => {
                        if (userRole === "visiteur") {
                          toast.error(
                            "Les visiteurs ne peuvent pas éditer ce règlement."
                          );
                        } else {
                          openEditModal(reglement);
                        }
                      }}
                      hoverLabel={
                        userRole === "visiteur" ? "Inactif" : "Éditer"
                      }
                      className={
                        userRole === "visiteur"
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }
                    />
                    <ButtonDelete
                      onClick={() => {
                        if (userRole === "visiteur") {
                          toast.error(
                            "Les visiteurs ne peuvent pas supprimer ce règlement."
                          );
                        } else {
                          deleteReglement(reglement.id);
                        }
                      }}
                      hoverLabel={
                        userRole === "visiteur" ? "Inactif" : "Supprimer"
                      }
                      className={
                        userRole === "visiteur"
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <CreateReglementInscriptionModal
          isModalOpen={isCreateModalOpen}
          setIsModalOpen={setIsCreateModalOpen}
          fetchReglements={fetchReglements}
          onClose={closeCreateModal} // Passer cette fonction pour fermer la modale
        />
        <EditReglementInscriptionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          reglement={editableReglement}
          fetchReglements={fetchReglementsAgain}
          onClose={closeEditModal} // Passer cette fonction pour fermer la modale
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReglementInscriptionPage;
