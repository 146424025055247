import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import {
  getAllInventaire,
  deleteInventaire,
} from "./../../../services/inventaireServices";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import Button from "./../../../components/Button/Button";
import "react-toastify/dist/ReactToastify.css";
import EditInventaireModal from "./EditInventaireModal";
import AddInventaireModal from "./AddInventaireModal";

const InventairePage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [inventaireData, setInventaireData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentInventaire, setCurrentInventaire] = useState(null);

  useEffect(() => {
    const fetchInventaire = async () => {
      try {
        const data = await getAllInventaire();
        if (Array.isArray(data)) {
          setInventaireData(data);
        } else {
          throw new Error("Les données reçues ne sont pas un tableau");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'inventaire:", error);
        toast.error("Erreur lors de la récupération de l'inventaire");
      }
    };

    fetchInventaire();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditClick = (inventaire) => {
    setCurrentInventaire(inventaire);
    setShowEditModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteInventaire(id);
      setInventaireData((prevInventaire) =>
        prevInventaire.filter((item) => item.id !== id)
      );
      toast.success("Objet supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'objet:", error);
      toast.error("Erreur lors de la suppression de l'objet");
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const filteredInventaire = inventaireData.filter((item) =>
    `${item.nom} ${item.categorie}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Inventaire
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="flex flex-col items-center w-full px-4 mt-4">
        <input
          type="text"
          placeholder="Rechercher par nom ou catégorie"
          value={searchTerm}
          onChange={handleSearchChange}
          className="mb-4 p-2 border border-gray-300 rounded w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
        />
        <Button
          text="Ajouter un Stock"
          onClick={handleAddClick}
          className="mb-4"
        />
        <InventaireTable
          inventaireData={filteredInventaire}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </div>
      <ToastContainer />
      {showEditModal && (
        <EditInventaireModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          inventaire={currentInventaire}
          refreshList={() => {
            const fetchInventaire = async () => {
              try {
                const data = await getAllInventaire();
                setInventaireData(data);
              } catch (error) {
                console.error(
                  "Erreur lors de la récupération de l'inventaire:",
                  error
                );
                toast.error("Erreur lors de la récupération de l'inventaire");
              }
            };
            fetchInventaire();
          }}
        />
      )}
      {showAddModal && (
        <AddInventaireModal
          isOpen={showAddModal}
          onClose={handleCloseAddModal}
          refreshList={() => {
            const fetchInventaire = async () => {
              try {
                const data = await getAllInventaire();
                setInventaireData(data);
              } catch (error) {
                console.error(
                  "Erreur lors de la récupération de l'inventaire:",
                  error
                );
                toast.error("Erreur lors de la récupération de l'inventaire");
              }
            };
            fetchInventaire();
          }}
        />
      )}
    </div>
  );
};

const InventaireTable = ({ inventaireData, onEditClick, onDeleteClick }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-500 shadow-lg max-w-full mx-auto font-satoshi">
      <div className="overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-satoshi w-full mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Photo
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Catégorie
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Nom
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Stock
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Note
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {inventaireData.length > 0 ? (
              inventaireData.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                >
                  <td className="px-5 py-3 flex justify-center">
                    <img
                      src={item.photo_url}
                      alt={item.nom}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                  </td>
                  <td className="px-5 py-3">{item.categorie}</td>
                  <td className="px-5 py-3">{item.nom}</td>
                  <td className="px-5 py-3">{item.stock}</td>
                  <td className="px-5 py-3">{item.note}</td>
                  <td className="px-5 py-3 flex items-center justify-center space-x-2">
                    <ButtonEdit onClick={() => onEditClick(item)} />
                    <ButtonDelete onClick={() => onDeleteClick(item.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4 px-6 text-gray-800">
                  Aucun objet dans l'inventaire.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventairePage;
