import React, { useState, useEffect } from "react";
import { getBeltHistoryByAdherent } from "../../../services/beltServices";
import ButtonDiplome from "../../../components/Button/ButtonDiplome";

const AdherentPassageCeinture = ({ adherentId }) => {
  const [ceintures, setCeintures] = useState([]);

  useEffect(() => {
    const fetchCeintures = async () => {
      try {
        const data = await getBeltHistoryByAdherent(adherentId);
        setCeintures(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des ceintures:", error);
      }
    };

    fetchCeintures();
  }, [adherentId]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-4 shadow-lg max-w-[800px] mx-auto font-satoshi">
      <div className="bg-gray-300 dark:bg-gray-600 p-4 rounded">
        <h3 className="text-2xl font-light uppercase mb-4">Passages de Ceinture</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {ceintures.length > 0 ? (
            ceintures.map((ceinture, index) => (
              <div
                key={index}
                className="relative bg-white dark:bg-slate-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                {/* Image de la ceinture avec overlay */}
                <div className="relative w-full h-24">
                  <img
                    src={`/img/belt/${ceinture.BeltColor.belt_url}`}
                    alt={ceinture.BeltColor.nom || "Inconnu"}
                    className="w-full h-full object-contain"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white opacity-0 hover:opacity-100 transition-opacity duration-300">
                    <span className="text-lg font-semibold">
                      {ceinture.BeltColor.nom || "Inconnu"}
                    </span>
                  </div>
                </div>

                {/* Date de passage */}
                <p className="text-center text-gray-700 dark:text-gray-300 text-sm">
                  {new Date(ceinture.date_passage).toLocaleDateString()}
                </p>

                {/* Bouton Diplôme */}
                {ceinture.BeltColor.ordre >= 2 && ceinture.BeltColor.ordre <= 13 && (
                  <div className="flex justify-center">
                    <ButtonDiplome
                      adherentId={adherentId}
                      datePassage={ceinture.date_passage}
                      beltImage={ceinture.BeltColor.belt_url}
                      beltName={ceinture.BeltColor.nom}
                      adherentNom={ceinture.Adherent.nom}
                      adherentPrenom={ceinture.Adherent.prenom}
                      dateNaissance={ceinture.Adherent.date_naissance}
                    />
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="col-span-full text-center text-gray-800 dark:text-gray-200">
              Aucun historique de passage de ceinture disponible.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdherentPassageCeinture;
